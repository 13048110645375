import React from 'react'
import "./index.css";

const Section2 = () => {
  return (
    <>
      <div class="sec_content">
        <div className="container_about_us">
          <div className="content_box">
            <h2>About Us</h2>
            <p className='sm-description'>Hop Electric is a climate-tech startup in the new energy automotive industry founded by Ketan Mehta, Nikhil Bhatia and Rahil Gupta in 2020 as forward integration of the $50+ Million renewable energy business of Rays Group. </p>
            <p className='sm-description mb-8'>
              <span className='bold-text-heaing'>Products</span> : Manufactures wide variants of Electric 2W with different target segments.
            </p>
            <p className="sm-description mb-8">
              <span className='bold-text-heaing'>HOP OXO</span> : High Speed Electric Motorcycle,
            </p>
            <p className="sm-description mb-8">
              <span className='bold-text-heaing'>HOP LEO</span> : Mid speed electric scooter,
            </p>
            <p className="sm-description mb-8">
              <span className='bold-text-heaing'>HOP LYF</span> : Low Speed Electric scooter.
            </p>
            <p className='sm-description mt-8'>Strong in-house R&D & manufacturing with 100+ team and captive cell testing lab, electronics &  electric lab, mechanical testing lab & Design studio at Hop Launchpad, Jaipur Rj.  Manufacturing facility ‘Hop Megaplex’ commissioned with an annual capacity of 1.2 lac units per annum.</p>
            <p className='sm-description'>Exclusive network of 150+ Hop Experience Centers across 15+ states in India</p>
            <p className='sm-description'>Mandated under Govt of India's ₹ 26,058 Cr Production Linked Incentive (PLI) Scheme as a Champion OEM</p>
            <p className='sm-description'>Captive NBFC License to enable consumer financing of Hop E2Ws. </p>
            <p className='sm-description'>$ 2.6 Million seed capital raised by a publicly listed power electronics manufacturer in May’22.</p>
            <p className='sm-description'>60 Acre land secured in Neemrana for futuristic technologies manufacturing industrial park development.</p>
          </div>
        </div>
      </div>

 
    </>
  )
}

export default Section2