import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import "../Testride/test-ride.css";
import logo_right from "../../assets/images/dx.png";
import popuplogomo from "../../assets/images/mo_popuplogo.png";
import confirm_bike from "../../assets/images/Confirmation_bike.png";
import bike_re_conf from "../../assets/images/conf-bike.png";
import Footer from "../../components/common/layout/Footer";
import Header from "../../components/common/layout/Header";
import { useScrollTo } from "../../hooks/useScrollTo";

const HomeBookTestride = () => {
  useScrollTo(0, 0);
  const navigate = useNavigate();
  const date = new Date();
  const estimateMonth = date.getMonth() + 1;
  const estimateYear = date.getFullYear();
  const newDate = new Date(estimateYear, estimateMonth + 3);
  const dateName = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const estimate_date =
    dateName[estimateMonth] +
    " - " +
    dateName[newDate.getMonth()] +
    " " +
    newDate.getFullYear();
  const [open, setOpen] = useState(false);

  return (
    <>
      <Header />
      <div className="height_one_csrol" id="homebook_testride">
        <div id="home-book-test-ride" className="top_header_pm">
          <div className="testride_map select_dealer book_map homebook_testride">

            <div className="map_left Confirmation_left displeymobile_none">
              <figure>
                <img  src={confirm_bike} alt="imhvgb" />
              </figure>

              <div className="after_savings">
                <figure>
                  <img  src={bike_re_conf} alt="imhvgb" />
                </figure>
              </div>
            </div>

            <div className="testride_righsec booktest_ride">
              <div className="logo_cont_show">
                <div className="logo_right_cont destop_show">
                  <img  src={logo_right} alt="imhvgb" />
                  <p>Est. Delivery Date: {estimate_date}</p>
                </div>

                <div className="only_mobileshow mobile_show">
                  <img  src={popuplogomo} alt="imhvgb" />
                  <h3 className="mobile_show">When would you like to ride</h3>
                </div>

                <h3 className="destop_show">
                  When would you <br /> like to ride
                </h3>

                <div className="test_ridestep">
                  <ul>
                    <li
                      className="dropdown_menu"
                      onClick={() => (open ? setOpen(false) : setOpen(true))}
                    >
                      <Link to=" ">My Test Rides</Link>
                      <ul
                        className="drop_menu"
                        style={{ display: open ? "block" : "none" }}
                      >
                        <li>
                          <Link to=" ">Book 1</Link>
                        </li>
                        <li>
                          <Link to=" ">Book 2</Link>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <Link to=" ">Book Another T-ride</Link>
                    </li>
                    <li>
                      <Link to=" ">My Bookings (vehicle)</Link>
                    </li>
                    <li>
                      <Link to=" ">Order bike</Link>
                    </li>
                  </ul>
                </div>

                <div className="next_btn">
                  <button
                    onClick={() => navigate("/homeconfirmation")}
                    className="default_btn"
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
export default HomeBookTestride;
