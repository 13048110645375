import "../Testride/test-ride.css";
import logo_right from "../../assets/images/dx.png";
import confirm_bike from "../../assets/images/Confirmation_bike.png";
import bike_re_conf from "../../assets/images/conf-bike.png";
import { useNavigate } from "react-router-dom";
import "react-pure-modal/dist/react-pure-modal.min.css";
import Footer from "../../components/common/layout/Footer";
import Header from "../../components/common/layout/Header";
import { useScrollTo } from "../../hooks/useScrollTo";

const ThanksTestride = () => {
  useScrollTo(0, 0);
  const navigate = useNavigate();
  const date = new Date();
  const estimateMonth = date.getMonth() + 1;
  const estimateYear = date.getFullYear();
  const newDate = new Date(estimateYear, estimateMonth + 3);
  const dateName = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  return (
    <>
      <Header />
      <div className="height_one_csrol">
        <div id="home-Confirmation" className="top_header_pm">
          <div className="testride_map Confirmation thanks_testride">
            <div className="map_left Confirmation_left">
              <figure>
                <img  src={confirm_bike} alt="imhvgb" />
              </figure>
              <div className="after_savings">
                <figure>
                  <img  src={bike_re_conf} alt="imhvgb" />
                </figure>
              </div>
            </div>

            <div className="testride_righsec">
              <div className="thak_test">
                <div className="logo_right_cont destop_show">
                  <img  src={logo_right} alt="imhvgb" />
                </div>

                <h3>Sorry!! Sit Tight</h3>
                <p> We Are Coming Soon </p>

                <div className="next_btn">
                  <button onClick={() => navigate("/")} className="default_btn">
                    Back To Home
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};
export default ThanksTestride;
