import React from "react";
import "./index.css";
import wall_img from "../../assets/images/SmartEnergy_homeimg.png";
import hop_star from "../../assets/images/carrier_hop_img.png";
import tree from "../../assets/images/hop_treeImg.png";
import battery from "../../assets/images/Hop-Battery_IMG.png";
import Header from "../../components/common/layout/Header";
import { useScrollTo } from "../../hooks/useScrollTo";

const SmartEnergy = () => {
  useScrollTo(0, 0);
  return (
    <>
      <Header />
      <>
        <div className="">
          <div id="carrierrrr">
            <section className="smart_energy_sec">
              <div className="home_img_main">
                <img  src={wall_img} alt="imhvgb" />
              </div>
            </section>
            <section className="smart_energy_sec2">
              <div className="swap_outside">
                <figure>
                  {" "}
                  <img  src={hop_star} alt="imhvgb" />
                </figure>
                <div className="content_samrtenergy">
                  <h2>CHARGE AT HOME OR SWAP OUTSIDE</h2>
                  <p>
                    Hop energy network is a decentralised network of smart
                    batteries, home chargers, charging + swapping stations all
                    connected together to create a seamless experience.
                  </p>
                </div>
              </div>
            </section>
            <div className="fully_charge_sec smart_energy_sec">
              <div className="fully_container">
                <h2>fully charged battery in just 20 seconds</h2>
                <p>
                  A smarter, faster, easier way to recharge and get back on the
                  road. Ride the way you want and enjoy an experience that’s
                  simply better. Put your discharged batteries into HOP Battery
                  Swapping Station and get a charged ones in just 20 seconds.
                </p>
              </div>
              <div className="content_box_smart">
                <div className="boxes_container">
                  <div className="box11">
                    <div className="content">
                      <h5>UPTO</h5>
                      <p>lesser upfront</p>
                      <p>purchase cost</p>
                    </div>
                  </div>
                </div>
                <div className="boxes_container">
                  <div className="box11">
                    <div className="content">
                      <h5>Unlimited</h5>
                      <p>Battery Warranty</p>
                    </div>
                  </div>
                </div>
                <div className="boxes_container">
                  <div className="box11">
                    <div className="content">
                      <h5>Latest</h5>
                      <p>Battery Technology</p>
                    </div>
                  </div>
                </div>
                <div className="boxes_container">
                  <div className="box11">
                    <div className="content">
                      <h5>Unlimited</h5>
                      <p> Battery Swap Facility</p>
                    </div>
                  </div>
                </div>
                <div className="boxes_container">
                  <div className="box11">
                    <div className="content">
                      <h5>Connected</h5>
                      <p> With GPS &amp; Mobile</p>
                      <p>App</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="content_box_smart box_bottom_content">
                <p>
                  <span>
                    Now you can buy Hop electric scooters with Hop energy
                    network subscription program on a pay as you go model.{" "}
                  </span>
                  Based on your need, opt in for a monthly plan for as low as
                  INR 2500 for unlimited swaps or pay per km @ 1 Re per Km
                </p>
              </div>
            </div>
            <div className="hop_tree_sec">
              <img  src={tree} alt="imhvgb" />
            </div>
            <div className="Battery_Features_sec">
              <div className="upper_header">
                <h2>
                  Battery <strong>Features</strong>
                </h2>
                <p>
                  Advanced Li-Ion batteries | Active Thermal Management | Smart
                  BMS with Can 2.0 | <br />
                  Telematics | Aluminium Casing | Lightweight | Waterproof x IP
                  65
                </p>
              </div>
              <div className="battery_body">
                <div className="body_sec1">
                  <div className="content_1">
                    <div className="battery_cont1">
                      <h4>Single System</h4>
                      <p>70 Kms</p>
                      <p>in Single Charge*</p>
                    </div>
                    <hr />
                    <div className="battery_cont2">
                      <p>20% to 80%</p>
                      <p>Charge in 3 Hours</p>
                    </div>
                  </div>
                </div>
                <div className="body_sec2">
                  <div className="content_1">
                    <div className="battery_cont1">
                      <h4>Single System</h4>
                      <p>70 Kms</p>
                      <p>in Single Charge*</p>
                    </div>
                    <hr />
                    <div className="battery_cont2">
                      <p>20% to 80%</p>
                      <p>Charge in 3 Hours</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="battery_img_sec">
                <div className="battery_img">
                  <img  src={battery} alt="imhvgb" />
                </div>
                <div className="battery_cont">
                  <p>
                    Don’t buy, <strong>swap!</strong> It’s the best way to get
                    the freshest, safest batteries with the newest technology
                    will make sure you move faster, further, and safer.
                  </p>
                </div>
              </div>
            </div>
            <section className="battery_footer">
              <div className="latest_news">
                <h2>Get the latest news &amp; insights</h2>
                <p>
                  <a href="">Recieve our newsletter →</a>
                </p>
              </div>
            </section>
          </div>
        </div>
      </>
    </>
  );
};

export default SmartEnergy;
