import React, { useState, useRef } from "react";
import { useEffect } from "react";
import $ from "jquery";
import PureModal from "react-pure-modal";
import Group_img from "../../../assets/images/Group.svg";

export const HopLeoPlus = () => {

  const [modal3, setModal3] = useState(false);
  const [activeBtnOxo1, setActiveBtnOxo1] = useState("activeBtnOxo1");
  const [opneContentModal, setOpneContentModal] = useState("contentofLH");
  const [activeBtnOxoX1, setActiveBtnOxoX1] = useState("");
  const [activeBtnOxoX2, setActiveBtnOxoX2] = useState("");
  const [tabindex, settabindex] = useState("oxox");

  const handleOxoBtn1 = (key) => {
    settabindex(key);
    if (key == "oxox") {
      setActiveBtnOxo1("activeBtnOxo1");
      setActiveBtnOxoX1("");
      setActiveBtnOxoX2("");
      setOpneContentModal("contentofLH");
    } else if (key == "oxo") {
      setActiveBtnOxo1("");
      setActiveBtnOxoX2("");
      setActiveBtnOxoX1("activeBtnOxo1");
      setOpneContentModal("contentofHS");
    } else {
      setActiveBtnOxo1("");
      setActiveBtnOxoX1("");
      setActiveBtnOxoX2("activeBtnOxo1");
    }
  };

  const openTechSpace = () => {
    setModal3(true);
  };
  return (
    <div className="tech_specs_sec for_hopleo">
      <div className="landing_page_container">
        <ul className={`tech_deails Specs_conbg  ${opneContentModal}`}>
          <div className="tabing_section">
            <div className="uk">
              <h2>Technical Specifications</h2>
              <div className=" oxo_both_headar first">
                {/* LEO HS */}
                
                <button
                  className={`btn-price-oxo  ${activeBtnOxo1}`}
                  onClick={() => handleOxoBtn1("oxox")}
                >
                  LEO HS
                </button>
                <button
                  className={`btn-price-oxo ${activeBtnOxoX1}`}
                  onClick={() => handleOxoBtn1("oxo")}
                >
                  LEO LS
                </button>
              </div>
              <div className="tabing_data">
                <div className="data_box">
                  <p>TOP SPEED</p>
                  <h1 className="uk-margin-small">{tabindex == "oxo" ? "25" : "52"}</h1>
                  <p>KMPH</p>

                  <h3 className="uk-margin-medium uppercase">Performance</h3>
                  <div className="details">
                    <p>
                      Top Speed{" "}
                      <span style={{ color: "#ffffff" }}>{tabindex == "oxo" ? "25 Kmph" : "52 Kmph"}</span>
                    </p>
                    <p>
                      Max. Torque (At Wheel){" "}
                      <span style={{ color: "#ffffff" }}>90 Nm</span>
                    </p>
                    <p>
                      Voltage Architecture{" "}
                      <span style={{ color: "#ffffff" }}>72V</span>
                    </p>
                  </div>
                </div>
                <div className="data_box">
                  <p>RANGE</p>
                  <h1 className="uk-margin-small">
                    {tabindex == "oxo" ? "95" : "120"}
                  </h1>
                  <p>KM/CHARGE</p>
                  <h3 className="uk-margin-medium uppercase">Battery</h3>
                  <div className="details">
                    <p>
                      Battery Type{" "}
                      <span style={{ color: "#ffffff" }}>Lithium-ion</span>
                    </p>
                    <p>
                      Battery positioning{" "}
                      <span style={{ color: "#ffffff" }}>
                        Under boot, Fixed
                      </span>
                    </p>
                    <p>
                      Charging method{" "}
                      <span style={{ color: "#ffffff" }}>
                        Off-board charger
                      </span>
                    </p>
                  </div>
                </div>
                <div className="data_box">
                  <p>Motor Power (Peak)</p>
                  <h1 className="uk-margin-small"> {tabindex == "oxo" ? "250" : "2200"}</h1>
                  <p>WATTS</p>
                  <h3 className="uk-margin-medium uppercase">Charging</h3>
                  <div className="details">
                    <p>
                      Smart Charger{" "}
                      <span style={{ color: "#ffffff" }}>
                        {tabindex == "oxox" ? "850W, 10A" : "550W, 6A"}{" "}
                      </span>
                    </p>
                    <p>
                      Charging Time (0-80%){" "}
                      <span style={{ color: "#ffffff" }}>
                        {tabindex == "oxox" ? "2Hr 45Min" : "3 Hrs"}
                      </span>
                    </p>
                    <p>
                      Charging Time (0-100%),{" "}
                      <span style={{ color: "#ffffff" }}>
                        {tabindex == "oxox" ? "3Hr 15Min" : "3Hr 30 min"}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ul>

        <div className="click_pop">
          <button className="open_pop_on" onClick={openTechSpace}>
            <img  src={Group_img} alt="imhvgb" />
          </button>
        </div>
        <div className={`modal-content  ${opneContentModal}`}>
          <PureModal
            onClose={() => {
              setModal3(false);
              return true;
            }}
            width="800px"
            scrollable={true}
            isOpen={modal3}
          >
            <div className="tabing_section">
              <div className="uk">
                <h2>Technical Specifications</h2>
                <div className="tabing_data_second">
                  <div className="content_sec">
                    <div className="inner_cont">
                      <ul className="content_main">
                        <li
                          className="left oxo_both_headar1"
                          style={{ listStyle: "none" }}
                        ></li>
                        <li className="right oxo_both_headar">LEO HS</li>
                        <li className="third oxo_both_headar">LEO LS</li>
                      </ul>
                    </div>
                    <h2>Performance</h2>
                    <div className="inner_cont">
                      <ul className="content_main">
                        <li className="left">Top Speed</li>
                        <li className="right">52 Kmph</li>
                        <li className="third">25 Kmph</li>
                      </ul>
                      <ul className="content_main">
                        <li className="left">True Range (Mode 1)</li>
                        <li className="right">120Kms</li>
                        <li className="third">95Kms</li>
                      </ul>
                    </div>
                    <div className="inner_cont">
                      <ul className="content_main">
                        <li className="left">True Range (Mode 2)</li>
                        <li className="right">100Kms</li>
                        <li className="third">75Kms</li>
                      </ul>
                      <ul className="content_main">
                        <li className="left">True Range (Mode 3)</li>
                        <li className="right">80kms</li>
                        <li className="third">60kms</li>
                      </ul>
                    </div>
                    <div className="inner_cont">
                      <ul className="content_main">
                        <li className="left">Voltage Architecture</li>
                        <li className="right">72V</li>
                        <li className="third">72V</li>
                      </ul>
                      <ul className="content_main">
                        <li className="left">Battery Capacity</li>
                        <li className="right">28.6Ah</li>
                        <li className="third">20Ah</li>
                      </ul>
                    </div>
                    <div className="inner_cont">
                      <ul className="content_main">
                        <li className="left">Motor Power(Peak)</li>
                        <li className="right">2200W</li>
                        <li className="third">250W</li>
                      </ul>
                      <ul className="content_main">
                        <li className="left">Max. Torque (At Wheel)</li>
                        <li className="right">90Nm</li>
                        <li className="third">90Nm</li>
                      </ul>
                    </div>
                    <div className="inner_cont">
                      <ul className="content_main">
                        <li className="left">Motor Type</li>
                        <li className="right">BLDC Hub motor</li>
                        <li className="third">BLDC Hub motor</li>
                      </ul>
                      <ul className="content_main">
                        <li className="left">Controller</li>
                        <li className="right">Sinusoidal FOC Vector Control</li>
                        <li className="third">Sinusoidal FOC Vector Control</li>
                      </ul>
                    </div>
                    <div className="inner_cont">
                      <ul className="content_main">
                        <li className="left">Riding Modes </li>
                        <li className="right">Eco-Power-Sport</li>
                        <li className="third">Eco-Power-Sport</li>
                      </ul>
                      <ul className="content_main">
                        <li className="left">Top Speed (Eco Mode) </li>
                        <li className="right">30 Kmph</li>
                        <li className="third">30 Kmph</li>
                      </ul>
                    </div>
                    <div className="inner_cont">
                      <ul className="content_main">
                        <li className="left">Top Speed (Power Mode) </li>
                        <li className="right">40 kmph</li>
                        <li className="third">40 kmph</li>
                      </ul>
                      <ul className="content_main">
                        <li className="left">Top Speed (Sport Mode)</li>
                        <li className="right">52 kmph</li>
                        <li className="third">52 kmph</li>
                      </ul>
                    </div>
                    <div className="inner_cont">
                      <ul className="content_main">
                        <li className="left">Climbing Ability</li>
                        <li className="right">12 Degree</li>
                        <li className="third">12 Degree</li>
                      </ul>
                      <ul className="content_main">
                      </ul>
                    </div>
                  </div>
                  <div className="content_sec">
                    <h2>Battery</h2>
                    <div className="inner_cont">
                      <ul className="content_main">
                        <li className="left">Battery Type</li>
                        <li className="right">Lithium-ion</li>
                        <li className="third">Lithium-ion</li>
                      </ul>
                      <ul className="content_main">
                        <li className="left">Installed capacity</li>
                        <li className="right">2 Kwh</li>
                        <li className="third">1.44 Kwh</li>
                      </ul>
                    </div>
                    <div className="inner_cont">
                      <ul className="content_main">
                        <li className="left">Battery positioning </li>
                        <li className="right">Under boot, Fixed</li>
                        <li className="third">Under boot, Fixed</li>
                      </ul>
                      <ul className="content_main">
                        <li className="left">Charging method</li>
                        <li className="right">Off-board charger</li>
                        <li className="third">Off-board charger</li>
                      </ul>
                    </div>
                  </div>
                  <div className="content_sec">
                    <h2>Charging</h2>
                    <div className="inner_cont">
                      <ul className="content_main">
                        <li className="left">Smart Charger </li>
                        <li className="right">850W, 10A</li>
                        <li className="third">550W, 6A</li>
                      </ul>
                      <ul className="content_main">
                        <li className="left">Charging Time (0-80%)</li>
                        <li className="right">2Hr 45Min</li>
                        <li className="third">3 Hrs</li>
                      </ul>
                    </div>
                    <div className="inner_cont">
                      <ul className="content_main">
                        <li className="left">Charging Time (0-100%) </li>
                        <li className="right">3Hr 15Min</li>
                        <li className="third">3Hr 30 min</li>
                      </ul>
                      <ul className="content_main">
                      </ul>
                    </div>
                  </div>
                  <div className="content_sec">
                    <h2>Suspension</h2>
                    <div className="inner_cont">
                      <ul className="content_main">
                        <li className="left">Front </li>
                        <li className="right">Upright Telescopic Forks</li>
                        <li className="third">Upright Telescopic Forks</li>
                      </ul>
                      <ul className="content_main">
                        <li className="left">Rear</li>
                        <li className="right">Shock absorber</li>
                        <li className="third">Shock absorber</li>
                      </ul>
                    </div>
                  </div>
                  <div className="content_sec">
                    <h2>Braking</h2>
                    <div className="inner_cont">
                      <ul className="content_main">
                        <li className="left">Front</li>
                        <li className="right">Disc</li>
                        <li className="third">Disc</li>
                      </ul>
                      <ul className="content_main">
                        <li className="left">Rear</li>
                        <li className="right">Disc</li>
                        <li className="third">Disc</li>
                      </ul>
                    </div>
                    <div className="inner_cont">
                      <ul className="content_main">
                        <li className="left">Brake type </li>
                        <li className="right">Disc-Disc</li>
                        <li className="third">Disc-Disc</li>
                      </ul>
                      <ul className="content_main">
                        <li className="left">Regenrative Braking </li>
                        <li className="right">Yes</li>
                        <li className="third">Yes</li>
                      </ul>
                    </div>
                  </div>
                  <div className="content_sec">
                    <h2>Display</h2>
                    <div className="inner_cont">
                      <ul className="content_main">
                        <li className="left">Display type </li>
                        <li className="right">LCD</li>
                        <li className="third">LCD</li>
                      </ul>
                      <ul className="content_main">
                        <li className="left">IP rating for Display</li>
                        <li className="right">IP65</li>
                        <li className="third">IP65</li>
                      </ul>
                    </div>
                    <div className="inner_cont">
                      <ul className="content_main">
                        <li className="left">Background colour </li>
                        <li className="right">White</li>
                        <li className="third">White</li>
                      </ul>
                      <ul className="content_main">
                      </ul>
                    </div>
                  </div>
                  <div className="content_sec">
                    <h2>Wheel</h2>
                    <div className="inner_cont">
                      <ul className="content_main">
                        <li className="left">Wheel type</li>
                        <li className="right">Alloy Wheel</li>
                        <li className="third">Alloy Wheel</li>
                      </ul>
                      <ul className="content_main">
                        <li className="left">Wheel Size (F&R)</li>
                        <li className="right">10 Inch</li>
                        <li className="third">10 Inch</li>
                      </ul>
                    </div>
                    <div className="inner_cont">
                      <ul className="content_main">
                        <li className="left">Tyre Size (Front)</li>
                        <li className="right">90/90-r10</li>
                        <li className="third">90/90-r10</li>
                      </ul>
                      <ul className="content_main">
                        <li className="left">Tyre Size (Rear)</li>
                        <li className="right">90/90-r10</li>
                        <li className="third">90/90-r10</li>
                      </ul>
                    </div>
                  </div>
                  <div className="content_sec">
                    <h2>Lightning</h2>
                    <div className="inner_cont">
                      <ul className="content_main">
                        <li className="left">Headlight</li>
                        <li className="right">Yes, LED Lights</li>
                        <li className="third">Yes, LED Lights</li>
                      </ul>
                      <ul className="content_main">
                        <li className="left">Tail Light </li>
                        <li className="right">Yes</li>
                        <li className="third">Yes</li>
                      </ul>
                    </div>
                    <div className="inner_cont">
                      <ul className="content_main">
                        <li className="left">Indicator</li>
                        <li className="right">Yes</li>
                        <li className="third">Yes</li>
                      </ul>
                      <ul className="content_main">
                        <li className="left">Day time running lights (DRL)</li>
                        <li className="right">Yes</li>
                        <li className="third">Yes</li>
                      </ul>
                    </div>
                  </div>
                  <div className="content_sec">
                    <h2>Structural</h2>
                    <div className="inner_cont">
                      <ul className="content_main">
                        <li className="left">Dimensions(LBH)</li>
                        <li className="right">L1940xW720xH1180 mm</li>
                        <li className="third">L1940xW720xH1180 mm</li>
                      </ul>
                      <ul className="content_main">
                        <li className="left">Ground Clearance(mm) </li>
                        <li className="right">160 mm</li>
                        <li className="third">160 mm</li>
                      </ul>
                    </div>
                    <div className="inner_cont">
                      <ul className="content_main">
                        <li className="left">Seat height(mm) </li>
                        <li className="right">800 mm</li>
                        <li className="third">800 mm</li>
                      </ul>
                      <ul className="content_main">
                        <li className="left">Kerb Weight(KG)</li>
                        <li className="right">80 Kg</li>
                        <li className="third">77 Kg</li>
                      </ul>
                    </div>
                    <div className="inner_cont">
                      <ul className="content_main">
                        <li className="left">Load Bearing Capacity(KG) </li>
                        <li className="right">160 Kg</li>
                        <li className="third">160 Kg</li>
                      </ul>
                      <ul className="content_main">
                      </ul>
                    </div>
                  </div>
                  <div className="content_sec">
                    <h2>IP Rating</h2>
                    <div className="inner_cont">
                      <ul className="content_main">
                        <li className="left">Motor</li>
                        <li className="right">IP 66</li>
                        <li className="third">IP 66</li>
                      </ul>
                      <ul className="content_main">
                        <li className="left">Controller</li>
                        <li className="right">IP 65</li>
                        <li className="third">IP 65</li>
                      </ul>
                    </div>
                    <div className="inner_cont">
                      <ul className="content_main">
                        <li className="left">Battery</li>
                        <li className="right">IP 67</li>
                        <li className="third">IP 67</li>
                      </ul>
                      <ul className="content_main">
                        <li className="left">Display</li>
                        <li className="right">IP 65</li>
                        <li className="third">IP 65</li>
                      </ul>
                    </div>
                  </div>
                  <div className="content_sec">
                    <h2>Connectivity</h2>
                    <div className="inner_cont">
                      <ul className="content_main">
                        <li className="left">Internet</li>
                        <li className="right">NA</li>
                        <li className="third">NA</li>
                      </ul>
                      <ul className="content_main">
                        <li className="left">GPS</li>
                        <li className="right">NA</li>
                        <li className="third">NA</li>
                      </ul>
                    </div>
                    <div className="inner_cont">
                      <ul className="content_main">
                        <li className="left">Bluetooth</li>
                        <li className="right">NA</li>
                        <li className="third">NA</li>
                      </ul>
                      <ul className="content_main">
                        <li className="left">Compute</li>
                        <li className="right">NA</li>
                        <li className="third">NA</li>
                      </ul>
                    </div>
                    <div className="inner_cont">
                      <ul className="content_main">
                        <li className="left">Security </li>
                        <li className="right">NA</li>
                        <li className="third">NA</li>
                      </ul>
                      <ul className="content_main">
                        <li className="left">Mobile APP</li>
                        <li className="right">NA</li>
                        <li className="third">NA</li>
                      </ul>
                    </div>
                  </div>
                  <div className="content_sec">
                    <h2>Warranty</h2>
                    <div className="inner_cont">
                      <ul className="content_main">
                        <li className="left">Vehicle</li>
                        <li className="right">3 Years</li>
                        <li className="third">3 Years</li>
                      </ul>
                      <ul className="content_main">
                        <li className="left">Battery</li>
                        <li className="right">3 Years or 40,000Kms</li>
                        <li className="third">3 Years or 30,000Kms</li>
                      </ul>
                    </div>
                    <div className="inner_cont">
                      <ul className="content_main">
                        <li className="left">Motor</li>
                        <li className="right">
                          As mentioned on Warranty manual
                        </li>
                        <li className="third">
                          As mentioned on Warranty manual
                        </li>
                      </ul>
                      <ul className="content_main">
                        <li className="left">Charger</li>
                        <li className="right">
                          As mentioned on Warranty manual
                        </li>
                        <li className="third">
                          As mentioned on Warranty manual
                        </li>
                      </ul>
                    </div>
                    <div className="inner_cont">
                      <ul className="content_main">
                        <li className="left">Controller </li>
                        <li className="right">
                          As mentioned on Warranty manual
                        </li>
                        <li className="third">
                          As mentioned on Warranty manual
                        </li>
                      </ul>
                      <ul className="content_main">
                      </ul>
                    </div>
                  </div>
                  <div className="content_sec">
                    <h2>Colors</h2>
                    <div className="inner_cont">
                      <ul className="content_main">
                        <li className="left">Colors Available </li>
                        <li className="right">Black, White, Grey, Blue, Red</li>
                        <li className="third">Black, White, Grey, Blue, Red</li>
                      </ul>
                      <ul className="content_main">
                        <li className="left">RTO Registration</li>
                        <li className="right">Yes, Required</li>
                        <li className="third">Not, Required</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </PureModal>
        </div>
      </div>
    </div>
  );
};
