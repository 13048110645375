import { useEffect } from "react";
import $ from "jquery";
import "../Testride/test-ride.css";
import "../Order/order.css";
import "../dashboard/dashboard.css";
import "react-pure-modal/dist/react-pure-modal.min.css";
import confirm_bike from "../../assets/images/Confirmation_bike.png";
import popuplogomo from "../../assets/images/mo_popuplogo.png";
import Dashor_bike from "../../assets/images/Dashorder_bike.png";
import Footer from "../../components/common/layout/Footer";
import Header from "../../components/common/layout/Header";
import { useScrollTo } from "../../hooks/useScrollTo";

const Viewreceipts = () => {
  useScrollTo(0, 0);
  // header scroll hide function
  useEffect(() => {
    document.getElementById("view_reci").addEventListener("scroll", (e) => {
      var yAxis = $(".Confirmation_left").offset().top;
      if (yAxis < 0) {
        $(".header").hide();
      } else {
        $(".header").show();
      }
    });
  }, []);
  return (
    <>
      <Header />
      <div className="height_one_csrol" id="view_reci">
        <div id="Viewreceipts" className="top_header_pm">
          <div className="testride_map Confirmation graphics_sec dashboard_orde viewreceipts_page">
            <div className="map_left Confirmation_left dashboard_leftsec">
              <figure>
                <img  src={confirm_bike} alt="imhvgb" />
              </figure>

              <div className="after_savings">
                <figure className="gra_logo">
                  <img  src={popuplogomo} alt="imhvgb" />
                </figure>

                <figure>
                  <img  src={Dashor_bike} alt="imhvgb" />
                </figure>
              </div>
            </div>

            <div className="testride_righsec dashboard_rightsec viewreceipts">
              <div className="Viewreceipts_email">
                <form>
                  <h4>Enter your email id</h4>
                  <span>
                    We’ll be sending this receipt to this email address
                  </span>
                  <div className="form_group">
                    <input
                      type="Email"
                      class="form-control"
                      placeholder="Email address"
                    />
                  </div>
                </form>

                <div className="orderid_btn">
                  <button class="default_btn tran_btn">Send email</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};
export default Viewreceipts;
