import {
    GoogleMap,
    MarkerF,
  } from '@react-google-maps/api'
  import { useState } from 'react'
  import { useNavigate } from "react-router-dom";
import { JAIPUR_LATITUDE, JAIPUR_LONGITUDE } from '../../constants';
  
  const TestrideGoogleMapMobWithMarkers = (props) => {
    const { myLat, myLong,latLongArray, id } = props;
    const styles = require('../LandingPage/Googlemap/GoogleMapStyles.json')
    const [map, setMap] = useState(/** @type google.maps.Map */(null))
    const navigate = useNavigate();

  
    return (
      <div className="map_left testride_map">
        <GoogleMap
          center={{ lat: parseFloat(latLongArray[0]?.lat !==null ? latLongArray[0]?.lat : JAIPUR_LATITUDE), lng: parseFloat(latLongArray[0]?.long !==null ? latLongArray[0]?.long : JAIPUR_LONGITUDE) }}
          zoom={15}
          mapContainerStyle={{ width: '100%', height: '100%' }}
          options={{
            zoomControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
            styles: styles
          }}
          onLoad={map => setMap(map)}
        >
        {myLat && myLong  && <MarkerF label="My Location" position={{lat:myLat,lng:myLong}} />}        
        {latLongArray && latLongArray.length > 0 && latLongArray.map((item,index) => {
          const { lat, long } = item
          return lat && long && <MarkerF key={index} position={{ lat: parseFloat(lat), lng: parseFloat(long) }} />

        })}
        </GoogleMap>
      </div>
    )
  }
  
  export default TestrideGoogleMapMobWithMarkers;