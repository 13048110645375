export const IMAGES = {



  // images for community pages
  OXO_Image: require("../assets/images/Community/oxoCommunity.png"),
  OXO_big_Image: require("../assets/images/Community/Big_comuunityimg.jpg"),


  //Images for EnquiryPage
  EnquiryBanner: require("../assets/images/Enquiry/EnquiryBanner.png"),
  home_banner_2: require("../assets/images/Enquiry/home_banner_2.jpg"),
  home_banner_3: require("../assets/images/Enquiry/home_banner_3.jpg"),

  OXO_400: require("../assets/images/Enquiry/OXO_400.jpg"),
  LYF_400: require("../assets/images/Enquiry/LYF_400.jpg"),
  LEO_400: require("../assets/images/Enquiry/LEO_400.jpg"),

  energy1: require("../assets/images/Enquiry/energy1.png"),
  energy2: require("../assets/images/Enquiry/energy2.png"),
  energy1hover: require("../assets/images/Enquiry/energy1hover.jpg"),
  noise1: require("../assets/images/Enquiry/noise1.png"),
  noise2: require("../assets/images/Enquiry/noise2.png"),
  noiseonhover: require("../assets/images/Enquiry/noiseonhover.jpg"),
  rain1: require("../assets/images/Enquiry/rain1.png"),
  rain2: require("../assets/images/Enquiry/rain2.png"),
  rainonhover: require("../assets/images/Enquiry/rainonhover.jpg"),
  storm1: require("../assets/images/Enquiry/storm1.png"),
  storm2: require("../assets/images/Enquiry/storm2.png"),
  maintanaince1: require("../assets/images/Enquiry/maintanaince1.png"),
  maintanaince2: require("../assets/images/Enquiry/maintanaince2.png"),
  chasis1: require("../assets/images/Enquiry/chasis1.png"),
  chasis2: require("../assets/images/Enquiry/chasis2.png"),
  insects1: require("../assets/images/Enquiry/insects1.png"),
  insects2: require("../assets/images/Enquiry/insects2.png"),
  insectsonhover: require("../assets/images/Enquiry/insectsonhover.jpg"),
  dust1: require("../assets/images/Enquiry/dust1.png"),
  dust2: require("../assets/images/Enquiry/dust2.png"),
  dustonhover: require("../assets/images/Enquiry/dustonhover.jpg"),
  customize1: require("../assets/images/Enquiry/customize1.png"),
  customize2: require("../assets/images/Enquiry/customize2.png"),
  customizeonhover: require("../assets/images/Enquiry/customizeonhover.jpg"),
  weather1: require("../assets/images/Enquiry/weather1.png"),
  weather2: require("../assets/images/Enquiry/weather2.png"),
  weatheronhover: require("../assets/images/Enquiry/weatheronhover.jpg"),
  elegant1: require("../assets/images/Enquiry/elegant1.png"),
  elegant2: require("../assets/images/Enquiry/elegant2.png"),
  Charging1: require("../assets/images/Enquiry/Charging1.png"),
  Charging2: require("../assets/images/Enquiry/Charging2.png"),

  //Enquiry Features Images




  MobileApp: require("../assets/images/Enquiry/bottomSec/MobileApp.png"),
  Wheels: require("../assets/images/Enquiry/bottomSec/Wheels.png"),
  Breaking: require("../assets/images/Enquiry/bottomSec/Breaking.png"),
  DisplayMeter: require("../assets/images/Enquiry/bottomSec/DisplayMeter.png"),
  LeoMotor: require("../assets/images/Enquiry/bottomSec/LeoMotor.png"),
  Chasis: require("../assets/images/Enquiry/bottomSec/Chasis.png"),
  motorPower: require("../assets/images/Enquiry/bottomSec/motorPower.png"),
  LedHeadlight: require("../assets/images/Enquiry/bottomSec/LedHeadlight.png"),
  accellaration: require("../assets/images/Enquiry/bottomSec/accellaration.png"),
  LionBattery: require("../assets/images/Enquiry/bottomSec/LionBattery.png"),
  RidingModes: require("../assets/images/Enquiry/bottomSec/RidingModes.png"),
  charging: require("../assets/images/Enquiry/bottomSec/charging.png"),




  // OXO_1100_600_1: require("../assets/images/Enquiry/OXO_1100_600_1.jpg"),
  // OXO_1100_600_2: require("../assets/images/Enquiry/OXO_1100_600_2.jpg"),
  // OXO_1100_600_3: require("../assets/images/Enquiry/OXO_1100_600_3.jpg"),
  // OXO_1100_600_4: require("../assets/images/Enquiry/OXO_1100_600_4.jpg"),

  // LEO_1100_600_1: require("../assets/images/Enquiry/LEO_1100_600_1.jpg"),
  // LEO_1100_600_2: require("../assets/images/Enquiry/LEO_1100_600_2.jpg"),
  // LEO_1100_600_3: require("../assets/images/Enquiry/LEO_1100_600_3.jpg"),
  // LEO_1100_600_4: require("../assets/images/Enquiry/LEO_1100_600_4.jpg"),
  // LEO_1100_600_5: require("../assets/images/Enquiry/LEO_1100_600_5.jpg"),
  // LEO_1100_600_6: require("../assets/images/Enquiry/LEO_1100_600_6.jpg"),

  // LYF_1100_600_1: require("../assets/images/Enquiry/LYF_1100_600_1.jpg"),
  // LYF_1100_600_2: require("../assets/images/Enquiry/LYF_1100_600_2.jpg"),
  // LYF_1100_600_3: require("../assets/images/Enquiry/LYF_1100_600_3.jpg"),
  // LYF_1100_600_4: require("../assets/images/Enquiry/LYF_1100_600_4.jpg"),
  // LYF_1100_600_5: require("../assets/images/Enquiry/LYF_1100_600_5.jpg"),
  // LYF_1100_600_6: require("../assets/images/Enquiry/LYF_1100_600_6.jpg"),
  // LYF_1100_600_7: require("../assets/images/Enquiry/LYF_1100_600_7.jpg"),

};
