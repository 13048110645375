import React, { useState } from "react";
import { useEffect } from "react";
import $ from "jquery";
import "./index.css";
import PureModal from "react-pure-modal";
import "react-toastify/dist/ReactToastify.css";
import SiliderPopup from "../LandingPage/SiliderComponent/siliderpopup";
import Header from "../../components/common/layout/Header";
import ride_modes from "../../assets/images/ride_mode.jpg";
import scooter_img1 from "../../assets/images/scooter_img1.png";
import scooter_img2 from "../../assets/images/ZIP_0042.png";
import scooter_img3 from "../../assets/images/ZIP_0010.png";
import scooter_img4 from "../../assets/images/ZIP_0032.jpeg";
import Lightboximg1 from "../../assets/images/ZIP_1.png";
import Lightboximg2 from "../../assets/images/ZIP_2.png";
import Lightboximg3 from "../../assets/images/ZIP_3.png";
import Lightboximg4 from "../../assets/images/ZIP_4.png";
import Lightboximg5 from "../../assets/images/ZIP_5.png";
import BannerOver from "../../assets/images/Group_2778.png";
import Newsletterform from "../LandingPage/SiliderComponent/Newsletterform";
import Productslider from "../LandingPage/SiliderComponent/productslider";
import Tabing from "../LandingPage/SiliderComponent/tabing";
import Tridi from "react-tridi";
import "react-tridi/dist/index.css";
import { Link } from "react-router-dom";
import SelectBikeLeo from "../../components/common/layout/SelectBikeLeo";
import { useScrollTo } from "../../hooks/useScrollTo";

const ContactUs = () => {
  useScrollTo(0, 0);
  const [modalsiliderone, setModelSiliderOne] = useState(false);
  const [modalsiliderForm, setModelSiliderForm] = useState(false);
  const [scrollwise, setScrollwise] = useState(true);
  const [image, setImage] = useState("./scooter");
  const [openModal, setopenModal] = useState(true);

  useEffect(() => {
    document
      .getElementById("UsingForHeader1")
      .addEventListener("scroll", (e) => {
        var yAxis = $(".banner_slider").offset().top;

        if (yAxis <= -60) {
          $(".header").hide();
        } else {
          $(".header").show();
        }
      });
  }, []);

  return (
    <>
      <Header />
      <div className="height_one_csr_leo">
        <div className="Hop_leo_main">
          <div className="hop-leo" id="UsingForHeader1">
            <div className="banner_slider" id="ContactUs1">
              <div
                className="overlay_img"
                style={{ display: openModal ? "block" : "none" }}
              >
                <img
                  src={BannerOver}
                  alt="Image"

                  onClick={() => setopenModal(false)}
                />
              </div>
              <figure>
                {" "}
                <div
                  style={{ width: "100%", display: "block", cursor: "pointer" }}
                  onClick={() => setScrollwise(true)}
                >
                  <Tridi
                    location={image}
                    format="png"
                    count="23"
                    mousewheel={true}
                    inverse={scrollwise}
                    autoplay={false}
                  />
                </div>{" "}
              </figure>
            </div>
            <div className="product-gallery-block">
              <div className="uk-container">
                <div className="gallery-flex" uk-lightbox="">
                  <div
                    className="width-60"
                    onClick={() => setModelSiliderOne(true)}
                  >
                    <img src={Lightboximg1} alt="image" />
                  </div>
                  <div
                    className="width-40"
                    onClick={() => setModelSiliderOne(true)}
                  >
                    <img src={Lightboximg2} alt="image" />
                  </div>
                  <div
                    className="width-40"
                    onClick={() => setModelSiliderOne(true)}
                  >
                    <img src={Lightboximg3} alt="image" />
                  </div>
                  <div
                    className="width-60"
                    onClick={() => setModelSiliderOne(true)}
                  >
                    <img src={Lightboximg4} alt="image" />
                  </div>
                  <div
                    className="width-100"
                    onClick={() => setModelSiliderOne(true)}
                  >
                    <img src={Lightboximg5} alt="image" />
                  </div>
                </div>
              </div>
            </div>

            <div className="modalpopup">
              <PureModal
                onClose={() => {
                  setModelSiliderOne(false);
                  return true;
                }}
                width="100%"
                scrollable={true}
                isOpen={modalsiliderone}
              >
                <SiliderPopup />
              </PureModal>
            </div>

            <div className="description_sec">
              <h2>Ride At Just 20 Paisa Per Km With Hop Electric</h2>
              <p>
                Enjoy The Convince And Joy Of Riding Without Burning A Hole In
                Your Pocket. While Petrol Scooters Run Just 70 Kms For ₹100, Hop
                Ev Electric Scooter Gives You 700 Kms In The Same ₹100.
              </p>
            </div>

            <div className="full_video_bike">
              <div className="video_sec_step">
                <div className="vimeo-video">
                  <iframe
                    src="https://player.vimeo.com/video/850886959?background=1&muted=1&autoplay=1&loop=1"
                    frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    mozAllowFullScreen
                    allowFullScreen
                    webkitAllowFullScreen
                    title="Hop_Leo_x_Lyf_promo_video"
                  ></iframe>
                </div>

              </div>
            </div>

            <div className="product-details-block">
              <div className="uk-container">
                <div className="product-details-section bg-blue d-flex">
                  <div className="product-details">
                    <div className="uk-padding-large">
                      <h2 className="uppercase">3 RIDE MODES</h2>
                      <p className="uppercase">WITH PARK ASSIST</p>
                      <p className="font-24">
                        <b>Bold &amp; Stylish</b>
                      </p>
                      <p className="uk-margin-small-bottom">
                        The bold looking Hop LEO battery scooty and electric
                        scooter bike is designed to create presence.
                      </p>
                      <p className="uk-margin-small-bottom">
                        Looks that makes statement not noise.
                      </p>
                      <p className="uk-margin-small-bottom">
                        Go forward or Go Backward with 3 riding modes and
                        reverse out of tough spots easily with park assist
                        feature.
                      </p>
                    </div>
                  </div>
                  <div className="produt-img">
                    <img src={ride_modes} type="social_imgs" />
                  </div>
                </div>
                <div className="product-details-section bg-blue d-flex">
                  <div className="produt-img">
                    <img src={scooter_img1} type="social_imgs" />
                  </div>
                  <div className="product-details">
                    <div className="uk-padding-large">
                      <h2 className="uppercase">2200 W</h2>
                      <p className="uppercase">Peak Power</p>
                      <p className="font-24">
                        <b>BETTER PERFORMANCE</b>
                      </p>
                      <p className="uk-margin-small-bottom">
                        Cruise the roads effortlessly at a top speed of{" "}
                        <b>52 Kmph</b>. With <b>90 Nm</b> of wheel torque zoom
                        past the traffic at every red light or climb a flyover
                        with 180 kgs of load with ease and comfort.{" "}
                        <b>72V BLDC Hub motors</b> powering the LEO electric
                        scooter is <b>IP 66 rated</b> so no worries of riding in
                        rain.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="product-details-section bg-blue d-flex">
                  <div className="product-details">
                    <div className="uk-padding-large">
                      <h2 className="uppercase">120 KMS</h2>
                      <p className="">Range per charge</p>
                      <p className="font-24">
                        <b>Easy Charging</b>
                      </p>
                      <p className="uk-margin-small-bottom">
                        Charge your LEO electric scooter anywhere with a
                        standard 15 Amp power socket. Weather at home or at work
                        just plug in your scooter and get 120 kms of range in
                        just three and a half hours.
                      </p>
                      <p className="uk-margin-small-bottom">
                        Hop LEO is available with two battery options.
                      </p>
                      <p className="uk-margin-small-bottom">
                        20 Ah with 90 Kms of range per charge
                      </p>
                      <p className="uk-margin-small-bottom">
                        29 Ah with 120 Kms of range per charge
                      </p>
                    </div>
                  </div>
                  <div className="produt-img">
                    <img src={scooter_img2} type="social_imgs" />
                  </div>
                </div>
                <div className="product-details-section bg-blue d-flex">
                  <div className="produt-img">
                    <img src={scooter_img3} type="social_imgs" />
                  </div>
                  <div className="product-details">
                    <div className="uk-padding-large">
                      <h2 className="uppercase">3 YEAR</h2>
                      <p className="uppercase">WARRANTY</p>
                      <p className="font-24">
                        <b className="uppercase">EASY OWNERSHIP</b>
                      </p>
                      <p className="uk-margin-small-bottom">
                        LEO electric scooter comes with 3 years of warranty so
                        that you can enjoy your journey without worries.
                      </p>
                      <p className="uk-margin-small-bottom">
                        Just for your information total cost of owning an
                        electric scooter is just 15% as compared a petrol
                        counterpart as running cost and service cost both are
                        very low.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="product-details-section bg-blue d-flex">
                  <div className="product-details">
                    <div className="uk-padding-large">
                      <h2 className="">Safety</h2>
                      <p className="">with style</p>
                      <p className="font-24">
                        <b className="">Advanced features</b>
                      </p>
                      <p className="uk-margin-small-bottom">
                        Hop LEO electric scooter comes with advanced features
                        front disc brake, electronically assisted braking
                        system, anti-theft wheel lock with alarm, remote keyless
                        ignition, and GPS and GSM* connectivity for better
                        safety.
                      </p>
                    </div>
                  </div>
                  <div className="produt-img">
                    <img src={scooter_img4} type="social_imgs" />
                  </div>
                </div>

              </div>
            </div>

            <Tabing />
            <SelectBikeLeo />

            <Productslider />

            <div className="formpopup">
              <PureModal
                onClose={() => {
                  setModelSiliderForm(false);
                  return true;
                }}
                scrollable={true}
                isOpen={modalsiliderForm}
              >
                <Newsletterform
                  modalsiliderForm={modalsiliderForm}
                  setModelSiliderForm={setModelSiliderForm}
                />
              </PureModal>
            </div>
          </div>
        </div>
        <div className="faq_sec HOP_LEO_FAQ" id="faq">
          <div className="">

          </div>
          <div className="landing_page_container">
            <div className="sec_heading">
              <h2 className="mb-2">
                <Link to={"/faq"}>FAQs</Link>
              </h2>
              <h2 className="mb-2">
                <a
                  href={process.env.REACT_APP_WEBSITE_URL + "/community"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Media
                </a>

              </h2>
              <h2 className="mb-2">

                <a
                  href={
                    process.env.REACT_APP_WEBSITE_URL + "/community/blogs"
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Blog
                </a>
              </h2>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
