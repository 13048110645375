import React, { useEffect } from "react";
import { useState } from "react";
import { NewsLetterAPI } from "../../../APIs";
import { toast } from "react-toastify";

const NewsletterForm = ({ modalsiliderForm, setModelSiliderForm }) => {
  const [email, setEmail] = useState("");
  const [Name, setName] = useState("");
  const [NameErr, setNameErr] = useState("");
  const [emailErr, setEmailErr] = useState("");

  const submitForm = async (event) => {
    event.preventDefault();
    const NameRegex = /^[a-z ,.'-]+$/i;
    const EmailRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    let err = 0;
    if (Name == "") {
      setNameErr("Name Field is Required.");
      err++;
    } else if (!NameRegex.test(Name)) {
      setNameErr("Enter valid Name.");
      err++;
    } else {
      setNameErr("");
    }

    if (email == "") {
      setEmailErr("Email Field is Required.");
      err++;
    } else if (!EmailRegex.test(email)) {
      setEmailErr("Enter valid Email.");
      err++;
    } else {
      setEmailErr("");
    }
    if (err == 0) {
      const data = {
        name: Name,
        email: email,
      };
      let response = await NewsLetterAPI(data);
      if (response.status == 200) {
        setModelSiliderForm(false);
        toast.success("Newsletter Subscribed Successfully.", {
          autoClose: 1500,
        });
      }
    }
  };
  useEffect(() => {}, []);
  return (
    <div className="newslettterform">
      <div className="form_body">
        <h2 className="from_title">Subscribe to our newsletter</h2>
        <form action="#" id="form">
          <div className="customContactStyle newsletterCustom">
            <div className="form-group">
              <input
                id="Name"
                type="text"
                class="form-control"
                placeholder="Name"
                value={Name}
                onChange={(e) => setName(e.target.value)}
              />
              <small style={{ color: "red" }}>{NameErr}</small>
            </div>
            <div className="form-group">
              <input
                type="text"
                class="form-control"
                placeholder="Email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <small style={{ color: "red" }}>{emailErr}</small>
            </div>
            <input
              type="submit"
              onClick={submitForm}
              value="Submit"
              className="btn btn-primary"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewsletterForm;
