import React from 'react'
import "./index.css";
import LEO_LYF from "../../../assets/images/about_us/LEO_LYF.png";
import OXO_Image from "../../../assets/images/about_us/OXO_Image.png";

const Section5 = () => {
  return (
    <>
      <div class="sec_2_content">
        <div className="container_about_us">
          <h2>Products & Technology</h2>
          <div className="image_box_wrapper">
            <div className="image_box_list">
              <img  src={LEO_LYF} alt="image not found" />
              <div className="bikes_type">
                <div className="bike_name1">
                  <p className="hop_lf">HOP LYF</p>
                  <p className="lfe_desc">LOW SPEED ELECTRIC SCOOTER</p>
                </div>
                <div className="bike_name2">
                  <p className="hop_leo">HOP LEO</p>
                  <p className="leo_desc">MID SPEED ELECTRIC SCOOTER</p>
                </div>
              </div>
            </div>
            <div className="image_box_list mb-0">
              <img  src={OXO_Image} alt="image not found" />
              <div className="bike_name3">
                <p className="hop_ox">HOP OXO</p>
                <p className="ox_desc">HIGH SPEED ELECTRIC MOTORCYLE</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Section5