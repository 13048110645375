import React, { useEffect, useState } from "react";
import "../Testride/test-ride.css";
import { useNavigate } from "react-router-dom";
import logo_right from "../../assets/images/dx.png";
import popuplogomo from "../../assets/images/mo_popuplogo.png";
import confirm_bike from "../../assets/images/Confirmation_bike.png";
import bike_re_conf from "../../assets/images/conf-bike.png";
import { Config } from "../../utils/apiUrl";
import PureModal from "react-pure-modal";
import $ from "jquery";
import Footer from "../../components/common/layout/Footer";
import Header from "../../components/common/layout/Header";
import { useScrollTo } from "../../hooks/useScrollTo";
import { getCookie } from "../../utils";

const HomeAddressMap = () => {
  const navigate = useNavigate();
  useScrollTo(0,0);
  const [house, seTHouse] = useState("");
  const [lendmark, seTLandmark] = useState("");
  const [number, seTnumber] = useState("");
  const [houseerror, setHouseerroer] = useState(false);
  const [numberformaterror, setnumbervalidation] = useState(false);
  const [localdata, setlocaldata] = useState(null);
  const [home, setHome] = useState("Home");
  const [open, setopen] = useState(false);
  const [ticket, setTicketid] = useState("");
  const [previousTicketData, setPreviousTicketData] = useState({});
  const [loader, setLoader] = useState(false);
  const [myLat, setMyLat] = useState();
  const [myLong, setMyLong] = useState();

  const getCookiedata = (expiredata) => {
    const cookieData = getCookie(expiredata);
    if (!cookieData) {
      navigate("/");
    }
  };

  useEffect(() => {
    getCookiedata("userdata");
  }, []);

  const getdealerdata = async () => {
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization:
          "Bearer " + JSON.parse(getCookie("userdata")).user_token,
      },
      body: JSON.stringify({
        pincode: JSON.parse(localStorage.getItem("pincode")),
        user_id: JSON.parse(getCookie("userdata")).user_id,
        lat:myLat,
        lng:myLong
      }),
    };
    try {
      const response = await fetch(Config.pincode, requestOptions);
      const json = await response.json();
      if (
        json.testdrive_ticket_id !== null ||
        json.testdrive_ticket_id !== "" ||
        json.testdrive_ticket_id !== " "
      ) {         
        setTicketid(json.testdrive_ticket_id);
        setPreviousTicketData(json.testdrive_dealer_details);
        setopen(true);
      }
      if (
        json.testdrive_ticket_id == null ||
        json.testdrive_ticket_id == "" ||
        json.testdrive_ticket_id == " "
      ) {
        setopen(false);
        booktestride();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const homeaddress = () => {
    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (house === "" || house === " " || house === null) {
      setHouseerroer(true);
      return false;
    } else {
      setHouseerroer(false);
    }
    if (number.length > 0) {
      if (number.length < 10 || !number.match(phoneno)) {
        setnumbervalidation(true);
        return false;
      } else {
        setnumbervalidation(false);
      }
    }
    getdealerdata();
  };

  const booktestride = async () => {
    const requestOptionsForOtpVarification = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(getCookie("userdata")).user_token,
      },
      body: JSON.stringify({
        phone_num: JSON.parse(getCookie("userdata")).user_number,
        email: JSON.parse(getCookie("userdata")).user_email,
        name: JSON.parse(getCookie("userdata")).user_name,
        pin_code: JSON.parse(localStorage.getItem("pincode")),
        address: lendmark !== "" ? `${house} ${lendmark}` : house,
        alt_num: number,
        source: "Online",
        hometestride_id: `${Number(localdata?.hometestride_id)}`,
        slot: `${Number(localdata?.hometestride_slots)}`,
        user_id: JSON.parse(getCookie("userdata")).user_id,
      }),
    };
    try {
      setLoader(true);
      const response = await fetch(
        Config.booktestride,
        requestOptionsForOtpVarification
      );
      const json = await response.json();
      nexts();
      function nexts(conditons = false) {
        if (
          conditons == true ||
          json.message == "Home test ride booked successfully"
        ) {
          setLoader(false);
          seTHouse("");
          seTnumber("");
          seTLandmark("");
          let storebookdata = {
            dealer:
              lendmark !== ""
                ? `${house} ${lendmark} ${number}`
                : `${house} ${number}`,
            date: localdata?.hometestride_date,
            time: localdata?.hometestride_time,
            order_id: json.order_id,
            ticket_id: json.ticket_id,
          };
          localStorage.setItem("dealerdata", JSON.stringify(storebookdata));
          navigate(`/homeconfirmation/${json.ticket_id}/${home}`);
        } else {
          return false;
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const CancelTestrides = async () => {
    const requestOptionsForOtpVarification = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(getCookie("userdata")).user_token,
      },
      body: JSON.stringify({
        ticket_id: `${Number(ticket)}`,
        reason: " Booked Another Test Ride",
      }),
    };
    try {
      const response = await fetch(
        Config.cancel_testride,
        requestOptionsForOtpVarification
      );
      const json = await response.json();
      if (json.data == "Test drive cancelled successfully") {
        navigate("/homeaddressmap");
        booktestride();
        return true;
      } else if (json.error) {
        alert("Already cancelled");
        return false;
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    setlocaldata(JSON.parse(localStorage.getItem("homtestridedata")));
  }, []);


  useEffect(() => {
    $(document).ready(function () {
      $(".header").show();
    });

    $(window).on("load", function () {
      $(".header").show();
    });
  }, []);

  function getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      console.log("Browser Not Supported");
    }
  }
  function showPosition(position) {
    setMyLat(position.coords.latitude);
    setMyLong(position.coords.longitude);
  }

  useEffect(() => {
    getLocation();
  }, [])
  return (
    <>
      <Header />
      <div className="height_one_csrol">
        <div id="home-add-test-ride" className="top_header_pm">
          <div className="testride_map select_dealer map_img_add homeadd_map">
            <div className="map_left Confirmation_left displeymobile_none">
              <figure>
                <img  src={confirm_bike} alt="imhvgb" />
              </figure>
              <div className="after_savings">
                <figure>
                  <img  src={bike_re_conf} alt="imhvgb" />
                </figure>
              </div>
            </div>
            <div className="testride_righsec map_right_address">
              <div className="logo_right_cont destop_show">
                <img  src={logo_right} alt="imhvgb" />
              </div>
              <div className="only_mobileshow mobile_show">
                <img  src={popuplogomo} alt="imhvgb" />
                <h3 className="mobile_show">Give Us Some Details</h3>
              </div>
              <h3>Enter your address</h3>

              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  value={house}
                  onChange={(e) => seTHouse(e.target.value)}
                  placeholder="House/Flat/Block no."
                />
              </div>
              {houseerror === true ? (
                <span
                  className="error pl-4"
                  style={{
                    color: "red",
                    fontSize: "19px",
                    marginBottom: "20px",
                    display: "block",
                  }}
                >
                  Please Enter House/Flat/Block no
                </span>
              ) : null}
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  value={lendmark}
                  onChange={(e) => seTLandmark(e.target.value)}
                  placeholder="Landmark(Optional)"
                />
              </div>

              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  maxLength="10"
                  value={number}
                  onChange={(e) => seTnumber(e.target.value)}
                  placeholder="Alternate Contact Number (optional)"
                />
                {numberformaterror === true ? (
                  <span
                    className="error pl-4"
                    style={{ color: "red", fontSize: "19px" }}
                  >
                    Invalid Phone Number
                  </span>
                ) : null}
              </div>

              <div className="form-group next_btn">
                <button
                  onClick={homeaddress}
                  className="default_btn"
                  style={{
                    opacity: loader ? 0.3 : "",
                    pointerEvents: loader ? "none" : "",
                  }}
                >
                  Book Test Ride{" "}
                </button>
              </div>

              <div
                className="form-group modal_popup test_ride_popup book_another close_button"
                id="cancel_testride"
              >
                <PureModal
                  onClose={() => {
                    setopen(false);
                    return true;
                  }}
                  isOpen={open}
                  closeButton={
                    <div
                      className="close_btn"
                      onClick={() => setopen(false)}
                    ></div>
                  }
                  closeButtonPosition="top"
                  width="100%"
                >
                  <div className="popup_cont requested">
                    <h3>Cancel Previous test Ride and continue?</h3>
                    <p>
                      only 1 booking is allowed a time continuing with this
                      booking will cancel the previous one
                    </p>
                    <p>
                      <span>Location</span>
                      {previousTicketData?.dealer_address}
                    </p>
                    <p>
                      <span>Date</span> {previousTicketData?.testdrive_date}
                    </p>
                    <p>
                      <span>Time</span> {previousTicketData?.time_slot}
                    </p>
                    <div className="book_anpo">
                      <button
                        className="user_btn border_btn"
                        onClick={() => setopen(false)}
                      >
                        No
                      </button>
                      <button
                        className="user_btn"
                        onClick={() => CancelTestrides()}
                      >
                        Yes
                      </button>
                    </div>
                  </div>
                </PureModal>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};
export default HomeAddressMap;
