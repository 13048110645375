import React from "react";
import DetailFooter from "./DetailFooter";
import ContactFooter from "./ContactFooter";
import { Link } from "react-router-dom";

function FooterLanding() {
  return (
    <>
      <div id="footer" className="landing_footer">
        <div>
          <div className="faq_sec" id="faq">
            <div className="landing_page_container">
              <div className="sec_heading">
                <h2 className="mb-2">
                <Link to={"/faq"}>FAQs</Link>
                </h2>
                <h2 className="mb-2">
                  <a
                    href={process.env.REACT_APP_WEBSITE_URL + "/community"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Media
                  </a>
                </h2>
                <h2 className="mb-2">
                  <a
                    href={
                      process.env.REACT_APP_WEBSITE_URL + "/community/blogs"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Blog
                  </a>
                </h2>
              </div>
            </div>
          </div>
          <DetailFooter />
          <ContactFooter />
        </div>
      </div>
    </>
  );
}
export default FooterLanding;
