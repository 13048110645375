import React from "react";
import "../Testride/test-ride.css";
import logo_right from "../../assets/images/dx.png";
import confirm_bike from "../../assets/images/Confirmation_bike.png";
import bike_re_conf from "../../assets/images/conf-bike.png";
import popuplogomo from "../../assets/images/mo_popuplogo.png";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "../../components/common/layout/Footer";
import Header from "../../components/common/layout/Header";
import { useScrollTo } from "../../hooks/useScrollTo";

const Confirmation = () => {
  useScrollTo(0, 0);
  const navigate = useNavigate();
  const { orderid } = useParams();


  return (
    <>
      <Header />
      <div className="height_one_csrol" id="confirmation_page">
        <div id="Confirmation" className="top_header_pm">
          <div className="testride_map Confirmation">
            <div className="map_left Confirmation_left displeymobile_none">
              <figure>
                <img  src={confirm_bike} alt="imhvgb" />
              </figure>

              <div className="after_savings">
                <figure>
                  <img  src={bike_re_conf} alt="imhvgb" />
                </figure>

              </div>
            </div>

            <div className="testride_righsec order_id">
              <div className="logo_right_cont destop_show">
                <img  src={logo_right} alt="imhvgb" />
              </div>

              <div className="only_mobileshow mobile_show">
                <img  src={popuplogomo} alt="imhvgb" />
                <h3 className="mobile_show">Ride Confirmed</h3>
              </div>

              <h3 className="destop_show">Confirmation</h3>

              <h3 className="mobile_show">
                Your ride has been Booked successfully 
              </h3>

              <div className="order_sec_id">
                <p className="destop_show">
                  Your ride has been Booked successfully
                </p>
                <strong>
                  <span>Order ID :</span> #{orderid}
                </strong>
              </div>
              <div className="next_btn">
                <button
                  onClick={() => navigate("/Dashboardorder")}
                  className="default_btn"
                >
                  Back To dashboard
                </button>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default Confirmation;
