import { useState } from "react";
import { useParams,useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import "../Testride/test-ride.css";
import logo_right from "../../assets/images/dx.png";
import popuplogomo from "../../assets/images/mo_popuplogo.png";
import { updateUserAddress } from "../../actions";
import TestRideGoogleMap from "./TestRideGoogleMap";
import Header from "../../components/common/layout/Header";

const TestRideMap = () => {
  const [house, setHouse] = useState(null);
  const [userpincode, setUserpincode] = useState(null);
  const [altPhone, setAltPhone] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { lat, lng, dealerid } = useParams();

  const next = () => {
    if (house == null || house == "" || house == " ") {
      return false;
    }
    if (
      userpincode == "" ||
      userpincode == " " ||
      userpincode == null ||
      userpincode.length < 6 ||
      userpincode.length > 6
    ) {
      return false;
    }
    if (altPhone.length > 0) {
      if (altPhone.length < 10) {
        return false;
      }
    }
    dispatch(
      updateUserAddress({
        house: house,
        userpincode: userpincode,
        altPhone: altPhone,
      })
    );
    navigate(`/booktestride/${lat}/${lng}/${dealerid}`);
  };

  const handlePhone = (e) => {
    let altphoneLenth = e.target.value.length;
    if (altphoneLenth < 11) {
      setAltPhone(e.target.value);
    }
  };

  function handlePincode(e) {
    let altPincodeLength = e.target.value.length;
    if (altPincodeLength < 7) {
      setUserpincode(e.target.value);
    }
  }

  const date = new Date();
  const estimateMonth = date.getMonth() + 1;
  const estimateYear = date.getFullYear();
  const newDate = new Date(estimateYear, estimateMonth + 3);
  const dateName = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const estimate_date =
    dateName[estimateMonth] +
    " - " +
    dateName[newDate.getMonth()] +
    " " +
    newDate.getFullYear();
    
  return (
    <>
      <Header />
      <div id="test-ride" className="top_header_pm">
        <div className="testride_map select_dealer map_img_add">
          <TestRideGoogleMap />

          <div className="testride_righsec map_right_address">
            <div className="logo_right_cont destop_show">
              <img  src={logo_right} alt="imhvgb" />
              <p>Est. Delivery Date: {estimate_date}</p>
            </div>

            <div className="only_mobileshow mobile_show">
              <img  src={popuplogomo} alt="imhvgb" />
              <h3 className="mobile_show">Give Us Some Details</h3>
            </div>

            <h3>Enter your address</h3>
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="form-group">
                <input
                  type="text"
                  required={true}
                  value={house}
                  onChange={(e) => setHouse(e.target.value)}
                  className="form-control"
                  placeholder="House/Flat/Block no."
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  required={true}
                  onChange={handlePincode}
                  value={userpincode}
                  className="form-control"
                  placeholder="Pin Code"
                />
              </div>
              <div className="form-group">
                <input
                  type="number"
                  value={altPhone}
                  onChange={handlePhone}
                  className="form-control"
                  placeholder="Alternate Contact Number (optional)"
                />
              </div>
              <div className="form-group next_btn">
                <button onClick={() => next()} className="default_btn">
                  Next
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default TestRideMap;
