import React from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import "react-pure-modal/dist/react-pure-modal.min.css";
import PureModal from "react-pure-modal";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import { useScrollTo } from "../../../hooks/useScrollTo";

function BookAnother() {
  useScrollTo(0, 0);
  const navigate = useNavigate();
  const [showcancel, opencancel] = useState(false);
  const [showcancedata1, opencancedata1] = useState(false);
  const section = document.querySelector("#price_sec");

  return (
    <>
      <Header />
      <div className="active_rides bookanother_sec">
        {/* <div className="dashboard_heading">
          <h2>Book Another Tes tRide </h2>
        </div> */}
        {/* <table className="user_info_table recent_acti">
          <thead>
            <tr>
              <th>Date</th>
              <th>Reference No</th>
              <th>Activity</th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>01-01-2023</td>
              <td className="order_co">Order Id: 1258</td>
              <td>TestRide</td>
              <td>
                <button className="user_btn" onClick={() => { navigate("/booktestride") }}>Reschedule</button>
                <div className="cancel_btnsec">
                  <button className="cancel_btn" onClick={() => opencancedata1(true)}>Cancel</button>
                </div>
              </td>
            </tr>

            <tr>
              <td>08-01-2023</td>
              <td className="order_co">SRN No: 0012</td>
              <td>Complaint</td>
              <td></td>
            </tr>

            <tr>
              <td>11-01-2023</td>
              <td className="order_co">Order Id: 5321</td>
              <td>Pre Booking</td>
              <td>
                <button className="user_btn" onClick={() => { navigate("/booktestride") }}>Modify Booking</button>
                <div className="cancel_btnsec">
                  <button className="cancel_btn" onClick={() => opencancedata1(true)}>Cancel</button>
                </div>
              </td>
            </tr>

            <tr>
              <td>20-12-2022</td>
              <td className="order_co">Order Id: 1793</td>
              <td>TestRide (Completed)</td>
              <td></td>
            </tr>

            <tr>
              <td>05-01-2023</td>
              <td className="order_co">Order Id: 5812</td>
              <td>TestRide (Cancelat)</td>

              <td>
                <button className="user_btn" onClick={() => { navigate("/booktestride") }}>Re Booking</button>
              </td>
            </tr>

          </tbody>
        </table> */}

        {/* <div className="das_showlink">
          <ul>
            <li>
              <button className="default_btn" onClick={() => { navigate("/price_sec") }}>Book A Vehicle</button>
              <button className="default_btn" onClick={() => { navigate("/booktestride") }}>Book A Test Ride</button>
            </li>
          </ul>
        </div> */}

        <div
          className="form-group modal_popup test_ride_popup form-group modal_popup close_button dasboard_cancel"
          id="cancel_testride"
        >
          <PureModal
            onClose={() => {
              return true;
            }}
            // isOpen={showcance3}
            closeButton={<div className="close_btn"></div>}
            closeButtonPosition="top"
            width="100%"
          >
            <div className="popup_cont requested">
              <h3>Cancel Previous test Ride and continue?</h3>
              <p>
                <span>Location</span>
              </p>
              <p>
                <span>Date</span>{" "}
              </p>
              <p>
                <span>Time</span>{" "}
              </p>
              <p>Do you want to cancel ?</p>
              <div className="book_anpo">
                <button className="user_btn border_btn">No</button>
                <button className="user_btn">Yes</button>
              </div>
            </div>
          </PureModal>
        </div>
      </div>
    </>
  );
}

export default BookAnother;
