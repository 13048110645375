import React from 'react'
import "./index.css";
import img1 from "../../../assets/images/about_us/img1.png";
import img2 from "../../../assets/images/about_us/img2.png";
import img3 from "../../../assets/images/about_us/img3.png";
import img4 from "../../../assets/images/about_us/img4.png";
import img5 from "../../../assets/images/about_us/img5.png";
import img6 from "../../../assets/images/about_us/img6.png";
import img7 from "../../../assets/images/about_us/img7.png";
import img8 from "../../../assets/images/about_us/img8.png";
import img9 from "../../../assets/images/about_us/img9.png";
import img10 from "../../../assets/images/about_us/img10.png";
import img11 from "../../../assets/images/about_us/img11.png";
import img12 from "../../../assets/images/about_us/img12.png";
import img13 from "../../../assets/images/about_us/img13.png";
import img14 from "../../../assets/images/about_us/img14.png";

const Section9 = () => {
  return (
    <>
      <div class="sec_9_content">
        <div className="container_about_us">
          <h2>TEAM</h2>
          <ul className="team-list">
            <li className="team-item">
              <figure>
                <img  src={img1} alt="" />
              </figure>
              <p className="member-name selection_background">Ketan Mehta</p>
              <p className="member-degi selection_background">Co Founder & CEO</p>
            </li>
            <li className="team-item">
              <figure>
                <img  src={img2} alt="" />
              </figure>
              <p className="member-name selection_background">Nikhil Bhatia</p>
              <p className="member-degi selection_background">Co Founder & CBO</p>
            </li>
            <li className="team-item">
              <figure>
                <img  src={img3} alt="" />
              </figure>
              <p className="member-name selection_background">Rahil Gupta</p>
              <p className="member-degi selection_background">Co Founder & CTO</p>
            </li>
          </ul>

          <ul className="team-list team-row2">
            <li className="team-item">
              <figure>
                <img  src={img4} alt="" />
              </figure>
              <p className="member-name selection_background">Rajneesh Singh</p>
              <p className="member-degi selection_background">Head of Marketing</p>
            </li>
            <li className="team-item">
              <figure>
                <img  src={img5} alt="" />
              </figure>
              <p className="member-name selection_background">Yogesh Pandey</p>
              <p className="member-degi selection_background">Head of Operations & Service</p>
            </li>
            <li className="team-item">
              <figure>
                <img  src={img6} alt="" />
              </figure>
              <p className="member-name selection_background">Ravi S kishore</p>
              <p className="member-degi selection_background">Head of Sales : South</p>
            </li>
            <li className="team-item">
              <figure>
                <img  src={img7} alt="" />
              </figure>
              <p className="member-name selection_background">Amrendra Patel</p>
              <p className="member-degi selection_background">Head of Sales - West</p>
            </li>
            <li className="team-item">
              <figure>
                <img  src={img8} alt="" />
              </figure>
              <p className="member-name selection_background">Ashish Jain</p>
              <p className="member-degi selection_background">Financial Controller</p>
            </li>
          </ul>

          <ul className="team-list team-row3">
            <li className="team-item">
              <figure>
                <img  src={img9} alt="" />
              </figure>
              <p className="member-name selection_background">Om D Vashisht</p>
              <p className="member-degi selection_background">Head of SCM</p>
            </li>
            <li className="team-item">
              <figure>
                <img  src={img10} alt="" />
              </figure>
              <p className="member-name selection_background">Abhishek Mishra</p>
              <p className="member-degi selection_background">Quality Lead</p>
            </li>
            <li className="team-item">
              <figure>
                <img  src={img11} alt="" />
              </figure>
              <p className="member-name selection_background">Nihit Yadav</p>
              <p className="member-degi selection_background">Vehicle Integration Lead</p>
            </li>
            <li className="team-item">
              <figure>
                <img  src={img12} alt="" />
              </figure>
              <p className="member-name selection_background">Prateek Gupta</p>
              <p className="member-degi selection_background">Engineering Lead</p>
            </li>
            <li className="team-item">
              <figure>
                <img  src={img13} alt="" />
              </figure>
              <p className="member-name selection_background">Taran S Salaria</p>
              <p className="member-degi selection_background ">Design Lead</p>
            </li>
            <li className="team-item">
              <figure>
                <img  src={img14} alt="" />
              </figure>
              <p className="member-name selection_background">Utkarsh Pandey</p>
              <p className="member-degi selection_background">Service Lead</p>
            </li>
          </ul>

        </div>
      </div>
    </>
  )
}

export default Section9