import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import $ from "jquery";

import "../Testride/test-ride.css";

import logo_right from "../../assets/images/dx.png";
import confirm_bike from "../../assets/images/Confirmation_bike.png";
import bike_re_conf from "../../assets/images/conf-bike.png";

import Footer from "../../components/common/layout/Footer";
import Header from "../../components/common/layout/Header";

import { Config } from "../../utils/apiUrl";
import { getCookie } from "../../utils";

const CancelTestrides = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [checkedtext, setCheckedtext] = useState("");
  const [userdata, setUserdata] = useState([]);
  const [cancelTicketId, setCancelTicketId] = useState(null);

  useEffect(() => {
    getCookiedata("userdata");
  }, []);

  useEffect(() => {
    setCancelTicketId(window.location.pathname.split("/")[2]);
  }, []);

  useEffect(() => {
    $(document).ready(function () {
      $(".header").show();
    });

    $(window).on("load", function () {
      $(".header").show();
    });
  }, []);

  // header scroll hide function
  useEffect(() => {
    document
      .getElementById("confirmation_page")
      .addEventListener("scroll", (e) => {
        var yAxis = $(".Confirmation").offset().top;

        if (yAxis < 0) {
          $(".header").hide();
        } else {
          $(".header").show();
        }
      });
  }, []);

  const canceltestride = (e) => {
    setCheckedtext(e.target.nextSibling.innerHTML);
  };

  const getCookiedata = (expiredata) => {
    const cookieData = getCookie(expiredata);
    if (!cookieData) {
      navigate("/");
    } else {
      var userDataDetail = JSON.parse(cookieData);
      setUserdata(userDataDetail);
    }
  };

  const canceltestridedata = async () => {
    let ticketId = "";
    if (cancelTicketId) {
      ticketId = cancelTicketId;
    } else {
      ticketId = params.id;
    }
    let label1 = document.getElementById("label1").innerHTML;
    const requestOptionsForOtpVarification = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(getCookie("userdata")).user_token,
      },
      body: JSON.stringify({
        ticket_id: `${ticketId}`,
        reason: checkedtext ? checkedtext : label1,
      }),
    };
    try {
      const response = await fetch(
        Config.cancel_testride,
        requestOptionsForOtpVarification
      );
      const json = await response.json();
      if (json.data == "Test drive cancelled successfully") {
        navigate("/CancelOrderids/" + params.id);
        return true;
      } else if (json.error) {
        alert("Already cancelled");
        return false;
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Header />
      <div className="height_one_csrol" id="confirmation_page">
        <div id="Confirmation" className="top_header_pm">
          <div className="testride_map Confirmation">
            <div className="map_left Confirmation_left displeymobile_none">
              <figure>
                <img src={confirm_bike} alt="imhvgb" />
              </figure>
              <div className="after_savings">
                <figure>
                  <img src={bike_re_conf} alt="imhvgb" />
                </figure>
              </div>
            </div>

            <div className="testride_righsec order_id check_radio">
              <div className="logo_right_cont destop_show">
                <img src={logo_right} alt="imhvgb" />
              </div>
              <div className="only_mobileshow mobile_show">
              </div>
              <h3>We would like to know the reason for cancellation </h3>
              <form className="cancel_ride">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="exampleRadios"
                    id="exampleRadios1"
                    onChange={(e) => canceltestride(e)}
                    defaultValue="option1"
                    defaultChecked
                  />
                  <label
                    className="form-check-label"
                    htmlFor="exampleRadios1"
                    id="label1"
                  >
                    Booked by mistake.
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="exampleRadios"
                    id="exampleRadios2"
                    onChange={(e) => canceltestride(e)}
                    defaultValue="option2"
                  />
                  <label className="form-check-label" htmlFor="exampleRadios2">
                    Not available at moment will take test ride later.
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="exampleRadios"
                    id="exampleRadios3"
                    onChange={(e) => canceltestride(e)}
                    defaultValue="option3"
                  />
                  <label className="form-check-label" htmlFor="exampleRadios3">
                    Brand Champion is not reachable.
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="exampleRadios"
                    id="exampleRadios4"
                    onChange={(e) => canceltestride(e)}
                    defaultValue="option4"
                  />
                  <label className="form-check-label" htmlFor="exampleRadios4">
                    HOP Brand Champion did not arrive on time.
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="exampleRadios"
                    id="exampleRadios5"
                    onChange={(e) => canceltestride(e)}
                    defaultValue="option5"
                  />
                  <label className="form-check-label" htmlFor="exampleRadios5">
                    HOP Brand Champion refused to come or denied his duty.
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="exampleRadios"
                    id="exampleRadios6"
                    onChange={(e) => canceltestride(e)}
                    defaultValue="option6"
                  />
                  <label className="form-check-label" htmlFor="exampleRadios6">
                    I'm directly interested in purchase, test ride is not
                    required.
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="exampleRadios"
                    id="exampleRadios7"
                    onChange={(e) => canceltestride(e)}
                    defaultValue="option7"
                  />
                  <label className="form-check-label" htmlFor="exampleRadios7">
                    Not looking to buy right now, might buy in future.
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="exampleRadios"
                    id="exampleRadios8"
                    onChange={(e) => canceltestride(e)}
                    defaultValue="option8"
                  />
                  <label className="form-check-label" htmlFor="exampleRadios8">
                    Other
                  </label>
                </div>
              </form>
              <div className="next_btn">
                <button
                  onClick={() => canceltestridedata()}
                  className="default_btn"
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default CancelTestrides;
