import { useNavigate } from "react-router-dom";
import "../Testride/test-ride.css";
import search_addmap from "../../assets/images/add_mapleft.png";
import logo_right from "../../assets/images/dx.png";
import popuplogomo from "../../assets/images/mo_popuplogo.png";
import Footer from "../../components/common/layout/Footer";
import Header from "../../components/common/layout/Header";
import { useScrollTo } from "../../hooks/useScrollTo";

const OrderBikehomeMap = () => {
  
  useScrollTo(0, 0);
  const navigate = useNavigate();
  const date = new Date();
  const estimateMonth = date.getMonth() + 1;
  const estimateYear = date.getFullYear();
  const newDate = new Date(estimateYear, estimateMonth + 3);

  const dateName = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const estimate_date =
    dateName[estimateMonth] +
    " - " +
    dateName[newDate.getMonth()] +
    " " +
    newDate.getFullYear();

  return (
    <>
      <Header />
      <div className="height_one_csrol">
        <div id="order-bikehome-map" className="top_header_pm">
          <div className="testride_map select_dealer map_img_add order_bikehomemap">
            <div className="map_left">
              <figure>
                <img  src={search_addmap} alt="imhvgb" />
              </figure>
            </div>

            <div className="testride_righsec map_right_address">
              <div className="logo_right_cont destop_show">
                <img  src={logo_right} alt="imhvgb" />
                <p>Est. Delivery Date: {estimate_date}</p>
              </div>

              <div className="only_mobileshow mobile_show">
                <img  src={popuplogomo} alt="imhvgb" />
                <h3 className="mobile_show">Give Us Some Details</h3>
              </div>

              <h3>Enter your address </h3>

              <form>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="House/Flat/Block no."
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Landmark(Optional)"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Alternate Contact Number (optional)"
                  />
                </div>
                <div className="form-group next_btn">
                  <button
                    onClick={() => navigate("/ordercityconfirmation")}
                    className="default_btn destop_show"
                  >
                    Next
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};
export default OrderBikehomeMap;
