import React from "react";
import Slider from "react-slick";
import Exclusion from "../../../assets/images/Exclusion-7.png";
import scooter5 from "../../../assets/images/LEOBOLDBLACK.png";
import scooter1 from "../../../assets/images/LEOPEARLBLUE.png";
import scooter2 from "../../../assets/images/LEOREDGLOSS.png";
import scooter3 from "../../../assets/images/LEOSILVERSHINE.png";
import scooter4 from "../../../assets/images/LEOWHITE.png";

export default function Productslider() {
  const settings = {
    dots: true,
    slidesToShow: 1,
    autoplay: false,
    speed: 500,
    centerMode: true,
    centerPadding: '26%',
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,

        }
      },
    ]
  };
  return (
    <div className="product_slider for_leo">

      <div className="productSlideBackImage">
        <img  src={Exclusion} />
      </div>
      <Slider {...settings}>

        <div className="product_slider_tem">
          <a href="#">
            <p className="productSlideItem--title section-title">PEARL BLUE</p>
            <figure>
              {" "}
              <img  src={scooter1} alt="imhvgb" />
            </figure>
          </a>
        </div>

        <div className="product_slider_tem">
          <a href="#">
            <p className="productSlideItem--title section-title">RED GLOSS</p>
            <figure>
              {" "}
              <img  src={scooter2} alt="imhvgb" />
            </figure>
          </a>
        </div>

        <div className="product_slider_tem">
          <a href="#">
            <p className="productSlideItem--title section-title">SILVER SHINE</p>
            <figure>
              {" "}
              <img  src={scooter3} alt="imhvgb" />
            </figure>
          </a>
        </div>

        <div className="product_slider_tem">
          <a href="#">
            <p className="productSlideItem--title section-title">WHITE</p>
            <figure>
              {" "}
              <img  src={scooter4} alt="imhvgb" />
            </figure>
          </a>
        </div>
        <div className="product_slider_tem">
          <a href="#">
            <p className="productSlideItem--title section-title">BOLD BLACK</p>
            <figure>
              {" "}
              <img  src={scooter5} alt="imhvgb" />
            </figure>
          </a>
        </div>

      </Slider>
    </div>
  );
}
