import React, { useState, useEffect, useRef } from "react";
import "../Testride/test-ride.css";
import { useNavigate, Link, useLocation } from "react-router-dom";
import PureModal from "react-pure-modal";
import "react-pure-modal/dist/react-pure-modal.min.css";
import lan_sliderbg from "../../assets/images/lan_sliderbg.webp";
import lan_logo from "../../assets/images/HopLogo.svg";
import india_mo from "../../assets/images/india.png";
import user_n from "../../assets/images/user.png";
import email_id from "../../assets/images/email.png";
import popuplogomo from "../../assets/images/mo_popuplogo.png";
import "../Testride/test-ride.css";
import {
  bookNowModal,
  AddTestRideToken,
  addUserDetails,
  updateUserDetails,
} from "../../actions";
import { useSelector, useDispatch } from "react-redux";
import mobile_imgl from "../../assets/images/mobile_imgla.webp";
import { Config } from "../../utils/apiUrl";
import { EX_SHOWROOM_PRICE_OXO } from "../../constants";
import { useScrollTo } from "../../hooks/useScrollTo";
import { getCookie } from "../../utils";
import ReCAPTCHAModal from "../../components/common/ReCaptchaModal";
import { verifyCaptchaAPI } from "../../APIs";
import { toast } from "react-toastify";

const TestRide = () => {
  useScrollTo(0, 0);
  const navigate = useNavigate();
  const captchaRef = useRef();

  const UserDetails = useSelector((state) => state.UserDetails);
  const dispatch = useDispatch();
  const [testRideToken, setTestRideToken] = useState(null);
  const [lasttwo, setLastTwo] = useState(null);
  const [TestRideModal, setModalTestRide] = useState(false);
  const [mobNmber, setMobNmber] = useState("");
  const [rideOtpModal, setRideOtpModal] = useState(false);
  const [userDetail, setUserDetail] = useState(false);
  const [user, setUser] = useState(null);
  const [mail, setMail] = useState(null);
  const [newuser, setNewUser] = useState(false);
  const [emailReadOnly, setReadOnLyEmail] = useState(false);
  const [wrongOtp, setWrongOtp] = useState(false);
  const [confirmOtp, setConfirmOtp] = useState(null);
  const [pincode, setPincode] = useState("");
  const [number, setMobilenumber] = useState("");
  const [pinreadonly, setPinreadyonly] = useState(false);
  const [namereadonly, setNamereadonly] = useState(false);
  const [numberreadonly, setnumberReadonly] = useState(false);
  const [nameerror, setNameerror] = useState(false);
  const [pincodeerror, setPincodeerror] = useState(false);
  const [numbererror, setNumbererror] = useState(false);
  const [nameFilederror, setNamefilederror] = useState(false);
  const [numbererror1, setNumbererror1] = useState(false);
  const [pincodeformaterror, setPincodeformaterror] = useState(false);
  const [userid, setUserid] = useState(null);
  const [otpnumbervalidation, setOtpnumbervalidation] = useState(false);
  const [otperror, setOtperror] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [resend, setresend] = useState(false);
  const [changecolor, setColor] = useState(false);
  const [resent, setrenset] = useState("");
  const location = useLocation();
  const location2 = location.pathname.split("/")[1];
  const [myLat, setMyLat] = useState();
  const [myLong, setMyLong] = useState();

  function getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    }
  }

  function showPosition(position) {
    setMyLat(position.coords.latitude);
    setMyLong(position.coords.longitude);
  }

  useEffect(() => {
    getLocation();
  }, []);

  useEffect(() => {
    if (location2) {
      if (location2 == "otp") {
        setModalTestRide(true);
      }
    }
  });

  const handleuserChange = (event) => {
    setUser(event.target.value);
  };

  const handlemailChange = (event) => {
    setMail(event.target.value);
  };

  const handlesearchChange = (event) => {
    if (event.target.value.length < 7) {
      setPincode(event.target.value);
    }
  };

  const handlenumber = (event) => {
    if (event.target.value.length < 11) {
      setMobilenumber(event.target.value);
    }
  };

  const mondalInt = setInterval(() => {
    if (UserDetails.orderModal === true) {
      dispatch(bookNowModal({ toggle: true }));
      clearInterval(mondalInt);
    }
  }, 700);

  const mondalInts = setInterval(() => {
    if (UserDetails.orderModal === false) {
      dispatch(bookNowModal({ toggle: false }));
      clearInterval(mondalInts);
    }
  }, 700);

  const handleMobNumberChange = (event) => {
    var nLength = event.target.value.length;
    if (11 <= nLength) {
    } else {
      setMobNmber(event.target.value);
    }
  };

  const requestOptionsForOtpVarification = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ phone_num: `${mobNmber}`, otp: `${confirmOtp}` }),
  };

  const getdealerdata = async () => {
    const requestOptions1 = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + JSON.parse(getCookie("userdata")).user_token,
      },
      body: JSON.stringify({
        pincode: JSON.parse(localStorage.getItem("pincode")),
        user_id: JSON.parse(getCookie("userdata")).user_id,
        lat: myLat,
        lng: myLong,
      }),
    };
    try {
      const responseDealer = await fetch(Config.pincode, requestOptions1);
      if (responseDealer) {
        const jsonDealer = await responseDealer.json();
        return jsonDealer.testdrive_ticket_id;
      }
    } catch (error) {
      console.log(error);
    }
  };

  async function confirmOTP(expiredata) {
    try {
      const response = await fetch(
        Config.otp_varification,
        requestOptionsForOtpVarification
      );
      const responseJson = await response.json();
      if (responseJson) {
        if (responseJson.status) {
          if (responseJson.data.authenticated_user === "True") {
            setMobilenumber(responseJson.data.phone_num);
            setTestRideToken(responseJson.data.token);
            if (responseJson.data !== undefined) {
              setUser(responseJson.data.name);
              setMail(responseJson.data.email);
              setPincode(responseJson.data.pin_code);
              dispatch(
                AddTestRideToken({ testRideToken: responseJson.data.token })
              );
              setNewUser(true);
              setReadOnLyEmail(true);
              setPinreadyonly(true);
              setNamereadonly(true);
              setNamefilederror(false);
              setNameerror(false);
              setNumbererror(false);
              setNumbererror1(false);
              setPincodeerror(false);
              setUserDetail(false);
              setPincodeformaterror(false);
              localStorage.removeItem("servicedata");
              localStorage.removeItem("userbooknowdata");
              localStorage.removeItem("dealerdata");
              const cookieData = getCookie(expiredata);
              if (!cookieData) {
                let userdetail = {
                  user_name: responseJson.data.name,
                  user_number: responseJson.data.phone_num,
                  user_email: responseJson.data.email,
                  user_token: responseJson.data.token,
                  user_id: userid,
                  user_pincode: responseJson.data.pin_code,
                };
                let date = new Date();
                date.setTime(date.getTime() + 24 * 60 * 60 * 1000);
                const expires = "expires=" + date.toUTCString();
                document.cookie =
                  "userdata=" +
                  JSON.stringify(userdetail) +
                  "; " +
                  expires +
                  "; path=/";
                localStorage.setItem(
                  "pincode",
                  JSON.stringify(responseJson.data.pin_code)
                );
              }
              var activeTicketId = await getdealerdata();
              if (
                activeTicketId == "" ||
                activeTicketId === null ||
                activeTicketId === undefined
              ) {
                navigate("/booktestride");
              } else {
                navigate("/userDashboard");
              }
            }
            setUserDetail(true);
            setRideOtpModal(false);
            setWrongOtp(false);
            setOtpVerified(true);
            setnumberReadonly(true);
          } else {
            setOtpVerified(true);
            setWrongOtp(true);
            setConfirmOtp("");
            setresend(false);
            setOtperror(false);
            return false;
          }

          if (response.status === 200) {
            setMobNmber("");
            setConfirmOtp("");
          }
        } else {
          toast.error(responseJson.message, { autoClose: 3500 });
        }
      }
    } catch (error) {
      toast.error(error.message, { autoClose: 3500 });
    }
  }

  const sendOtp = () => {
    if (
      mobNmber === "" ||
      mobNmber == null ||
      mobNmber === " " ||
      mobNmber.length > 12
    ) {
      setOtpnumbervalidation(false);
      return false;
    }
    if (mobNmber.length < 10) {
      setOtpnumbervalidation(true);
      return false;
    } else {
      setOtpnumbervalidation(false);
      fetchData();
    }
  };

  const checkConfirmOtp = (e) => {
    e.preventDefault();
    let expiredata = "userdata";
    if (!confirmOtp) {
      setOtperror(true);
      setresend(false);
      setOtpVerified(false);
    } else {
      confirmOTP(expiredata);
    }
  };

  async function fetchData() {
    try {
      const g_token = await captchaRef.current.executeAsync();
      if (g_token) {
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            phone_num: `${mobNmber}`,
            otp: "",
            g_response: g_token,
          }),
        };
        const response = await fetch(Config.otp_varification, requestOptions);
        const responseJson = await response.json();
        if (responseJson) {
          if (responseJson.status) {
            let last = mobNmber[mobNmber.length - 1];
            let lastsecond = mobNmber[mobNmber.length - 2];
            setLastTwo(lastsecond + "" + last);
            setRideOtpModal(true);
            setModalTestRide(false);
            otpmsgs(60000);
            setUserid(responseJson.data.user_id);
            dispatch(
              addUserDetails({
                userId: `${responseJson.data.user_id}`,
                messageStatus: true,
                phoneNum: `${responseJson.data.phone_num}`,
              })
            );
          } else {
            toast.error(responseJson.message, { autoClose: 3500 });
          }
        }
      }
    } catch (error) {
      toast.error(error.message, { autoClose: 3500 });
    } finally {
      captchaRef.current.reset();
    }
  }

  const sendotp1 = () => {
    setColor(true);
    if (
      mobNmber === "" ||
      mobNmber == null ||
      mobNmber === " " ||
      mobNmber.length > 12
    ) {
      setOtpnumbervalidation(false);
      return false;
    }
    if (mobNmber.length < 10) {
      setOtpnumbervalidation(true);
      return false;
    } else {
      setOtpnumbervalidation(false);
      fetchData1();
    }
  };

  async function fetchData1() {
    try {
      const g_token = await captchaRef.current.executeAsync();
      if (g_token) {
        const requestOptions1 = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            phone_num: `${mobNmber}`,
            otp: "",
            g_response: g_token,
          }),
        };
        const response = await fetch(Config.otp_varification, requestOptions1);
        const responseJson = await response.json();
        if (responseJson) {
          if (responseJson.status) {
            let last = mobNmber[mobNmber.length - 1];
            let lastsecond = mobNmber[mobNmber.length - 2];
            setLastTwo(lastsecond + "" + last);
            setRideOtpModal(true);
            setModalTestRide(false);
            setresend(true);
            setColor(false);
            setOtperror(false);
            setOtpVerified(false);
            otpmsgs(30000);
            setUserid(responseJson.data.user_id);
            dispatch(
              addUserDetails({
                userId: `${responseJson.data.user_id}`,
                messageStatus: true,
                phoneNum: `${responseJson.data.phone_num}`,
              })
            );
          } else {
            toast.error(responseJson.message, { autoClose: 3500 });
          }
        }
      }
    } catch (error) {
      toast.error(error.message, { autoClose: 3500 });
    } finally {
      captchaRef.current.reset();
    }
  }

  const expiretoken = (expiredata) => {
    var i,
      x,
      y,
      ARRcookies = document.cookie.split(";");
    var re = false;
    for (i = 0; i < ARRcookies.length; i++) {
      x = ARRcookies[i].substr(0, ARRcookies[i].indexOf("="));
      y = ARRcookies[i].substr(ARRcookies[i].indexOf("=") + 1);
      x = x.replace(/^\s+|\s+$/g, "");
      if (x == expiredata) {
        re = true;
        setModalTestRide(false);
        navigate("/booktestride");
        return unescape(y);
      }
      if (!re) {
        setModalTestRide(true);
      }
    }
  };

  const submitUserDetailTest = async () => {
    if (newuser === false) {
      var regex =
        /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
      var regName = /^[a-zA-Z ]{2,30}$/;
      var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
      var pin = "^[1-9]{1}[0-9]{2}\\s{0,1}[0-9]{3}$";
      if (user === "" || user === " " || user == null) {
        setNamefilederror(true);
        setNameerror(false);
        return false;
      } else {
        setNamefilederror(false);
      }
      if (!user.match(regName)) {
        setNameerror(true);
        setNamefilederror(false);
        return false;
      } else {
        setNameerror(false);
      }
      if (number === " " || number === "" || number == null) {
        setNumbererror(true);
        setNumbererror1(false);
        return false;
      } else {
        setNumbererror(false);
      }
      if (number.length < 10 || number.length > 10 || !number.match(phoneno)) {
        setNumbererror1(true);
        setNumbererror(false);
        return false;
      } else {
        setNumbererror1(false);
      }
      if (pincode === " " || pincode === "" || pincode == null) {
        setPincodeerror(true);
        setPincodeformaterror(false);
        return false;
      } else {
        setPincodeerror(false);
      }
      if (pincode.length > 6 || pincode.length < 6 || !pincode.match(pin)) {
        setPincodeformaterror(true);
        setPincodeerror(false);
        return false;
      } else {
        setPincodeformaterror(false);
      }
    }

    searchDealerByPincode("userdata");
    dispatch(updateUserDetails({ userName: user, userEmail: mail }));
    setUserDetail(false);
  };

  const searchDealerByPincode = (expiredata) => {
    if (newuser == false) {
      const cookieData = getCookie(expiredata);
      if (!cookieData) {
        let userdetail = {
          user_name: user,
          user_number: number,
          user_email: mail,
          user_token: testRideToken,
          user_id: userid,
          user_pincode: pincode,
        };
        let date = new Date();
        date.setTime(date.getTime() + 24 * 60 * 60 * 1000);
        const expires = "expires=" + date.toUTCString();
        document.cookie =
          "userdata=" +
          JSON.stringify(userdetail) +
          "; " +
          expires +
          "; path=/";
        localStorage.setItem("pincode", JSON.stringify(pincode));
      }
      navigate("/booktestride");
      localStorage.removeItem("servicedata");
      localStorage.removeItem("userbooknowdata");
      localStorage.removeItem("dealerdata");
    }
  };

  const handleConfirmOTPChange = (event) => {
    let otp = event.target.value.length;
    if (otp < 7) {
      setConfirmOtp(event.target.value);
    }
  };

  const scrollFun = () => {
    const section = document.querySelector("#price_sec");
    section.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const onKeyPressNumberWanto = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9]").test(keyValue)) event.preventDefault();
    return true;
  };

  const onKeyPressOtpWanto = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9]").test(keyValue)) event.preventDefault();
    return true;
  };

  const otpmsgs = function (interval) {
    clearTimeout(resent);
    var a = setTimeout(() => {
      setOtperror(true);
    }, interval);
    setrenset(a);
  };

  const redirect = () => {
    // navigate("/");
    setMobNmber("");
    setModalTestRide(false);
    setOtpnumbervalidation(false);
    setOtperror(false);
    setOtpVerified(false);
    setresend(false);
    setColor(false);
  };

  const redirect1 = () => {
    // navigate("/");
    setMobNmber("");
    setRideOtpModal(false);
    setModalTestRide(false);
    setOtpnumbervalidation(false);
    setOtperror(false);
    setOtpVerified(false);
    setresend(false);
    setColor(false);
  };

  const redirect3 = () => {
    // navigate("/");
    setMobNmber("");
    setUserDetail(false);
    setRideOtpModal(false);
    setModalTestRide(false);
    setOtpnumbervalidation(false);
    setOtperror(false);
    setOtpVerified(false);
    setresend(false);
    setColor(false);
  };

  return (
    <>
      <div id="test-ride">
        <div className="landing_banner">
          <div className="video_sec_step">
            <img className="slide_des" src={lan_sliderbg} alt="imhvgb" />
            <img className="slide_mobile" src={mobile_imgl} alt="imhvgb" />
          </div>
          <div className="width_sec landing_page_container">
            <div className="steps" id="usingForHeader">
              <ul className="uk-flex uk-flex-center">
                <li>
                  <span>
                    95 <p>Kmph</p>
                  </span>
                  <p>Top Speed</p>
                </li>
                <li>
                  <span>
                    150 <p>Km</p>
                  </span>
                  <p>Range</p>
                </li>

                <li>
                  <span>
                    25 <p>paise</p>
                  </span>
                  <p>Cost/km</p>
                </li>
              </ul>
            </div>
            <div className="landig_banner_cont">
              <div className="hop_oxo">
                <img src={lan_logo} alt="imhvgb" />
                <strong>OXO</strong>
                <div className="rs_sec">
                  <p>Ex-showroom starts at</p>
                  <strong>
                    ₹ {Number(EX_SHOWROOM_PRICE_OXO).toLocaleString("en-IN")}*
                  </strong>
                </div>
              </div>
              <div className="btn_banner">
                <button className="default_btn" onClick={scrollFun}>
                  Book now @ ₹999
                </button>
                <button
                  className="default_btn Book drstop_btn"
                  onClick={() => expiretoken("userdata")}
                >
                  Book a test drive
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="full_video_bike">
          <div className="video_sec_step">
            <div className="vimeo-video">
              <iframe
                src="https://player.vimeo.com/video/744990698?background=1&muted=1&autoplay=1&loop=1"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                mozallowfullscreen="true"
                allowFullScreen
                webkitallowfullscreen="true"
                title="HOP-TEASER_WITH-WEBSITE.c6ba3089bce3c0b610fc.mp4"
              ></iframe>
            </div>
          </div>
          <div className="width_sec landing_page_container">
            <div className="landig_banner_cont">
              <div className="hop_oxo">
                <img src={lan_logo} alt="imhvgb" />
                <strong>OXO</strong>
                <div className="rs_sec">
                  <p>Ex-showroom starts at</p>
                  <strong>
                    ₹ {Number(EX_SHOWROOM_PRICE_OXO).toLocaleString("en-IN")}*
                  </strong>
                </div>
              </div>
              <div className="btn_banner">
                <button className="default_btn" onClick={scrollFun}>
                  Book now @ ₹999
                </button>
                <button
                  className="default_btn Book drstop_btn"
                  onClick={() => expiretoken("userdata")}
                >
                  Book a test drive
                </button>
              </div>
            </div>
          </div>
        </div>
        <ReCAPTCHAModal
          recaptchaRef={captchaRef}
          // onClose={onClose}
          // showCaptcha={showCaptchaModal}
          // onExecute={onExecute}
          on={"logincaptcha"}
        />
        <div
          className="form-group modal_popup test_ride_popup form-group modal_popup login_popup_detail"
          id="enter_mobile_no_desktop"
        >
          <PureModal
            isOpen={TestRideModal}
            closeButton={<div className="close_btn" onClick={redirect}></div>}
            closeButtonPosition="top"
            width="100%"
          >
            {" "}
            {/* {showCaptchaModal ? ( */}
            {/* ) : ( */}
            <div className="popup_cont color_change give_detail">
              <div className="mobile_showlogo">
                <img src={popuplogomo} alt="imhvgb" />
              </div>
              <h2>Please Enter Your Phone Number</h2>
              <form onSubmit={(e) => e.preventDefault()}>
                <div
                  className="react_form_group india_f indiaf_phone"
                  id="mobileno_testride"
                >
                  <label>
                    {otpnumbervalidation === true ? (
                      <span className="error pl-4">
                        Please enter 10 digit mobile number*
                      </span>
                    ) : (
                      ""
                    )}{" "}
                  </label>
                  <div className="mo_numb">
                    <input
                      required
                      type="text"
                      value={mobNmber}
                      onChange={handleMobNumberChange}
                      placeholder="Mobile Number"
                      onKeyPress={onKeyPressNumberWanto}
                    />
                    <div className="in_img">
                      <img className="slide_des" src={india_mo} alt="imhvgb" />
                      <span>+91</span>
                    </div>
                  </div>
                </div>
                <div className="react_form_group next_popu">
                  <button onClick={sendOtp} className="default_btn">
                    Continue
                  </button>
                </div>
              </form>
            </div>
            {/* )} */}
          </PureModal>
        </div>

        <div
          className="form-group modal_popup test_ride_popup login_popup_detail login_popup_detail"
          id="enterotptestride"
        >
          <PureModal
            isOpen={rideOtpModal}
            closeButton={<div className="close_btn" onClick={redirect1}></div>}
            closeButtonPosition="top"
            width="100%"
          >
            <div className="popup_cont color_change mo_optpopup">
              <div className="mobile_showlogo">
                <img src={popuplogomo} alt="imhvgb" />
                <h2>Give Us Some Details</h2>
              </div>

              <h2 className="otp_send">Enter the OTP sent to</h2>
              <h2 className="otp_no">+91********{lasttwo}</h2>
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="react_form_group">
                  <label>
                    <span
                      style={{
                        color:
                          wrongOtp === true ||
                          otperror === true ||
                          resend === true
                            ? "red"
                            : "",
                      }}
                    >
                      {!otperror && !otpVerified && !resend
                        ? ""
                        : otperror && !otpVerified
                        ? "Didn’t get the OTP?"
                        : otpVerified
                        ? "Incorrect or Invalid OTP."
                        : resend
                        ? "OTP Successfully Resent."
                        : ""}{" "}
                    </span>
                    {otperror || otpVerified ? (
                      <span
                        style={{
                          color: changecolor ? "lightgreen" : "#0000EE",
                          cursor: "pointer",
                        }}
                        onClick={sendotp1}
                      >
                        Resend?
                      </span>
                    ) : (
                      ""
                    )}{" "}
                  </label>
                  <input
                    required
                    type="text"
                    value={confirmOtp}
                    onChange={handleConfirmOTPChange}
                    placeholder="X X X X X X"
                    maxLength={6}
                    onKeyPress={onKeyPressOtpWanto}
                  />
                </div>
                <div className="react_form_group next_popu">
                  <button
                    onClick={(e) => checkConfirmOtp(e)}
                    className="default_btn"
                  >
                    Continue
                  </button>
                </div>
              </form>
            </div>
          </PureModal>
        </div>

        <div
          className="form-group modal_popup test_ride_popup login_popup_detail"
          id="userDetailDesktop"
        >
          <PureModal
            isOpen={userDetail}
            closeButton={<div className="close_btn" onClick={redirect3}></div>}
            closeButtonPosition="top"
            width="100%"
          >
            <div className="popup_cont color_change give_detail">
              <div className="mobile_showlogo">
                <img src={popuplogomo} alt="imhvgb" />
              </div>

              <h2>Give us Some Details</h2>
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="react_form_group gi_nameinp">
                  <div className="mo_numb">
                    <input
                      required
                      type="text"
                      value={user}
                      onChange={handleuserChange}
                      placeholder="Name"
                      readOnly={namereadonly}
                    />
                    <div className="in_img">
                      <img className="slide_des" src={user_n} alt="imhvgb" />
                    </div>
                  </div>
                  {nameerror === true ? (
                    <span className="error pl-4">Invalid Name*</span>
                  ) : null}
                  {nameFilederror === true ? (
                    <span className="error pl-4">Please Fill Name Field*</span>
                  ) : null}
                </div>

                <div className="react_form_group india_f indiaf_phone">
                  <div className="mo_numb">
                    <input
                      required
                      type="text"
                      value={number}
                      onChange={handlenumber}
                      placeholder="Mobile Number"
                      readOnly={numberreadonly}
                      maxLength="10"
                      onKeyPress={onKeyPressNumberWanto}
                    />
                    <div className="in_img">
                      <img className="slide_des" src={india_mo} alt="imhvgb" />
                      <span>+91</span>
                    </div>
                    {numbererror === true ? (
                      <span className="error pl-4">
                        Please Fill Phone Number Field*
                      </span>
                    ) : null}
                    {numbererror1 === true ? (
                      <span className="error pl-4">Invalid Phone Number*</span>
                    ) : null}
                  </div>
                </div>

                <div className="react_form_group">
                  <div className="mo_numb">
                    <input
                      type="email"
                      value={mail}
                      onChange={handlemailChange}
                      placeholder="Email id"
                      readOnly={emailReadOnly}
                    />
                    <div className="in_img">
                      <img className="slide_des" src={email_id} alt="imhvgb" />
                    </div>
                  </div>
                </div>

                <div className="react_form_group">
                  <div className="mo_numb">
                    <input
                      required
                      type="text"
                      value={pincode}
                      maxLength="6"
                      onChange={handlesearchChange}
                      placeholder="Pin Code"
                      readOnly={pinreadonly}
                      onKeyPress={onKeyPressOtpWanto}
                    />
                    {pincodeerror === true ? (
                      <span className="error pl-4">
                        Please Fill Pincode Field*
                      </span>
                    ) : null}
                    {pincodeformaterror === true ? (
                      <span className="error pl-4">Invalid Pin Code</span>
                    ) : null}
                  </div>
                </div>

                <div className="react_form_group next_popu">
                  <button
                    onClick={submitUserDetailTest}
                    className="default_btn"
                  >
                    Continue
                  </button>
                </div>

                <div className="react_form_group by_continuing">
                  <p>
                    {" "}
                    By continuing, you agree to
                    <Link to="/Tc" className="privacy_po">
                      {" "}
                      T&C{" "}
                    </Link>
                    &
                    <Link to="/privacypolicy" className="privacy_po">
                      {" "}
                      Privacy Policy
                    </Link>
                  </p>
                </div>
              </form>
            </div>
          </PureModal>
        </div>
      </div>
    </>
  );
};
export default TestRide;
