import React, { useState, useEffect, useRef } from "react";
import {
  useNavigate,
  Link,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import PureModal from "react-pure-modal";

import "react-pure-modal/dist/react-pure-modal.min.css";
import "../../../../pages/Testride/test-ride.css";

import india_mo from "../../../../assets/images/india.png";
import user_n from "../../../../assets/images/user.png";
import email_id from "../../../../assets/images/email.png";
import popuplogomo from "../../../../assets/images/mo_popuplogo.png";
import {
  bookNowModal,
  AddTestRideToken,
  addUserDetails,
  updateUserDetails,
} from "../../../../actions";

import Header from "../Header";

import { Config } from "../../../../utils/apiUrl";
import { getCookie } from "../../../../utils";
import { useScrollTo } from "../../../../hooks/useScrollTo";
import ReCAPTCHAModal from "../../ReCaptchaModal";
import { verifyCaptchaAPI } from "../../../../APIs";
import { toast } from "react-toastify";

const Login = () => {
  useScrollTo(0, 0);
  const navigate = useNavigate();
  const captchaRef = useRef();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const UserDetails = useSelector((state) => state.UserDetails);
  const [testRideToken, setTestRideToken] = useState(null);
  const [lasttwo, setLastTwo] = useState(null);
  const [TestRideModal, setModalTestRide] = useState(true);
  const [mobNmber, setMobNmber] = useState("");
  const [, /*display*/ setDisplay] = useState(true);
  const [rideOtpModal, setRideOtpModal] = useState(false);
  const [userDetail, setUserDetail] = useState(false);
  const [user, setUser] = useState(null);
  const [mail, setMail] = useState(null);
  const [newuser, setNewUser] = useState(false);
  const [emailReadOnly, setReadOnLyEmail] = useState(false);
  const [wrongOtp, setWrongOtp] = useState(false);
  const [, /*modalOrder1*/ setModalOrder1] = useState(false);
  const [, /*otpMessageStatus*/ setOtpMessageStatus] = useState(false);
  const [confirmOtp, setConfirmOtp] = useState(null);
  const [pincode, setPincode] = useState("");
  const [number, setMobilenumber] = useState("");
  const [pinreadonly, setPinreadyonly] = useState(false);
  const [namereadonly, setNamereadonly] = useState(false);
  const [numberreadonly, setnumberReadonly] = useState(false);
  const [nameerror, setNameerror] = useState(false);
  const [pincodeerror, setPincodeerror] = useState(false);
  const [numbererror, setNumbererror] = useState(false);
  const [nameFilederror, setNamefilederror] = useState(false);
  const [numbererror1, setNumbererror1] = useState(false);
  const [pincodeformaterror, setPincodeformaterror] = useState(false);
  const [userid, setUserid] = useState(null);
  const [otpnumbervalidation, setOtpnumbervalidation] = useState(false);
  const [otperror, setOtperror] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [resend, setresend] = useState(false);
  const [changecolor, setColor] = useState(false);
  const [resent, setrenset] = useState("");

  // const [showCaptchaModal, setShowCaptchaModal] = useState(false);

  const location = useLocation();
  const handleuserChange = (event) => {
    setUser(event.target.value);
  };
  const handlemailChange = (event) => {
    setMail(event.target.value);
  };

  const handlesearchChange = (event) => {
    let pincode = event.target.value.length;
    if (pincode < 7) {
      setPincode(event.target.value);
    }
  };

  const handlenumber = (event) => {
    let mobile = event.target.value.length;
    if (mobile < 11) {
      setMobilenumber(event.target.value);
    }
  };

  const mondalInt = setInterval(() => {
    if (UserDetails.orderModal === true) {
      setModalOrder1(true);
      dispatch(bookNowModal({ toggle: true }));
      clearInterval(mondalInt);
    }
  }, 700);

  const mondalInts = setInterval(() => {
    if (UserDetails.orderModal === false) {
      setModalOrder1(false);
      dispatch(bookNowModal({ toggle: false }));
      clearInterval(mondalInts);
    }
  }, 700);

  const handleMobNumberChange = (event) => {
    var nLength = event.target.value.length;
    if (11 <= nLength) {
      setDisplay(true);
    } else {
      setDisplay(true);
      setMobNmber(event.target.value);
    }
  };

  const requestOptionsForOtpVarification = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ phone_num: `${mobNmber}`, otp: `${confirmOtp}` }),
  };

  async function confirmOTP(expiredata) {
    try {
      const response = await fetch(
        Config.otp_varification,
        requestOptionsForOtpVarification
      );
      const responseJson = await response.json();
      if (responseJson) {
        if (responseJson.status) {
          if (responseJson.data.authenticated_user === "True") {
            setMobilenumber(responseJson.data.phone_num);
            setTestRideToken(responseJson.data.token);
            if (responseJson.data !== undefined) {
              setUser(responseJson.data.name);
              setMail(responseJson.data.email);
              setPincode(responseJson.data.pin_code);
              dispatch(
                AddTestRideToken({ testRideToken: responseJson.data.token })
              );
              setNewUser(true);
              setReadOnLyEmail(true);
              setPinreadyonly(true);
              setNamereadonly(true);
              setNamefilederror(false);
              setNameerror(false);
              setNumbererror(false);
              setNumbererror1(false);
              setPincodeerror(false);
              setUserDetail(false);
              setPincodeformaterror(false);
              localStorage.removeItem("servicedata");
              localStorage.removeItem("userbooknowdata");
              localStorage.removeItem("dealerdata");
              const cookieData = getCookie(expiredata);
              if (!cookieData) {
                let userdetail = {
                  user_name: responseJson.data.name,
                  user_number: responseJson.phone_num,
                  user_email: responseJson.data.email,
                  user_token: responseJson.data.token,
                  user_id: userid,
                  user_pincode: responseJson.data.pin_code,
                };
                let date = new Date();
                date.setTime(date.getTime() + 24 * 60 * 60 * 1000);
                const expires = "expires=" + date.toUTCString();
                document.cookie =
                  "userdata=" +
                  JSON.stringify(userdetail) +
                  "; " +
                  expires +
                  "; path=/";
                localStorage.setItem(
                  "pincode",
                  JSON.stringify(responseJson.data.pin_code)
                );
              }
              navigate(
                searchParams.get("to")
                  ? "/" + searchParams.get("to")
                  : "/userDashboard"
              );
            }
            setUserDetail(true);
            setRideOtpModal(false);
            setOtpMessageStatus(true);
            setWrongOtp(false);
            setOtpVerified(true);
            setresend(false);
            setnumberReadonly(true);
          } else {
            setOtpVerified(true);
            setWrongOtp(true);
            setresend(false);
            setOtperror(false);
            setConfirmOtp("");
            clearTimeout(resent);
            return false;
          }

          if (response.status === 200) {
            setMobNmber("");
            setConfirmOtp("");
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  const sendOtp = () => {
    if (
      mobNmber === "" ||
      mobNmber == null ||
      mobNmber === " " ||
      mobNmber.length > 12
    ) {
      setOtpnumbervalidation(false);
      return false;
    }

    if (mobNmber.length < 10) {
      setOtpnumbervalidation(true);
      return false;
    } else {
      setOtpnumbervalidation(false);
      fetchData();
    }
  };

  const checkConfirmOtp = (expiredata) => {
    if (!confirmOtp) {
      setOtperror(true);
      setOtpVerified(false);
      setresend(false);
    } else {
      confirmOTP(expiredata);
    }
  };

  async function fetchData() {
    try {
      const token = await captchaRef.current.executeAsync();

      if (token) {
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            phone_num: `${mobNmber}`,
            otp: "",
            g_response: token,
          }),
        };
        const response = await fetch(Config.otp_varification, requestOptions);
        const responseJson = await response.json();
        if (responseJson) {
          if (responseJson.status) {
            let last = mobNmber[mobNmber.length - 1];
            let lastsecond = mobNmber[mobNmber.length - 2];
            setLastTwo(lastsecond + "" + last);
            setOtpnumbervalidation(false);
            setRideOtpModal(true);
            otpmsgs(60000);
            setUserid(responseJson.data.user_id);
            setOtpMessageStatus(true);
            dispatch(
              addUserDetails({
                userId: `${responseJson.data.user_id}`,
                messageStatus: true,
                phoneNum: `${responseJson.data.phone_num}`,
              })
            );
          } else {
            toast.error(responseJson.message, { autoClose: 3500 });
          }
        }
      }
    } catch (error) {
      toast.error(error.message, { autoClose: 3500 });
    } finally {
      captchaRef.current.reset();
    }
  }

  const sendotp1 = () => {
    setColor(true);
    if (
      mobNmber === "" ||
      mobNmber == null ||
      mobNmber === " " ||
      mobNmber.length > 12
    ) {
      setOtpnumbervalidation(false);
      return false;
    }
    if (mobNmber.length < 10) {
      setOtpnumbervalidation(true);
      return false;
    } else {
      setOtpnumbervalidation(false);
      fetchData1();
    }
  };

  async function fetchData1() {
    try {
      const token = await captchaRef.current.executeAsync();
      if (token) {
        const requestOptions1 = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            phone_num: `${mobNmber}`,
            otp: "",
            g_response: token,
          }),
        };
        const response = await fetch(Config.otp_varification, requestOptions1);
        const responseJson = await response.json();
        if (responseJson) {
          if (responseJson.status) {
            let last = mobNmber[mobNmber.length - 1];
            let lastsecond = mobNmber[mobNmber.length - 2];
            setLastTwo(lastsecond + "" + last);
            setRideOtpModal(true);
            setModalTestRide(false);
            setresend(true);
            setColor(false);
            setOtperror(false);
            otpmsgs(30000);
            setOtpVerified(false);
            setUserid(responseJson.data.user_id);
            setOtpMessageStatus(true);
            dispatch(
              addUserDetails({
                userId: `${responseJson.data.user_id}`,
                messageStatus: true,
                phoneNum: `${responseJson.data.phone_num}`,
              })
            );
          } else {
            toast.error(responseJson.message, { autoClose: 3500 });
          }
        }
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      captchaRef.current.reset();
    }
  }

  const submitUserDetailTest = async () => {
    if (newuser === false) {
      var regex =
        /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
      var regName = /^[a-zA-Z ]{2,30}$/;
      var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
      var pin = "^[1-9]{1}[0-9]{2}\\s{0,1}[0-9]{3}$";
      if (user === "" || user === " " || user == null) {
        setNamefilederror(true);
        setNameerror(false);
        return false;
      } else {
        setNamefilederror(false);
      }
      if (!user.match(regName)) {
        setNameerror(true);
        setNamefilederror(false);
        return false;
      } else {
        setNameerror(false);
      }
      if (number === " " || number === "" || number == null) {
        setNumbererror(true);
        setNumbererror1(false);
        return false;
      } else {
        setNumbererror(false);
      }
      if (number.length < 10 || number.length > 10 || !number.match(phoneno)) {
        setNumbererror1(true);
        setNumbererror(false);
        return false;
      } else {
        setNumbererror1(false);
      }
      if (pincode === " " || pincode === "" || pincode == null) {
        setPincodeerror(true);
        setPincodeformaterror(false);
        return false;
      } else {
        setPincodeerror(false);
      }
      if (pincode.length > 6 || pincode.length < 6 || !pincode.match(pin)) {
        setPincodeformaterror(true);
        setPincodeerror(false);
        return false;
      } else {
        setPincodeformaterror(false);
      }
    }

    searchDealerByPincode("userdata");
    dispatch(updateUserDetails({ userName: user, userEmail: mail }));
    // setUserDetail(false);
  };

  const searchDealerByPincode = (expiredata) => {
    if (newuser == false) {
      const cookieData = getCookie(expiredata);
      if (!cookieData) {
        let userdetail = {
          user_name: user,
          user_number: number,
          user_email: mail,
          user_token: testRideToken,
          user_id: userid,
          user_pincode: pincode,
        };
        let date = new Date();
        date.setTime(date.getTime() + 24 * 60 * 60 * 1000);
        const expires = "expires=" + date.toUTCString();
        document.cookie =
          "userdata=" +
          JSON.stringify(userdetail) +
          "; " +
          expires +
          "; path=/";
        localStorage.setItem("pincode", JSON.stringify(pincode));
      }
      localStorage.removeItem("servicedata");
      localStorage.removeItem("userbooknowdata");
      localStorage.removeItem("dealerdata");
      navigate("/userDashboard");
    }
  };

  const handleConfirmOTPChange = (event) => {
    let otp = event.target.value.length;
    if (otp < 7) {
      setConfirmOtp(event.target.value);
    }
  };
  const scrollFun = () => {
    const section = document.querySelector("#price_sec");
    section.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const onKeyPressNumberWanto = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9]").test(keyValue)) event.preventDefault();
    return true;
  };

  const onKeyPressOtpWanto = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9]").test(keyValue)) event.preventDefault();
    return true;
  };

  useEffect(() => {
    const close = document.getElementsByClassName("close")[0];
    if (close !== undefined) {
      close.addEventListener("click", () => {
        navigate("/");
        setMobNmber("");
        setModalTestRide(false);
        setOtperror(false);
        setOtpVerified(false);
        setresend(false);
        setColor(false);
      });
    }
  });

  useEffect(() => {
    const close1 = document.getElementsByClassName("close")[1];
    if (close1 !== undefined) {
      close1.addEventListener("click", () => {
        navigate("/");
        setMobNmber("");
        setModalTestRide(false);
        setRideOtpModal(false);
        setOtperror(false);
        setOtpVerified(false);
        setresend(false);
        setColor(false);
      });
    }
  });

  useEffect(() => {
    const close2 = document.getElementsByClassName("close")[2];
    if (close2 !== undefined) {
      close2.addEventListener("click", () => {
        navigate("/");
        setMobNmber("");
        setModalTestRide(false);
        setRideOtpModal(false);
        setUserDetail(false);
        setOtperror(false);
        setOtpVerified(false);
        setresend(false);
        setColor(false);
      });
    }
  });

  const otpmsgs = function (interval) {
    clearTimeout(resent);
    var a = setTimeout(() => {
      setOtperror(true);
    }, interval);
    setrenset(a);
  };

  // const onExecute = async (e) => {
  //   let response = await verifyCaptchaAPI(e);
  //   if (response && response.status == "success") {
  //     setShowCaptchaModal(false);
  //     sendOtp();
  //   } else {
  //     captchaRef.current.reset();
  //   }
  // };

  // const onClose = () => {
  //   setShowCaptchaModal(false);
  // };

  return (
    <>
      <Header />
      <div id="test-ride">
        <ReCAPTCHAModal
          recaptchaRef={captchaRef}
          // onClose={onClose}
          // showCaptcha={showCaptchaModal}
          // onExecute={onExecute}
          on={"logincaptcha"}
        />
        <div
          className="form-group modal_popup test_ride_popup form-group modal_popup login_popup_detail"
          id="enter_mobile_no_desktop"
        >
          <PureModal
            isOpen={TestRideModal}
            closeButton={<div className="close_btn"></div>}
            closeButtonPosition="top"
            width="100%"
          >
            {/* {showCaptchaModal ? ( */}

            {/* ) : ( */}
            <div className="popup_cont color_change give_detail">
              <div className="mobile_showlogo">
                <img src={popuplogomo} alt="imhvgb" />
              </div>
              <h2>Please Enter Your Phone Number</h2>
              <form onSubmit={(e) => e.preventDefault()}>
                <div
                  className="react_form_group india_f indiaf_phone"
                  id="mobileno_testride"
                >
                  <label>
                    {otpnumbervalidation === true ? (
                      <span className="error pl-4">
                        Please enter 10 digit mobile number*
                      </span>
                    ) : (
                      ""
                    )}{" "}
                  </label>
                  <div className="mo_numb">
                    <input
                      required
                      type="text"
                      value={mobNmber}
                      onChange={handleMobNumberChange}
                      placeholder="Mobile Number"
                      onKeyPress={onKeyPressNumberWanto}
                    />
                    <div className="in_img">
                      <img className="slide_des" src={india_mo} alt="imhvgb" />
                      <span>+91</span>
                    </div>
                  </div>
                </div>

                <div className="react_form_group next_popu">
                  <button onClick={fetchData} className="default_btn">
                    Continue
                  </button>
                </div>
              </form>
            </div>
            {/* )} */}
          </PureModal>
        </div>

        <div
          className="form-group modal_popup test_ride_popup login_popup_detail"
          id="enterotptestride"
        >
          <PureModal
            isOpen={rideOtpModal}
            closeButton={<div className="close_btn"></div>}
            closeButtonPosition="top"
            width="100%"
          >
            <div className="popup_cont color_change mo_optpopup login_popup_detail">
              <div className="mobile_showlogo">
                <img src={popuplogomo} alt="imhvgb" />
                <h2>Give Us Some Details</h2>
              </div>

              <h2 className="otp_send">Enter the OTP sent to</h2>
              <h2 className="otp_no">+91********{lasttwo}</h2>
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="react_form_group">
                  <label>
                    <span
                      style={{
                        color:
                          wrongOtp === true ||
                          otperror === true ||
                          resend === true
                            ? "red"
                            : "",
                      }}
                    >
                      {!otperror && !otpVerified && !resend
                        ? ""
                        : otperror && !otpVerified
                        ? "Didn’t get the OTP?"
                        : otpVerified
                        ? "Incorrect or Invalid OTP."
                        : resend
                        ? "OTP Successfully Resent."
                        : ""}{" "}
                    </span>{" "}
                    {otperror || otpVerified ? (
                      <span
                        style={{
                          color: changecolor ? "lightgreen" : "#0000EE",
                          cursor: "pointer",
                        }}
                        onClick={sendotp1}
                      >
                        Resend?
                      </span>
                    ) : (
                      ""
                    )}{" "}
                  </label>
                  <input
                    type="text"
                    value={confirmOtp}
                    onChange={handleConfirmOTPChange}
                    placeholder="X X X X X X"
                    maxLength="6"
                    onKeyPress={onKeyPressOtpWanto}
                  />
                </div>
                {/* {!zipEror && <span className="error">Invalid Pin Code</span>} */}
                <div className="react_form_group next_popu">
                  <button
                    onClick={() => checkConfirmOtp("userdata")}
                    className="default_btn"
                  >
                    Continue
                  </button>
                </div>
              </form>
            </div>
          </PureModal>
        </div>

        <div
          className="form-group modal_popup test_ride_popup login_popup_detail"
          id="userDetailDesktop"
        >
          <PureModal
            isOpen={userDetail}
            closeButton={<div className="close_btn"></div>}
            closeButtonPosition="top"
            width="100%"
          >
            <div className="popup_cont color_change give_detail">
              <div className="mobile_showlogo">
                <img src={popuplogomo} alt="imhvgb" />
              </div>

              <h2>Give us Some Details</h2>
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="react_form_group gi_nameinp">
                  <div className="mo_numb">
                    <input
                      required
                      type="text"
                      value={user}
                      onChange={handleuserChange}
                      placeholder="Name"
                      readOnly={namereadonly}
                    />
                    <div className="in_img">
                      <img className="slide_des" src={user_n} alt="imhvgb" />
                    </div>
                  </div>
                  {nameerror === true ? (
                    <span className="error pl-4">Invalid Name*</span>
                  ) : null}
                  {nameFilederror === true ? (
                    <span className="error pl-4">Please Fill Name Field*</span>
                  ) : null}
                </div>

                <div className="react_form_group india_f indiaf_phone">
                  <div className="mo_numb">
                    <input
                      required
                      type="text"
                      value={number}
                      onChange={handlenumber}
                      placeholder="Mobile Number"
                      readOnly={numberreadonly}
                      maxLength="10"
                      onKeyPress={onKeyPressNumberWanto}
                    />
                    <div className="in_img">
                      <img className="slide_des" src={india_mo} alt="imhvgb" />
                      <span>+91</span>
                    </div>
                    {numbererror === true ? (
                      <span className="error pl-4">
                        Please Fill Phone Number Field*
                      </span>
                    ) : null}
                    {numbererror1 === true ? (
                      <span className="error pl-4">Invalid Phone Number*</span>
                    ) : null}
                  </div>
                </div>

                <div className="react_form_group">
                  <div className="mo_numb">
                    <input
                      type="email"
                      value={mail}
                      onChange={handlemailChange}
                      placeholder="Email id"
                      readOnly={emailReadOnly}
                    />
                    <div className="in_img">
                      <img className="slide_des" src={email_id} alt="imhvgb" />
                    </div>
                  </div>
                </div>

                <div className="react_form_group">
                  <div className="mo_numb">
                    <input
                      required
                      type="text"
                      value={pincode}
                      maxLength="6"
                      onChange={handlesearchChange}
                      placeholder="Pin Code"
                      readOnly={pinreadonly}
                      onKeyPress={onKeyPressOtpWanto}
                    />
                    {pincodeerror === true ? (
                      <span className="error pl-4">
                        Please Fill Pincode Field*
                      </span>
                    ) : null}
                    {pincodeformaterror === true ? (
                      <span className="error pl-4">Invalid Pin Code</span>
                    ) : null}
                  </div>
                </div>

                <div className="react_form_group next_popu">
                  <button
                    onClick={submitUserDetailTest}
                    className="default_btn"
                  >
                    Continue
                  </button>
                </div>

                <div className="react_form_group by_continuing">
                  <p>
                    {" "}
                    By continuing, you agree to
                    <Link to="/Tc" className="privacy_po">
                      {" "}
                      T&C{" "}
                    </Link>
                    &
                    <Link to="/privacypolicy" className="privacy_po">
                      {" "}
                      Privacy Policy
                    </Link>
                  </p>
                </div>
              </form>
            </div>
          </PureModal>
        </div>
      </div>
    </>
  );
};
export default Login;
