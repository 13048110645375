import React from "react";
import { Link } from "react-router-dom";

function ContactFooter() {
  return (
    <>
      <div className="footer_bottom">
        <div className="uk-container">
          <div className="Privacy_Policy">
            <ul className="uk-flex uk-flex-center">
              <li>
                Contact: <a href="tel:+91-80033-80063">+91-80033-80063</a>
              </li>
              <li>
                <Link to="/privacy-policy">Privacy Policy </Link> -{" "}
                <Link to="/TermsService"> Terms of Service</Link>
              </li>
            </ul>
          </div>
          <div className="Copyright">
            <p>@2024 Copyright HOP Electric Mobility Pvt. Ltd. </p>
            <p>All Rights Reserved.</p>
          </div>
        </div>
      </div>
    </>
  );
}
export default ContactFooter;
