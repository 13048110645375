import React from "react";
import PureModal from "react-pure-modal";
import ReCAPTCHA from "react-google-recaptcha";
import "./index.css";

const ReCAPTCHAModal = ({
  on,
  onClose,
  showCaptcha,
  onExecute,
  recaptchaRef,
}) => {
  return (
    <>
      {/* {showCaptcha && ( */}
      <div className="center">
        <ReCAPTCHA
          className={`captcha ${on}`}
          ref={recaptchaRef}
          size="invisible"
          // onChange={onExecute}
          sitekey={process.env.REACT_APP_SITE_KEY}
        />
      </div>
      {/* )} */}
    </>
  );
};

export default ReCAPTCHAModal;
