import React from "react";
import Slider from "react-slick";
import Eco_City from "../../../assets/images/Eco_City.jpg";
import Reverse from "../../../assets/images/Reverse.jpg";
import Degree from "../../../assets/images/Degree.jpg";
import Waterproof from "../../../assets/images/Waterproof.jpg";

export default function SiliderTwo() {
  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: false,
    speed: 500,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
  
        }
      },
    ]
  };
  return (
    <div className="slider_show">
      <Slider {...settings}>

        <div className="featur_para pop_slideron">
          <figure>
            <img  src={Eco_City} alt="imhvgb" />
          </figure>
          <div className="slide_cont">
            <h3>Eco - City - Power -Turbo | Ride Modes</h3>
            <p>
              Choose the way you want to ride. Want to get maximum range choose the eco mode or switch to turbo to unleash the maximus acceleration and top speed. 
              <br/>
              Your OXO. Your Choice
            </p>
          </div>
        </div>

        <div className="featur_para pop_slideron">
          <figure>
            <img  src={Reverse} alt="imhvgb" />
          </figure>
          <div className="slide_cont">
            <h3>Reverse Mode</h3>
            <p>
              Legacy motorcycles can not even think of this feature. This is the next-generation riding experience. Stuck in a tight parking spot or parked on a slope, just press “R”, and with the push of the throttle, watch the magic. 
No more exercising your legs to pull your motorcycle.
            </p>
          </div>
        </div>

        <div className="featur_para pop_slideron">
          <figure>
            <img  src={Degree} alt="imhvgb" />
          </figure>
          <div className="slide_cont">
            <h3>18 Degree Climbing Ability </h3>
            <p>
              IPMSM BLDC Motors with 40 MM 16 Pole Neodymium (NdFeB) magnets on Aluminium core is configured to deliver high quality & seamless motion. 
Efficiency η - 92.8% <br/>
200 NM torque <br/>
IP 67 All weather performance
            </p>
          </div>
        </div>

        <div className="featur_para pop_slideron">
          <figure>
            <img  src={Waterproof} alt="imhvgb" />
          </figure>
          <div className="slide_cont">
            <h3>Waterproof & Fireproof</h3>
            <p>
              Riding in the summer sun of Jaipur or water clogged roads of Mumbai during rains. Fear no more. Hop OXO’s eFlow powertrain is designed for high thermal performance.
               With IP 67-rated motors and batteries, you can literally ride underwater.
            </p>
          </div>
        </div>
     
      </Slider>
    </div>
  );
}
