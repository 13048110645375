import React, { useEffect } from "react";
import $ from "jquery";
import Footer from "../../components/common/layout/Footer";
import Header from "../../components/common/layout/Header";
import "../Testride/test-ride.css";
import "../policies/privacypolicy.css";
import { useScrollTo } from "../../hooks/useScrollTo";

const TermsService = () => {
  useScrollTo(0, 0);
  useEffect(() => {
    document.getElementById("data").addEventListener("scroll", (e) => {
      var yAxis = $(".container").offset().top;
      if (yAxis <= 100) {
        $(".header").hide();
      } else {
        $(".header").show();
      }
    });
  }, []);

  return (
    <>
      <Header />

      <div className="height_one_csrol header_hidecss">
        <div id="privacy-policy" className="top_header_pm">
          <div className="payment_secmain  privacy_policy_sec terms_service_sec">
            <div className="payment_cont pri_policy ter_service">
              <div className="content" id="data">
                <div className="container">
                  <h2 id="page-heading">Terms Of Service</h2>
                  <p>
                    This Privacy Policy has been drafted with the requirements
                    set forth by the Information Technology Act of 2000 and the
                    Information Technology Rules of 2011.
                  </p>
                  <p>
                    Hop Electric Mobility Pvt. Ltd. (“Hop”, “Company”) owns and
                    operates the website www.hopelectric.in (“Website”), and a
                    platform accessed through an application identified as Hop
                    (“App”, or “Platform”), that facilitate short term electric
                    scooter rentals, sale, subscriptions (“Service”). Any
                    individual (“User”, or “Visitor”) may access the Website and
                    download the App. To rent/buy/subscribe a scooter through
                    the Hop platform, the individual must agree and accept the
                    terms of the service during the registration process. This
                    Privacy Policy does not require any physical, electronic or
                    digital signature. In the event of any discrepancy between
                    the Terms and any other policies with respect to the
                    Platform, the provisions of the Terms shall prevail.{" "}
                  </p>
                  <p>
                    Upon gaining access to the platform, the User indicates
                    their agreement to this privacy policy. The User and Hop
                    will be a part of a legally binding agreement regarding the
                    collection, storage, processing and transfer of any or all
                    their Personal Information and Non-Personal Information by
                    Hop as specified under this Privacy Policy. You further
                    agree that such collection, use, storage and transfer of
                    Your Information shall not cause any loss or wrongful gain
                    to you or any other person. If the User does not agree with
                    the policy, they are recommended to stop using the Hop
                    Platform.{" "}
                  </p>
                  <p>
                    The company with the rights to the website app and service
                    is based in India and therefore must abide by the privacy
                    laws put forth by the Government of India. We try to protect
                    the Personal Information of our App’s users and we aim to
                    comply with data protection and consumer rights laws to the
                    extent they may apply. Our App is targeted towards Indian
                    citizens and our policies are directed at compliance with
                    those laws. If you are unsure whether this privacy policy is
                    applicable to the local laws where you are located, you
                    should not submit your Personal Information. Foreign
                    nationals utilizing the service through the app must notify
                    the company before providing their personal data as separate
                    privacy laws may be applicable.{" "}
                  </p>
                  <p>
                    Hop may change, revise, or add to the Privacy Policy at any
                    time in accordance with the applicable laws. Unless
                    otherwise stated, all changes will be effective immediately
                    and will apply to the use of the Platform thereafter. It is
                    recommended that the user visits the privacy policy as well
                    as the other legal documents regularly to review any changes
                    made to the policy, as they are legally binding to the User.
                    The continued use of the service after being notified of
                    changes will be taken as an agreement by the User to consent
                    to the revisions.{" "}
                  </p>
                  <h5>Information Collected </h5>
                  <p>
                    During the registration process, the User will have to
                    provide the following information: email address, phone
                    number, full name, password, date of birth, payment
                    information, a self-taken image of the user, and a valid
                    government ID. This personally identifiable information is
                    collected and used to verify the identity of the user. All
                    required information is service dependent, and we may use
                    the above said user information to, maintain, protect, and
                    improve its services (including advertising services) and
                    for developing new services
                  </p>
                  <p>
                    Such information will not be considered as sensitive if it
                    is freely available and accessible in the public domain or
                    is furnished under the Right to Information Act, 2005 or any
                    other law for the time being in force.{" "}
                  </p>
                  <p>
                    If a Visitor accesses the website, Hop may collect
                    information including but not limited to the domain name and
                    host from which the user accesses the Internet, the Internet
                    Protocol (IP) address of the computer, and the date and time
                    of the Visitor’s access. This information will allow Hop to
                    monitor the usage of the website.{" "}
                  </p>
                  <p>
                    While the User is operating the scooter, data regarding the
                    usage of the scooter will be collected by Hop for passenger
                    safety, scooter security, fleet management, support, and
                    other purposes deemed relevant. The data includes, but is
                    not limited to:{" "}
                  </p>
                  <ul>
                    <li>Route history and geo-location of the scooter</li>
                    <li>Trip information and driving data</li>
                    <li>Battery usage data</li>
                    <li>Sensor data for telemetry for scooter functionality</li>
                    <li>Location where the User pauses and ends the ride</li>
                  </ul>
                  <p>
                    While the App is in use, information regarding its
                    functionality, such as the unique device ID (UID), will be
                    collected as well. The information will be used for the
                    primary purpose of creating a pleasant experience while
                    using the platform, and to improve the functionality of the
                    platform and its features over time.{" "}
                  </p>
                  <p>
                    Hop may also send out surveys to Users to gain valuable
                    feedback regarding the service and platform. Answers to the
                    survey will be collected and utilized to improve the User’s
                    Hop experience.{" "}
                  </p>
                  <p>
                    Any forms of written communication between the User and
                    Company will be securely stored to keep a record for future
                    use by Hop.{" "}
                  </p>
                  <h5>Payment Transactions</h5>
                  <p>
                    Transactions made by the User for the service will be
                    protected by the secured technology utilized in the Paytm
                    and through a secured in-App Wallet Service. For Paytm
                    transactions, Hop will not interfere with the payment
                    process at any point or have access to the data the User
                    enters to make a payment. Hop is not liable for any failure
                    during a transaction on the payment gateway service offered
                    by Paytm. It is recommended the user familiarizes themselves
                    with the privacy policy and security measures of the Payment
                    Gateway. Paytm’s privacy policy may be found at:
                    https://pages.paytm.com/privacy.html. The User must accept
                    the risk of using the payment gateway and must agree to not
                    take legal action against Hop.
                  </p>
                  <p>
                    The in-app wallet service will also be protected by secured
                    technology. Information given regarding payments will not be
                    shared with other parties, or stored without the User’s
                    consent.{" "}
                  </p>
                  <p>
                    Hop will collect and store the User’s transaction history
                    details for the service usage to keep a record of payments
                    and refunds for the benefit of the User and the Company.{" "}
                  </p>
                  <p>
                    All payment transactions will be encrypted with SSL
                    technology.
                  </p>
                  <h5>Cookies and Other Technologies </h5>
                  <p>
                    Cookies or small files of information regarding the User’s
                    visit to the website may be saved on the device. This will
                    assign each visitor a unique, random number as a User
                    Identification (User ID) to understand the user’s individual
                    interests using the Identified Computer. The technology will
                    allow Hop to save the user’s preferences allowing for a more
                    convenient user experience. The use of cookies can be
                    blocked and or managed by the Visitor through the browser
                    settings, however this may limit the features available to
                    the visitor on the website. Web beacons and other
                    technologies may be used along with cookies to detect and
                    track a visitor’s interaction with the website.{" "}
                  </p>
                  <p>
                    The only personal information a cookie can contain is
                    information you supply. A cookie cannot read data off your
                    hard drive. Our advertisers may also assign their own
                    cookies to your browser (if you click on their ads), a
                    process that we do not control.{" "}
                  </p>
                  <h5>Use of Information </h5>
                  <p>
                    The information collected will be used by Hop for the
                    purposes stated below:{" "}
                  </p>
                  <ul>
                    <li>
                      Allow the user access to the platform and interact with
                      specific features
                    </li>
                    <li>Sending and receiving communications to the user</li>
                    <li>
                      Enforce rights and obligations of agreements agreed to
                      between the user and the company
                    </li>
                    <li>
                      To create statistics and models to better the platform and
                      service.
                    </li>
                    <li>
                      For any reason for which the User has given explicit
                      consent
                    </li>
                    <li>Any other use deemed relevant to the service</li>
                  </ul>
                  <h5>Sharing User Information </h5>
                  <p>
                    Hop respects the User’s personal information, and will not
                    share or sell personal information to a third party without
                    the User’s consent apart from the following purposes:{" "}
                  </p>
                  <p>
                    The User information will be shared with third parties for
                    the following:{" "}
                  </p>
                  <ul>
                    <li>Verification of User ID</li>
                    <li>Verify the eligibility of Accounts</li>
                    <li>Navigation and planning assistance</li>
                    <li>Process payments and refunds</li>
                    <li>Provide customer support</li>
                    <li>
                      Identifying scooters in need of a battery swap, repair, or
                      maintenance
                    </li>
                    <li>Allow the user to use specific platform features</li>
                    <li>
                      Any other third parties involved with features or services
                      available on the platform
                    </li>
                    <li>
                      For analysis on usage data of the scooters and the App
                    </li>
                  </ul>
                  <p>
                    We ensure that these recipients of such information agree to
                    process such information based on our instructions and in
                    compliance with this Privacy Policy and any other
                    appropriate confidentiality and security measures.{" "}
                  </p>
                  <h5>Children’s Privacy</h5>
                  <p>
                    Our Services are not intended to be used by anyone under the
                    age of 13 years. We do not knowingly collect personal
                    information from children under 13 years. If we realize that
                    a child under 13 years has provided us with personal
                    information, we will immediately delete this from our
                    servers. If you are a parent or a guardian and are aware
                    that your child has submitted personal information to our
                    platform, please contact us and we will take necessary
                    actions.{" "}
                  </p>
                  <h5>Links To and From Third Parties</h5>
                  <p>
                    Hop may include links to third parties on the platform. This
                    policy only discloses the privacy practices of our website.
                    The third-party privacy policies will determine what
                    information may be collected and how it will be used and are
                    beyond our control. We shall in no way be responsible in any
                    way for your use of such sites. Links from third party
                    websites may contain links that direct visitors to the Hop
                    website{" "}
                  </p>
                  <h5>Social Media </h5>
                  <p>
                    The Hop platform also includes all social media pages that
                    are owned and operated by the company. Any content posted by
                    Users on these pages must comply with the guidelines put
                    forth by the company. Content will be managed as per Hop’s
                    discretion, and content that does not align with the
                    guidelines may be removed. Hop may collect information
                    posted on social media pages for feedback or other business
                    initiatives. Hop cannot be held liable for posts made by
                    users on these social media pages. The user is responsible
                    for any personal information they post on the social media
                    pages as it is visible to other users.
                  </p>
                  <h5>Data Security</h5>
                  <p>
                    Hop has implemented security measures to protect the User’s
                    personal information collected from loss, or unauthorized
                    access, use, alteration, and disclosure. These include
                    internal reviews of our data collection, storage, and
                    processing practices and security measures, including
                    appropriate encryption and physical security measures to
                    guard against unauthorized access to systems where we store
                    personal data.{" "}
                  </p>
                  <p>
                    The User is also responsible for protecting their
                    information by choosing a secure password to access the
                    service and keeping the password confidential. It is also
                    recommended that the User not share their personal
                    information in any public areas visible to other users.{" "}
                  </p>
                  <p>
                    Hop will do their best to protect the personal information
                    of the user, but the security of personal information cannot
                    be guaranteed as unfortunately, information transmitted via
                    the internet is not completely secure. Any personal
                    information shared will be at the risk of the User. Hop is
                    not responsible for any circumvention of the privacy or
                    security measures implemented on the Platform.{" "}
                  </p>
                  <h5>Data Retention </h5>
                  <p>
                    Hop will store the User’s personal information for as long
                    as the user has an account with Hop. Some information will
                    be retained by the Company if it is relevant for legal,
                    financial or account obligations, or if it has already been
                    archived in Hop’s system. In the case of archived
                    information, the User data will be removed once the archives
                    are updated as per the discretion of Hop. Hop has the right
                    to transfer the retained information if the company is sold
                    or transferred. Hop will implement security measures on the
                  </p>
                  <p>
                    personal information that is retained to protect against
                    theft, misuse, or any other form of unauthorized access.{" "}
                  </p>
                  <h5>Opting Out</h5>
                  <p>
                    The User is able to opt-out of the service by emailing the
                    customer support team at hello@hop.bike.{" "}
                  </p>
                  <h5>Grievances</h5>
                  <p>
                    If the user has any grievances against Hop regarding their
                    privacy, they are requested to address the complaint to the
                    appointed Grievance Officer. Nikhil Bhatia is the Grievance
                    officer at Hop and can be reached at nikhil@hopelectric.in.
                    Any disputes are subject to the jurisdiction of the courts
                    of Jaipur.
                  </p>
                  <h5>Contact </h5>
                  <p>
                    If the User has any questions regarding their privacy while
                    utilizing the Hop services, they are requested to contact
                    the customer service at hello@hopelectric.in. Hop will
                    respond to any queries or concerns in a reasonable time.
                  </p>

                  <h5>Refund Policy</h5>
                  <p>
                    Pre-Bookings for Hop electric vehicles are refundable till
                    the time that the customer makes the full payment for the
                    vehicle.
                  </p>
                  <p>
                    All eligible refunds shall be processed by us within a
                    period of 7-15 working days.
                  </p>
                  <p>
                    Please note that the Customers will be eligible for refunds
                    only for the booking amount paid by the Customers. The
                    Customers shall not be eligible for any refunds on payment
                    by the Customer of the complete purchase amount towards the
                    vehicle.
                  </p>
                  <p>
                    The refund amount will be processed only via the means that
                    the customer paid for the pre-booking on the website.{" "}
                  </p>
                  <p>
                    If a customer has pre-booked offline with a dealership,
                    please contact your dealership for a refund.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default TermsService;
