import React from "react";

const Dashboard = () => {
  return (
    <h1 style={{ height: "400px", color: "black", textAlign: "center" }}>
      Dashboard
    </h1>
  );
};
export default Dashboard;
