import React from 'react'
import "./index.css";
import customers from "../../../assets/images/about_us/customers.png";

const Section7 = () => {
  return (
    <>
      <div class="sec_content_image_7">
        <div class="overlay"></div>
        <div className="container_about_us">
          <h2>CLIMATE ACTION IN NUMBERS</h2>
          <div className="net_counters">
            <div className="count_box">
              <div className="count_items">
                  <p className="count_num">11000+</p>
                  <p className="count_lable">Hop E2W  Customer</p>
              </div>
              <div className="count_items">
                  <p className="count_num">17383474</p>
                  <p className="count_lable">Electric Kilometres clocked & Counting</p>
              </div>
              <div className="count_items">
                  <p className="count_num">251935*</p>
                  <p className="count_lable">Kilograms of CO2 emissions saved</p>
              </div>
              <div className="count_items">
                  <p className="count_num">₹31251905*</p>
                  <p className="count_lable">Approx. Saving in fuel </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Section7