import React, { useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom";
import HamburgerMenu from "./HamburgerMenu/HamburgerMenu";
import logo_img from '../../../assets/images/HopLogo.svg'

function HeaderSec() {
    const [isHidden, setIsHidden] = useState(false);
    const location = useLocation();
    useEffect(() => {
        if (location.pathname === '/') {
          setIsHidden(false);
        } else {
          setIsHidden(true);
        }
      }, [location]);
    const removeclass = () => {
        let form = document.querySelector(".HamburgerMenu_hamburger__aXVme.HamburgerMenu_hamburgeractive__9iPUg");
        return (form ? form.click() : "");
    }

    return (
        <>
            <header className="header two_pages">
                <div className="uk-container uk-flex uk-flex-between">
                    <div className="logo"><Link to="/"><img  src={logo_img} alt="Logo image" /></Link></div>
                    <div className="right_sec">
                        <ul className="uk-nav uk-flex">
                           {!isHidden && <li><Link to="/dealership" onClick={removeclass}>Dealership</Link></li>}

                        </ul>
                        <div className="menu_bar">
                            <HamburgerMenu />                                                                                                                                        
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}
export default HeaderSec;
