import React from 'react'
import "./index.css";
import polygon from "../../../assets/images/about_us/polygon.png";

const Section3 = () => {

  return (
    <>
      <div class="sec_content_image">
        <div className="container_about_us">
          <div className="box-wrapper">
          <div className="box-content-bottom">
            <div className="box-header"></div>
              <p>SHIFT 90% OF TWO WHEELERS SALES TO ELECTRIC BY 2030</p>
              <p className="sm-description">THIS WILL SAVE :</p>
              <p className="benifit_mili">11 BILLION TONS OF CO2 <br/>1 MILLION BARRELS OF OIL EVERYDAY</p>
              <div className="poly_count">
                <p className="text-white">400 MILLION (2050)</p>
                <div className="polygon">
                  <img  src={polygon} alt="img not found" />
                </div>
                <p className="text-white">200 MILLION (TODAY)</p>
              </div>
              <div className="source">Source : BloombergNEF Electric vehicle outlook 2022 | The future of mobility 2022</div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Section3;