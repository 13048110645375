import React from "react";
import "./style.css";
import Header from "../../components/common/layout/Header";
import ThankYouCard from "../../components/Enquiry/ThankYouCard";
import { useScrollTo } from "../../hooks/useScrollTo";

const ThankYou = () => {
  useScrollTo(0, 0);
  return (
    <>
      <Header />
      <ThankYouCard />
    </>
  );
};

export default ThankYou;
