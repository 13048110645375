import React from "react"
import DetailFooter from './DetailFooter'
import ContactFooter from './ContactFooter'

function Footer() {
    return (
        <>
            <div id="footer">
            <DetailFooter />
            <ContactFooter />
            </div>
        </>
    )
}
export default Footer;
