import React from "react";
import Slider from "react-slick";
import OXO_Connected from "../../../assets/images/OXO_Connected.jpg";
import Features_for from "../../../assets/images/Features_for.jpg";

export default function SiliderFive() {
  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: false,
    speed: 500,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="slider_show">
      <Slider {...settings}>
        <div className="featur_para pop_slideron">
          <figure>
            <img
              src={OXO_Connected}
              alt="imhvgb"
               
            />
          </figure>
          <div className="slide_cont">
            <h3>OXO Connected Technology</h3>
            <p>
              OXO has 4G connected telematics with intelligent vehicle control
              unit that enable smart computations and real time data fetch to
              keep system up to date for better performance
            </p>
          </div>
        </div>

        <div className="featur_para pop_slideron">
          <figure>
            <img  src={Features_for} alt="imhvgb" />
          </figure>
          <div className="slide_cont">
            <h3>Features for HOP Nuron</h3>
            <p>
              HOP Nuron is a digital intelligent platform which enables
              interactive interface between consumer and our platform. App is
              first layer of interaction from HOP Nuron
            </p>
          </div>
        </div>
      </Slider>
    </div>
  );
}
