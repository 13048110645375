import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import "../Testride/test-ride.css";
import "../Order/order.css";
import "../dashboard/dashboard.css";
import "react-pure-modal/dist/react-pure-modal.min.css";
import logo_right from "../../assets/images/dx.png";
import confirm_bike from "../../assets/images/Confirmation_bike.png";
import popuplogomo from "../../assets/images/mo_popuplogo.png";
import Dashor_bike from "../../assets/images/Dashorder_bike.png";
import da_orderimg from "../../assets/images/dashb_orderimg.jpg";
import Footer from "../../components/common/layout/Footer";
import Header from "../../components/common/layout/Header";
import { Config } from "../../utils/apiUrl";
import { getCookie } from "../../utils";
import { useScrollTo } from "../../hooks/useScrollTo";

const Congrats = () => {
  useScrollTo(0, 0);
  const { id } = useParams();
  const [myBooking, setMyBooking] = useState();

  const getBookingData = async () => {
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + JSON.parse(getCookie("userdata")).user_token,
      },
      body: JSON.stringify({
        user_id: JSON.parse(getCookie("userdata")).user_id,
      }),
    };
    try {
      const responseBook = await fetch(Config.mybookings, requestOptions);
      if (responseBook) {
        const jsonBook = await responseBook.json();
        let filtered_booking = jsonBook.bookings.filter(
          (item) => item.ticket_id == id
        );
        setMyBooking(filtered_booking[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getBookingData();
  }, []);
  return (
    <>
      <Header />

      <div className="height_one_csrol">
        <div id="Congrats" className="top_header_pm">
          <div className="testride_map Confirmation graphics_sec dashboard_orde Colorpreferences_page congrats_page">
            <div className="map_left Confirmation_left dashboard_leftsec">
              <figure>
                <img  src={confirm_bike} alt="imhvgb" />
              </figure>

              <div className="after_savings">
                <figure className="gra_logo">
                  <img  src={popuplogomo} alt="imhvgb" />
                </figure>

                <figure>
                  <img  src={Dashor_bike} alt="imhvgb" />
                </figure>
              </div>
            </div>

            <div className="testride_righsec dashboard_rightsec Colorpreferences_right congrats_right">
              <div className="logo_right_cont destop_show">
                <img  src={logo_right} alt="imhvgb" />
              </div>
              <h3>Update color preferences</h3>
              <div className="mobile_show das_orderimg">
                <img  src={da_orderimg} alt="imhvgb" />
              </div>

              <h4 className="mobile_show">
                <span>Order ID:</span> 0222-NHM780
              </h4>

              <div className="cong_revolution">
                <h4 className="cong_colors_sec">Congrats, you’re now</h4>
                <h4 className="cong_colors_sec cong_color_second">
                  part of the revolution
                </h4>

                <div className="count_step">
                  <h5 className="active_step">1</h5>
                  <p>
                    <span>vehicle reserved</span>
                    on {moment(myBooking?.date).format("DD MMM YYYY")}
                  </p>
                </div>

                <div className="count_step">
                  <h5>2</h5>
                  <p>
                    <span>Purchase your Vehicle</span>
                    Choose from convenient financing options or pay an advance
                    amount to be first in the queue for delivery
                  </p>
                  <Link to={"/graphics/" + id} className="get_sta">
                    Get Started
                  </Link>
                </div>

                <div className="count_step">
                  <h5>3</h5>
                  <p>
                    <span>Vehicle shipment & delivery</span>
                    Delivery starts in Sept 2023
                  </p>
                </div>
              </div>

              <div className="color_preferences">
                <ul className="steps_colorsec next_pageview">
                  <li>
                    <Link to={"/Registrationaddress/" + id}>
                      Confirm registration address
                    </Link>
                  </li>
                  <li>
                    <Link to="/viewreceipts">View receipts</Link>
                  </li>
                  <li>
                    <Link to={"/Registrationaddress/" + id}>Modify Color</Link>
                  </li>
                  <li>
                    <Link to="/">Get support</Link>
                  </li>
                  <li>
                    <Link to={`/Cancelbooking/` + id}>Cancel order</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};
export default Congrats;
