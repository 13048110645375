import React from 'react'
import "./index.css"

export default function Featured() {
    return (
        <div>
            <div className='featured_main_sec'>
                <div className="marquee">
                    <div className="track">
                        <div className="content">
                            <img
                                src="https://uploads-ssl.webflow.com/6249c66aec4f22659488a365/6406d5aec072450002b65de0_Autocar.svg"
                                alt="image"
                                 
                            />
                            <img
                                src="https://uploads-ssl.webflow.com/6249c66aec4f22659488a365/6406d5afa76aa47f9417424b_AutoX.svg"
                                alt="image"
                                 
                            />
                            <img
                                src="https://uploads-ssl.webflow.com/6249c66aec4f22659488a365/6406d5b1ff03d9e44d194b2f_Bikewale.svg"
                                alt="image"
                                 
                            />
                            <img
                                src="https://uploads-ssl.webflow.com/6249c66aec4f22659488a365/6406d5b2d4b9da8551b7cdb5_Car%20and%20Bike.svg"
                                alt="image"
                                 
                            />
                            <img
                                src="https://uploads-ssl.webflow.com/6249c66aec4f22659488a365/6406d5b13e750f5d5ee93c67_Bike%20Dekho.svg"
                                alt="image"
                                 
                            />
                            <img
                                src="https://uploads-ssl.webflow.com/6249c66aec4f22659488a365/6406d5aec072450002b65de0_Autocar.svg"
                                alt="image"
                                 
                            />
                            <img
                                src="https://uploads-ssl.webflow.com/6249c66aec4f22659488a365/6406d5bbff03d958f1194d0e_The%20Financial%20Express.svg"
                                alt="image"
                                 
                            />
                            <img
                                src="https://uploads-ssl.webflow.com/6249c66aec4f22659488a365/6406d5b2d4b9da8551b7cdb5_Car%20and%20Bike.svg"
                                alt="image"
                                 
                            />
                            <img
                                src="https://uploads-ssl.webflow.com/6249c66aec4f22659488a365/6406d5b13e750f5d5ee93c67_Bike%20Dekho.svg"
                                alt="image"
                                 
                            />
                             <img
                                src="https://uploads-ssl.webflow.com/6249c66aec4f22659488a365/6406d5aec072450002b65de0_Autocar.svg"
                                alt="image"
                                 
                            />
                            <img
                                src="https://uploads-ssl.webflow.com/6249c66aec4f22659488a365/6406d5afa76aa47f9417424b_AutoX.svg"
                                alt="image"
                                 
                            />
                            <img
                                src="https://uploads-ssl.webflow.com/6249c66aec4f22659488a365/6406d5b1ff03d9e44d194b2f_Bikewale.svg"
                                alt="image"
                                 
                            />
                            <img
                                src="https://uploads-ssl.webflow.com/6249c66aec4f22659488a365/6406d5b2d4b9da8551b7cdb5_Car%20and%20Bike.svg"
                                alt="image"
                                 
                            />
                            <img
                                src="https://uploads-ssl.webflow.com/6249c66aec4f22659488a365/6406d5b13e750f5d5ee93c67_Bike%20Dekho.svg"
                                alt="image"
                                 
                            />
                            <img
                                src="https://uploads-ssl.webflow.com/6249c66aec4f22659488a365/6406d5aec072450002b65de0_Autocar.svg"
                                alt="image"
                                 
                            />
                            <img
                                src="https://uploads-ssl.webflow.com/6249c66aec4f22659488a365/6406d5b1ff03d9e44d194b2f_Bikewale.svg"
                                alt="image"
                                 
                            />
                            <img
                                src="https://uploads-ssl.webflow.com/6249c66aec4f22659488a365/6406d5b2d4b9da8551b7cdb5_Car%20and%20Bike.svg"
                                alt="image"
                                 
                            />
                            <img
                                src="https://uploads-ssl.webflow.com/6249c66aec4f22659488a365/6406d5b13e750f5d5ee93c67_Bike%20Dekho.svg"
                                alt="image"
                                 
                            />
                        </div>
                    </div>
                </div>
                <div className="marquee">
                    <div className="track1">
                        <div className="content">
                            <img
                                src="https://uploads-ssl.webflow.com/6249c66aec4f22659488a365/6406d5b3722f322f3013766a_Business%20Insider.svg"
                                alt="image"
                                 
                            />
                            <img
                                src="https://uploads-ssl.webflow.com/6249c66aec4f22659488a365/6406d5b3ff03d9a303194b3c_Drive%20Spark.svg"
                                alt="image"
                                 
                            />
                            <img
                                src="https://uploads-ssl.webflow.com/6249c66aec4f22659488a365/6406d5b5f897a231418fcb98_England%20News%20Portal.svg"
                                alt="image"
                                 
                            />
                            <img
                                src="https://uploads-ssl.webflow.com/6249c66aec4f22659488a365/6406d5ba3e750f4bcae93d64_Techradar.svg"
                                alt="image"
                                 
                            />
                            <img
                                src="https://uploads-ssl.webflow.com/6249c66aec4f22659488a365/6406d5bdbb4a994f421ee297_Washington%20DC%20Despatch.svg"
                                alt="image"
                                 
                            />
                            <img
                                src="https://uploads-ssl.webflow.com/6249c66aec4f22659488a365/6406d5b79e2c94826b42d3ff_HTAuto.svg"
                                alt="image"
                                 
                            />
                            <img
                                src="https://uploads-ssl.webflow.com/6249c66aec4f22659488a365/6406d5b3722f322f3013766a_Business%20Insider.svg"
                                alt="image"
                                 
                            />
                            <img
                                src="https://uploads-ssl.webflow.com/6249c66aec4f22659488a365/6406d5b3ff03d9a303194b3c_Drive%20Spark.svg"
                                alt="image"
                                 
                            />
                            <img
                                src="https://uploads-ssl.webflow.com/6249c66aec4f22659488a365/6406d5b5f897a231418fcb98_England%20News%20Portal.svg"
                                alt="image"
                                 
                            />
                            <img
                                src="https://uploads-ssl.webflow.com/6249c66aec4f22659488a365/6406d5ba3e750f4bcae93d64_Techradar.svg"
                                alt="image"
                                 
                            />
                             <img
                                src="https://uploads-ssl.webflow.com/6249c66aec4f22659488a365/6406d5b3722f322f3013766a_Business%20Insider.svg"
                                alt="image"
                                 
                            />
                            <img
                                src="https://uploads-ssl.webflow.com/6249c66aec4f22659488a365/6406d5b3ff03d9a303194b3c_Drive%20Spark.svg"
                                alt="image"
                                 
                            />
                            <img
                                src="https://uploads-ssl.webflow.com/6249c66aec4f22659488a365/6406d5b5f897a231418fcb98_England%20News%20Portal.svg"
                                alt="image"
                                 
                            />
                            <img
                                src="https://uploads-ssl.webflow.com/6249c66aec4f22659488a365/6406d5ba3e750f4bcae93d64_Techradar.svg"
                                alt="image"
                                 
                            />
                            <img
                                src="https://uploads-ssl.webflow.com/6249c66aec4f22659488a365/6406d5bdbb4a994f421ee297_Washington%20DC%20Despatch.svg"
                                alt="image"
                                 
                            />
                            <img
                                src="https://uploads-ssl.webflow.com/6249c66aec4f22659488a365/6406d5b79e2c94826b42d3ff_HTAuto.svg"
                                alt="image"
                                 
                            />
                            <img
                                src="https://uploads-ssl.webflow.com/6249c66aec4f22659488a365/6406d5b3722f322f3013766a_Business%20Insider.svg"
                                alt="image"
                                 
                            />
                            <img
                                src="https://uploads-ssl.webflow.com/6249c66aec4f22659488a365/6406d5b3ff03d9a303194b3c_Drive%20Spark.svg"
                                alt="image"
                                 
                            />
                            <img
                                src="https://uploads-ssl.webflow.com/6249c66aec4f22659488a365/6406d5b5f897a231418fcb98_England%20News%20Portal.svg"
                                alt="image"
                                 
                            />
                            <img
                                src="https://uploads-ssl.webflow.com/6249c66aec4f22659488a365/6406d5ba3e750f4bcae93d64_Techradar.svg"
                                alt="image"
                                 
                            />
                        </div>
                    </div>
                </div>
                <div className="marquee">
                    <div className="track2">
                        <div className="content"> <img
                            src="https://uploads-ssl.webflow.com/6249c66aec4f22659488a365/6406d5b6c34465e23ef636a5_Federal%20Despatch.svg"
                            alt="image"
                             
                        />
                            <img
                                src="https://uploads-ssl.webflow.com/6249c66aec4f22659488a365/6406d5b8722f321829137695_Mint.svg"
                                alt="image"
                                 
                            />
                            <img
                                src="https://uploads-ssl.webflow.com/6249c66aec4f22659488a365/6406d5b9a76aa4118517441e_Motoroids.svg"
                                alt="image"
                                 
                            />
                            <img
                                src="https://uploads-ssl.webflow.com/6249c66aec4f22659488a365/6406d5afbe87b1125beb2256_Bike%20Auto.svg"
                                alt="image"
                                 
                            />
                            <img
                                src="https://uploads-ssl.webflow.com/6249c66aec4f22659488a365/6406d5bc154efb4c3ba9579b_The%20Week.svg"
                                alt="image"
                                 
                            />
                            <img
                                src="https://uploads-ssl.webflow.com/6249c66aec4f22659488a365/6406d5b51905459f19bd00a2_Evo.svg"
                                alt="image"
                                 
                            />
                            <img
                                src="https://uploads-ssl.webflow.com/6249c66aec4f22659488a365/6406d5b6c34465e23ef636a5_Federal%20Despatch.svg"
                                alt="image"
                                 
                            />
                            <img
                                src="https://uploads-ssl.webflow.com/6249c66aec4f22659488a365/6406d5b8722f321829137695_Mint.svg"
                                alt="image"
                                 
                            />
                            <img
                                src="https://uploads-ssl.webflow.com/6249c66aec4f22659488a365/6406d5b9a76aa4118517441e_Motoroids.svg"
                                alt="image"
                                 
                            />
                            <img
                                src="https://uploads-ssl.webflow.com/6249c66aec4f22659488a365/6406d5afbe87b1125beb2256_Bike%20Auto.svg"
                                alt="image"
                                 
                            />
                            <img
                                src="https://uploads-ssl.webflow.com/6249c66aec4f22659488a365/6406d5bc154efb4c3ba9579b_The%20Week.svg"
                                alt="image"
                                 
                            />
                            <img
                                src="https://uploads-ssl.webflow.com/6249c66aec4f22659488a365/6406d5b51905459f19bd00a2_Evo.svg"
                                alt="image"
                                 
                            />
                             <img
                                src="https://uploads-ssl.webflow.com/6249c66aec4f22659488a365/6406d5b8722f321829137695_Mint.svg"
                                alt="image"
                                 
                            />
                            <img
                                src="https://uploads-ssl.webflow.com/6249c66aec4f22659488a365/6406d5b9a76aa4118517441e_Motoroids.svg"
                                alt="image"
                                 
                            />
                            <img
                                src="https://uploads-ssl.webflow.com/6249c66aec4f22659488a365/6406d5afbe87b1125beb2256_Bike%20Auto.svg"
                                alt="image"
                                 
                            />
                            <img
                                src="https://uploads-ssl.webflow.com/6249c66aec4f22659488a365/6406d5bc154efb4c3ba9579b_The%20Week.svg"
                                alt="image"
                                 
                            />
                            <img
                                src="https://uploads-ssl.webflow.com/6249c66aec4f22659488a365/6406d5b51905459f19bd00a2_Evo.svg"
                                alt="image"
                                 
                            />
                            <img
                                src="https://uploads-ssl.webflow.com/6249c66aec4f22659488a365/6406d5b6c34465e23ef636a5_Federal%20Despatch.svg"
                                alt="image"
                                 
                            />
                            <img
                                src="https://uploads-ssl.webflow.com/6249c66aec4f22659488a365/6406d5b8722f321829137695_Mint.svg"
                                alt="image"
                                 
                            />
                            <img
                                src="https://uploads-ssl.webflow.com/6249c66aec4f22659488a365/6406d5b9a76aa4118517441e_Motoroids.svg"
                                alt="image"
                                 
                            />
                            <img
                                src="https://uploads-ssl.webflow.com/6249c66aec4f22659488a365/6406d5afbe87b1125beb2256_Bike%20Auto.svg"
                                alt="image"
                                 
                            />
                            <img
                                src="https://uploads-ssl.webflow.com/6249c66aec4f22659488a365/6406d5bc154efb4c3ba9579b_The%20Week.svg"
                                alt="image"
                                 
                            />
                            <img
                                src="https://uploads-ssl.webflow.com/6249c66aec4f22659488a365/6406d5b51905459f19bd00a2_Evo.svg"
                                alt="image"
                                 
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
