
  import {
    GoogleMap,
    MarkerF,
  } from '@react-google-maps/api'
  import {  useState } from 'react'
  import { useParams} from "react-router-dom";

  const OrderPageMap=()=>{
    const {lat,lng,dealerid}=useParams();
  
    const [map, setMap] = useState(/** @type google.maps.Map */ (null))
    return (
        <div className="map_left">
        <GoogleMap
          center={{lat: parseFloat(lat),lng: parseFloat(lng) }}
          zoom={8}
          mapContainerStyle={{ width: '100%', height: '100%' }}
          options={{
            zoomControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
          }}
          onLoad={map => setMap(map)}
        >
            <MarkerF position={{lat:parseFloat(lat),lng: parseFloat(lng) }}/>
         </GoogleMap>
     </div>
    )
  }

  export default OrderPageMap;