import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { AiFillCheckCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import PureModal from "react-pure-modal";
import "../CareerOne/index.css";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../../components/common/layout/Footer";
import Header from "../../components/common/layout/Header";
import {
  GET_UserDetailAPI,
  POST_SendVerifyOTPAPI,
  POST_ServiceRequestAPI,
} from "../../APIs";

import { getCookie } from "../../utils";
import { useScrollTo } from "../../hooks/useScrollTo";

const Service = () => {
  useScrollTo(0, 0);
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [modelName, setModelName] = useState("");
  const [vinNumber, setVinNumber] = useState("");
  const [purchaseWithin, setPurchaseWithin] = useState("");
  const [OtherOpen, setOtherOpen] = useState(false);
  const [reason, setReason] = useState("");
  const [query, setquery] = useState("");
  const [displaySendOTP, setDisplaySendOTP] = useState(false);
  const [OTP, setOTP] = useState("");
  const [showOTPModal, setShowOTPModal] = useState(false);
  const [OTPVerified, setOTPVerified] = useState(false);
  const [pincode, setPincode] = useState("");
  const [NameErr, setNameErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [mobile_NumberErr, setMobileNumberErr] = useState("");
  const [pincodeErr, setPincodeErr] = useState("");
  const [modelErr, setModelErr] = useState("");
  const [VinErr, setVinErr] = useState("");
  const [purchaseWithinErr, setPurchaseWithinErr] = useState("");
  const [reasonErr, setReasonErr] = useState("");
  const [queryErr, setQueryErr] = useState("");
  const [OTPError, setOTPError] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const [countdown, setCountdown] = useState(30);
  const [isCounting, setIsCounting] = useState(false);
  const [resent, setResent] = useState(false);
  const [VerifyON, setVerifyON] = useState(false);

  useEffect(() => {
    document.getElementById("scrollForm").addEventListener("scroll", (e) => {
      let yAxisForHeader = document.querySelector("#UsingForHeader").scrollTop;
      var yAxisScrollForm = document.getElementById("scrollForm").scrollTop;
      if (yAxisForHeader > 10 || yAxisScrollForm > 10) {
        window.document.querySelector("header").style.display = "none";
      } else {
        window.document.querySelector("header").style.display = "block";
      }
    });
    return () =>
      document.getElementById("scrollForm")?.addEventListener("scroll");
  }, []);

  useEffect(() => {
    document
      .getElementById("UsingForHeader")
      .addEventListener("scroll", (e) => {
        let yAxisForHeader =
          document.querySelector("#UsingForHeader").scrollTop;
        let yAxisScrollForm = document.getElementById("scrollForm").scrollTop;
        if (yAxisForHeader > 10 || yAxisScrollForm > 10) {
          window.document.querySelector("header").style.display = "none";
        } else {
          window.document.querySelector("header").style.display = "block";
        }
      });
    return () =>
      document.getElementById("UsingForHeader")?.addEventListener("scroll");
  }, []);

  useEffect(() => {
    let userCookie = getCookie("userdata");
    if (userCookie) {
      setMobileNumber(JSON.parse(userCookie)?.user_number);
      setLoggedIn(true);
      setOTPVerified(true);
      getUserDetail();
    } else {
      setLoggedIn(false);
    }
  }, []);

  const getUserDetail = async () => {
    let userDetail = await GET_UserDetailAPI();
    setName(userDetail?.name);
    setEmail(userDetail?.email);
    if (userDetail?.phone_num) {
      setMobileNumber(userDetail?.phone_num);
    } else {
      setMobileNumber(userDetail?.username);
    }
    setPincode(userDetail?.pin_code);
  };

  const onKeyPressZipWanto = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9]").test(keyValue)) event.preventDefault();
    return true;
  };

  const handleMobileInputChange = (e) => {
    const { value } = e.target;
    if (value.length === 10) {
      setDisplaySendOTP(true);
    } else {
      setDisplaySendOTP(false);
    }
    const phoneNumberDigits = value.replace(/\D/g, "");
    setMobileNumber(phoneNumberDigits);
  };

  const handlePinCodeInputChange = (e) => {
    const { value } = e.target;
    const pinCodeDigits = value.replace(/\D/g, "");
    setPincode(pinCodeDigits);
  };

  const handleInputVIN = (e) => {
    const { value } = e.target;
    const vinNumberx = value.replace(/\W/g, "");
    setVinNumber(vinNumberx);
  };

  const handleInputKeyDown = (e) => {
    if (e.ctrlKey || e.altKey) {
      e.preventDefault();
    } else {
      var key = e.keyCode;

      if (
        !(
          key == 8 ||
          key == 9 ||
          key == 32 ||
          key == 46 ||
          (key >= 35 && key <= 40) ||
          (key >= 65 && key <= 90)
        )
      ) {
        e.preventDefault();
      }
    }
  };

  const handleSelect = (e) => {
    setReason(e.target.value);
    if (e.target.value === "Others") {
      setOtherOpen(true);
    } else {
      setOtherOpen(false);
    }
  };

  // Function to start the countdown
  const startCountdown = () => {
    setIsCounting(true);
    setCountdown(30); // Reset the countdown to the initial value
  };

  useEffect(() => {
    let interval;

    // Function to handle the countdown
    const handleCountdown = () => {
      if (countdown > 0) {
        setCountdown((prev) => prev - 1);
      } else {
        if (resent) {
          setResent(false);
        }
        setIsCounting(false);
        clearInterval(interval);
      }
    };

    // Start the countdown when isCounting is true
    if (isCounting) {
      interval = setInterval(handleCountdown, 1000);
    }

    // Clean up the interval when the component unmounts
    return () => clearInterval(interval);
  }, [isCounting, countdown]);
  const enableResend = () => {
    startCountdown();
  };

  const handleResend = () => {
    enableResend();
    resendOTP();
  };

  //Submitting Service Request
  const handleSubmit = async (event) => {
    event.preventDefault();
    const NameRegex = /^[a-z ,.'-]+$/i;
    const EmailRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    let err = 0;

    if (name == "") {
      setNameErr("Please Enter Name.");
      err++;
    } else if (!NameRegex.test(name)) {
      setNameErr("Please Enter Valid Name.");
      err++;
    } else {
      setNameErr("");
    }

    if (email == "") {
      setEmailErr("Please Enter Email.");
      err++;
    } else if (!EmailRegex.test(email)) {
      setEmailErr("Please Enter Valid Email.");
      err++;
    } else {
      setEmailErr("");
    }

    if (mobileNumber == "") {
      setMobileNumberErr("Please Enter Mobile Number.");
      err++;
    } else if (mobileNumber.length !== 10) {
      setMobileNumberErr("Please Enter Valid Mobile Number.");
      err++;
    } else if (!OTPVerified) {
      setMobileNumberErr("Please Verify OTP.");
      err++;
    } else {
      setMobileNumberErr("");
    }

    if (pincode == "") {
      setPincodeErr("Please Enter Pin Code.");
      err++;
    } else if (pincode.length !== 6) {
      setPincodeErr("Please Enter Valid Pin Code.");
      err++;
    } else {
      setPincodeErr("");
    }

    if (modelName == "") {
      setModelErr("Please Select Model Name.");
      err++;
    } else {
      setModelErr("");
    }

    if (purchaseWithin == "") {
      setPurchaseWithinErr("Please Select Purchase WithIn.");
      err++;
    } else {
      setPurchaseWithinErr("");
    }
    if (reason == "") {
      setReasonErr("Please Select Reason.");
      err++;
    } else if (reason == "Others") {
      if (query == "") {
        setQueryErr("Please Type Your Query.");
        err++;
      } else {
        setQueryErr("");
      }
    } else {
      setReasonErr("");
    }
    if (err == 0) {
      let userCookie = getCookie("userdata");
      let parsedUserCookie;
      if (userCookie) {
        parsedUserCookie = JSON.parse(userCookie);
      }
      const serviceObject = {
        phone_num: mobileNumber,
        email: email,
        name: name,
        pin_code: pincode,
        query: `${query}`,
        query_title: `${reason}`,
        vin_num: `${vinNumber}`,
        user_id: parsedUserCookie ? parsedUserCookie?.user_id : "",
        source: "OXO website",
        flag: !parsedUserCookie ? "new_user" : "",
        purchase_within: purchaseWithin,
        model: modelName,
      };
      try {
        let res = await POST_ServiceRequestAPI(serviceObject);
        if (res) {
          if (res.data) {
            localStorage.setItem("pincode", JSON.stringify(pincode));
            setName("");
            setEmail("");
            setMobileNumber("");
            setModelName("");
            setVinNumber("");
            setPurchaseWithin("");
            setReason("");
            setPincode("");
            setOTPVerified(false);
            setDisplaySendOTP(false);
            toast.success(
              "Thank You, Your Service Request has been submitted.",
              {
                autoClose: 2000,
              }
            );
            navigate("/");
          } else {
            toast.error("Something went wrong...", {
              autoClose: 2000,
            });
          }
        }
      } catch (error) {
        toast.error("Something went wrong...", {
          autoClose: 2000,
        });
      }
      // }
    }
  };

  //Send OTP
  const getOTP = async (event) => {
    event.preventDefault();
    try {
      const response = await POST_SendVerifyOTPAPI({
        phone_num: mobileNumber,
        otp: "",
      });
      if (response.message === "sms sent sucessfully") {
        setShowOTPModal(true);
        enableResend();
      } else {
        toast.error(response.message, { autoClose: 1500 });
      }
    } catch (error) {
      toast.error("Something went wrong.", { autoClose: 1500 });
    }
  };

  const resendOTP = async () => {
    try {
      const response = await POST_SendVerifyOTPAPI({
        phone_num: mobileNumber,
        otp: "",
      });
      if (response.message === "sms sent sucessfully") {
        setResent(true);
        setOTPError();
      } else {
        toast.error(response.message, { autoClose: 1500 });
      }
    } catch (error) {
      toast.error("Something went wrong.", { autoClose: 1500 });
    }
  };

  //verify OTP
  const handleVerifyOTP = async (event) => {
    event.preventDefault();
    setResent(false);
    setIsCounting(false);
    setOTPError("");
    setVerifyON(true);
    if (!OTP) {
      setOTPError("Please Enter OTP.");
      return false;
    } else if (OTP.length !== 6) {
      setOTPError("Please Enter Valid OTP.");
      return false;
    } else {
      setOTPError("");
    }

    try {
      const response = await POST_SendVerifyOTPAPI({
        phone_num: mobileNumber,
        otp: OTP,
      });
      if (response) {
        if (response.authenticated_user === "True") {
          setShowOTPModal(false);
          setOTPVerified(true);
          setOTP("");
          setVerifyON(false);
          setMobileNumberErr("");
        } else {
          setVerifyON(false);
          setOTPError("Invalid or Incorrect OTP.");
        }
      }
    } catch (error) {
      toast.error("Something went wrong." + error.message, { autoClose: 1500 });
    }
  };

  return (
    <>
      <div className="form-group opt_popup service_otppop">
        <PureModal
          onClose={() => {
            setShowOTPModal(false);
            setIsCounting(false);
            setOTP();
            setOTPError("");
            return true;
          }}
          isOpen={showOTPModal}
          closeButton={<div className="close_btn"></div>}
          closeButtonPosition="top"
          width="100%"
        >
          <div className="otp_cont">
            <h2>Enter the OTP sent to</h2>
            <h2>
              +91********
              {mobileNumber
                ?.toString()
                .slice(
                  Number(mobileNumber?.toString().length) - 2,
                  mobileNumber?.toString().length
                )}
            </h2>
            <p>Please Enter The OTP Received on Your Mobile Number</p>
            {OTPError && !isCounting ? (
              <div className="otp_resend_div">
                <p style={{ color: "red" }}>{OTPError}</p>
                <button
                  style={{ marginLeft: "5px", color: "rgb(0, 0, 238)" }}
                  onClick={handleResend}
                >
                  {" "}
                  Resend?
                </button>
              </div>
            ) : null}

            {!isCounting && !resent && !OTPError && !VerifyON ? (
              <div className="otp_resend_div">
                <p style={{ color: "red" }}>Didn’t get the OTP?</p>
                <button
                  style={{ marginLeft: "5px", color: "rgb(0, 0, 238)" }}
                  onClick={handleResend}
                >
                  {" "}
                  Resend?
                </button>
              </div>
            ) : null}
            {isCounting && resent ? (
              <div className="otp_resend_div">
                <p style={{ color: "red" }}>OTP Resent Successfully.</p>
              </div>
            ) : null}

            <div className="form-group">
              <input
                type="text"
                placeholder="ENTER OTP"
                maxLength={6}
                onChange={(e) => setOTP(e.target.value)}
                value={OTP}
                onKeyPress={onKeyPressZipWanto}
              />
            </div>
            <button className="default_btn" onClick={(e) => handleVerifyOTP(e)}>
              Submit
            </button>
          </div>
        </PureModal>
      </div>
      <Header />
      <div
        className="height_one_csrol career_one service_ticket"
        id="UsingForHeader"
      >
        <div className="min_color">
          <div className="form_main_sec" id="scrollForm">
            <div className="form_sec_new_inner">
              <div className="form_sec_new before_remove">
                <div className="scroll_header">
                  <div className="form_head">
                    <h2>Submit Your Service Request</h2>
                  </div>
                  <form>
                    <div className="form-group">
                      <label>Name*</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        onKeyDown={(e) => handleInputKeyDown(e)}
                      />
                      <small style={{ color: "red" }}>{NameErr}</small>
                    </div>

                    <div className="form-group">
                      <label>Email Address*</label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email Address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <small style={{ color: "red" }}>{emailErr}</small>
                    </div>

                    <div className="form-group">
                      <label>Registered Mobile Number*</label>
                      <div style={{ position: "relative" }}>
                        <input
                          type="tel"
                          className="form-control"
                          placeholder="Mobile Number"
                          value={mobileNumber}
                          onChange={handleMobileInputChange}
                          maxLength={10}
                          disabled={loggedIn}
                          style={loggedIn ? { cursor: "not-allowed" } : null}
                          title={loggedIn ? "Registered Mobile Number" : null}
                        />
                        {!loggedIn && displaySendOTP && !OTPVerified && (
                          <button
                            className="send_otp_form"
                            onClick={(e) => getOTP(e)}
                          >
                            Send OTP
                          </button>
                        )}

                        {OTPVerified ? (
                          <p className="otp_verified">
                            <AiFillCheckCircle color="#00EAFF" size={30} />
                          </p>
                        ) : null}
                      </div>
                      <small style={{ color: "red" }}>{mobile_NumberErr}</small>
                    </div>

                    <div className="form-group">
                      <label>PIN Code*</label>
                      <div style={{ position: "relative" }}>
                        <input
                          type="tel"
                          className="form-control"
                          placeholder="Pin Code"
                          value={pincode}
                          onChange={handlePinCodeInputChange}
                          maxLength={6}
                        />
                      </div>
                      <small style={{ color: "red" }}>{pincodeErr}</small>
                    </div>
                    <div className="form-group">
                      <label>Model*</label>
                      <select
                        value={modelName}
                        className="form-control"
                        onChange={(e) => setModelName(e.target.value)}
                      >
                        <option value="">--- Select ---</option>
                        <option value="LEO">LEO</option>
                        <option value="LYF">LYF</option>
                        <option value="OXO">OXO</option>
                      </select>

                      <small style={{ color: "red" }}>{modelErr}</small>
                    </div>

                    <div className="form-group">
                      <label>Vehicle Identification Number </label>
                      <input
                        type="tel"
                        className="form-control"
                        placeholder="VIN No."
                        maxLength={17}
                        value={vinNumber}
                        onChange={handleInputVIN}
                      />
                      <small style={{ color: "red" }}>{VinErr}</small>
                    </div>

                    <div className="form-group">
                      <label>Purchase Within*</label>
                      <select
                        value={purchaseWithin}
                        className="form-control"
                        onChange={(e) => setPurchaseWithin(e.target.value)}
                      >
                        <option value="">--- Select ---</option>
                        <option value="1 - 3 Months">1 - 3 Months</option>
                        <option value="3 - 6 Months">3 - 6 Months</option>
                        <option value="6 - 12 Months">6 - 12 Months</option>
                        <option value="More Than 1 Year">
                          More Than 1 Year
                        </option>
                      </select>
                      <small style={{ color: "red" }}>
                        {purchaseWithinErr}
                      </small>
                    </div>

                    <div className=" form-group ">
                      <label>Please Select the Reason*</label>
                      <select
                        className="form-control"
                        value={reason}
                        onChange={handleSelect}
                      >
                        <option value="">Please Select the Reason</option>
                        <option value="VCU Error">VCU Error</option>
                        <option value="Vehicle Failure">Vehicle Failure</option>
                        <option value="Battery Error">Battery Error</option>
                        <option value="Motor Problem">Motor Problem</option>
                        <option value="Others">Others</option>
                      </select>
                      <small style={{ color: "red" }}>{reasonErr}</small>
                    </div>
                    <br></br>
                    <div
                      className="form-group service_textarea"
                      style={{ display: OtherOpen ? "block" : "none" }}
                    >
                      <label style={{ opacity: "0" }} className="other_label">
                        Please Select the Reason*
                      </label>
                      {OtherOpen ? (
                        <>
                          <textarea
                            required
                            type="text"
                            value={query}
                            style={{
                              padding: "5px",
                              border: "1px solid #525252",
                              backgroundColor: "#171717",
                              transition: "background-color 200ms ease",
                              color: "#737373",
                            }}
                            onChange={(e) => setquery(e.target.value)}
                            placeholder="Type your query here..."
                          />
                          <small style={{ color: "red" }}>{queryErr}</small>
                        </>
                      ) : (
                        <textarea
                          type="text"
                          value={query}
                          onChange={(e) => setquery(e.target.value)}
                          placeholder="Type your query here..."
                        />
                      )}
                    </div>

                    <div
                      className="send_btn forms_click_btn"
                      style={{ textAlign: "center" }}
                    >
                      <button
                        className="default_btn"
                        type="submit"
                        onClick={handleSubmit}
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Service;
