import { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import PureModal from "react-pure-modal";
import $ from "jquery";
import moment from "moment";
import { toast } from "react-toastify";
import "../Testride/test-ride.css";
import "react-pure-modal/dist/react-pure-modal.min.css";
import logo_right from "../../assets/images/dx.png";
import confirm_bike from "../../assets/images/Confirmation_bike.png";
import bike_re_conf from "../../assets/images/conf-bike.png";
import location_icons_icon from "../../assets/images/location_icons.png";
import Rectangle_booktest from "../../assets/images/Rectangle_booktest.png";
import phone_icons_icon from "../../assets/images/phone_icons.png";
import Footer from "../../components/common/layout/Footer";
import Header from "../../components/common/layout/Header";
import LoadingSpinner from "../../assets/images/spinner.svg";
import { Config } from "../../utils/apiUrl";
import { JAIPUR_LATITUDE, JAIPUR_LONGITUDE } from "../../constants";
import {
  GET_ALL_DEALERS_FROM_GET_ALL_API,
  GET_TestDriveDataAPI,
} from "../../APIs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useScrollTo } from "../../hooks/useScrollTo";
import { getCookie } from "../../utils";

const HomeConfirmation = () => {

  useScrollTo(0, 0);
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const dealerid = useParams();
  const params = useParams();
  const [showcancel, opencancel] = useState(false);
  const [showcance2, opencance2] = useState(false);
  const [showcance3, opencance3] = useState(false);
  const [dealers, setDealer] = useState("Dealer");
  const [userhome, sethome] = useState("Home");
  const [localdata, setLocal] = useState(null);
  const [homeconfirmation, setHomeconfirmation] = useState("homeconfirmation");
  const [getdate, setdate1] = useState("");
  const [username, setusername] = useState(null);
  const [userdate, setTime] = useState(dealerid.date);
  const [time, setUserdate] = useState(dealerid.ticketid);
  const [dealerForTestRide, setDealerForTestRide] = useState("");
  const [previousRide, setPreviousRide] = useState("");
  const [myLat, setMyLat] = useState();
  const [myLong, setMyLong] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getLocation();
  }, []);

  const date = new Date();
  const estimateMonth = date.getMonth() + 1;
  const estimateYear = date.getFullYear();
  const newDate = new Date(estimateYear, estimateMonth + 3);
  const dateName = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const modal = () => {
    opencance3(false);
  };

  const getCookiedata = (expiredata) => {
    const cookieData = getCookie(expiredata);
    if (!cookieData) {
      navigate("/");
    }
  };

  useEffect(() => {
    getCookiedata("userdata");
  }, []);

  useEffect(() => {
    $(document).ready(function () {
      $(".header").show();
    });
    $(window).on("load", function () {
      $(".header").show();
    });
  }, []);

  const getMyTestDriveData = async () => {
    setLoading(true);
    let response = await GET_TestDriveDataAPI();
    let filtered_test_drive = response.filter(
      (item) => item.ticket_id == params.ticket_id
    );
    setLocal(filtered_test_drive[0]);
  };

  useEffect(() => {
    if (localdata) {
      getAndSetADealer();
    }
  }, [localdata]);

  useEffect(() => {
    getMyTestDriveData();
  }, []);

  useEffect(() => {
    let dealername = localStorage.getItem("Dealername");
    if (dealername !== "undefined") {
      setusername(JSON.parse(dealername));
    }
  }, []);

  const getAndSetADealer = async () => {
    let response = await GET_ALL_DEALERS_FROM_GET_ALL_API(
      myLat,
      myLong,
      localdata.pin_code
    );
    setPreviousRide(response.testdrive_dealer_details);
    let filteredDealerForTestRide = response.dealers?.filter((item, index) => {
      return item.id == localdata?.dealer_id;
    });
    setDealerForTestRide(filteredDealerForTestRide[0]);
    setLoading(false);
  };

  function getLocation() {
    if (navigator.permissions) {
      navigator.permissions.query({ name: "geolocation" }).then((result) => {
        if (result.state === "prompt" || result.state === "denied") {
          result.onchange = () => {
            window.location.reload();
          };
        } else if (result.state == "granted") {
          result.onchange = () => {
            window.location.reload();
          };
        }
      });
    } else {
      console.log("Permissions API is not supported by this browser.");
    }
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      console.log("Browser Not Supported");
    }
  }

  function showPosition(position) {
    setMyLat(position.coords.latitude);
    setMyLong(position.coords.longitude);
  }

  const cancelbooktestride = async () => {
    const requestOptionsForOtpVarification = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(getCookie("userdata")).user_token,
      },
      body: JSON.stringify({
        ticket_id: `${localdata?.ticket_id}`,
        reason: "Booked Another Test Ride",
      }),
    };
    try {
      const response = await fetch(
        Config.cancel_testride,
        requestOptionsForOtpVarification
      );
      const json = await response.json();
      if (json.data == "Test drive cancelled successfully") {
        navigate("/booktestride");
        return true;
      } else if (json.error) {
        alert("Already cancelled");
        return false;
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (localdata) {
      let date = localdata?.date.split("-");
      let temp = date[0];
      date[0] = date[1];
      date[1] = temp;
    }
  }, [localdata]);

  useEffect(() => {
    if (userdate) {
      let dates = userdate.split("-");
      let temp1 = dates[0];
      dates[0] = dates[1];
      dates[1] = temp1;
      let lastdate2 = dates.join("-");
      setdate1(lastdate2);
    }
  }, [userdate]);

  const Navigat = () => {
    if (localdata.status == 3) {
      toast.error("Test Ride can't be rescheduled as it is cancelled.", {
        autoClose: 1500,
      });
    } else {
      if (params.type == "Dealer") {
        navigate(
          `/booktestride/${dealers}/${Number(
            localdata?.ticket_id
          )}/${username}/${homeconfirmation}/${Number(localdata.pin_code)}`
        );
      }
      if (params.type == "Home") {
        navigate(
          `/booktestride/${userhome}/${Number(
            localdata?.ticket_id
          )}/${homeconfirmation}/${Number(localdata.pin_code)}`
        );
      }
    }
  };

  const openMapInNewTab = (latitude, longitude) => {
    if (myLat && myLong) {
      if (!latitude || !longitude) {
        toast.info("Direction Not Available for this dealer.", {
          autoClose: 1000,
        });
      } else {
        let url = `https://www.google.com/maps/dir/${myLat}+${myLong}+/${latitude},+${longitude}`;
        window.open(url, "_blank");
      }
    } else {
      let url = `https://www.google.com/maps/dir/${JAIPUR_LATITUDE}+${JAIPUR_LONGITUDE}+/${latitude},+${longitude}`;
      window.open(url, "_blank");
    }
  };

  useEffect(() => {
    document
      .getElementById("home-Confirmation")
      ?.addEventListener("scroll", (e) => {
        var yAxis = $(".Confirmation").offset().top;

        if (yAxis <= -60) {
          $(".header").hide();
        } else {
          $(".header").show();
        }
      });
  }, []);

  const setFocusOnModalButton = () => {
    let reschedule_test_drive = document.getElementById(
      "reschedule_test_drive"
    );
    if (reschedule_test_drive) {
      reschedule_test_drive.focus();
    } else {
      let book_another_test_drive = document.getElementById(
        "book_another_test_drive"
      );
      if (book_another_test_drive) {
        book_another_test_drive.focus();
      } else {
        let cancel_booking = document.getElementById("cancel_booking");
        if (cancel_booking) {
          cancel_booking.focus();
        }
      }
    }
  };

  useEffect(() => {
    if (showcance2) {
      setFocusOnModalButton();
    }
  }, [showcance2]);

  useEffect(() => {
    document
      .getElementById("height_one_home_confirmation")
      .addEventListener("scroll", (e) => {
        var yAxis = $(".home_confirmation").offset().top;

        if (yAxis < 0) {
          $(".header").hide();
        } else {
          $(".header").show();
        }
      });
  }, []);

  return (
    <>
      <Header />
      <div className="height_one_csrol" id="height_one_home_confirmation">
        <div id="home_Confirmation" className="top_header_pm">
          <div className="testride_map Confirmation home_confirmation">
            <div className="map_left Confirmation_left">
              <figure>
                <img  src={confirm_bike} alt="imhvgb" />
              </figure>
              <div className="after_savings">
                <figure>
                  <img  src={bike_re_conf} alt="imhvgb" />
                </figure>
              </div>
            </div>
            <div className="only_mobileshow mobile_show">
              <img  src={Rectangle_booktest} alt="imhvgb" />
            </div>
            <div className="testride_righsec order_id">
              <div className="logo_right_cont destop_show">
                <img  src={logo_right} alt="imhvgb" />
              </div>

              <h3>Confirmation</h3>
              <h4>
                Your ride has been{" "}
                <span className="color_book_text">Booked</span> <br />{" "}
                <span className="color_book_text">successfully</span>
              </h4>

              {loading ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {" "}
                  <img
                    style={{ height: "50px", height: "50px" }}
                     src={LoadingSpinner}
                  />
                </div>
              ) : (
                <>
                  <div className="order_sec_id order_id_confirmation">
                    <strong>
                      <span>Order ID :</span> {localdata?.order_id}
                    </strong>
                  </div>
                  <div className="appointment_data">
                    <div className="requested">
                      {params.type == "Dealer" ? (
                        <h4>
                          Appointment scheduled at the {localdata?.dealer_name}
                        </h4>
                      ) : (
                        <h4>Test ride requested at your doorstep</h4>
                      )}
                      <ul>
                        <li>
                          <span className="mobile_show">
                            <FontAwesomeIcon icon={faCheck} />
                          </span>

                          <span>
                            {" "}
                            Make sure you keep your driving license handy before
                            the test ride starts{" "}
                          </span>
                        </li>
                        <li>
                          <span className="mobile_show">
                            <FontAwesomeIcon icon={faCheck} />
                          </span>
                          <span>
                            {" "}
                            Wearing a Helmet is mandatory while taking the test
                            ride
                          </span>
                        </li>
                        {params.type == "Dealer" ? (
                          ""
                        ) : (
                          <li>
                            <span className="mobile_show">
                              <FontAwesomeIcon icon={faCheck} />
                            </span>
                            <span>
                              Our team will reach out to you to confirm your
                              slot preference
                            </span>
                          </li>
                        )}
                      </ul>
                      {params.type == "Dealer" ? (
                        <p className="confirmation_booked_location">
                          <span>Location:</span>
                          {localdata?.dealer_address}
                        </p>
                      ) : (
                        <p className="confirmation_booked_location">
                          <span>Location:</span>
                          {previousRide?.dealer_address}
                        </p>
                      )}
                      {width < 767 ? (
                        <div className="date_tme_mobile">
                          <h3>Appointment slot</h3>
                          <p>
                            <span>
                              <FontAwesomeIcon icon={faCalendarDays} />
                            </span>
                            {localdata?.testdrive_date &&
                              moment(localdata?.testdrive_date).format(
                                "DD-MM-YYYY"
                              )}
                          </p>

                          <p>
                            <span>
                              <FontAwesomeIcon icon={faCalendarDays} />
                            </span>
                            {time ? time : localdata?.time_slot}
                          </p>
                        </div>
                      ) : null}

                      {params.type == "Dealer" &&
                      dealerForTestRide?.latitude !== "null" &&
                      dealerForTestRide?.longitude !== "null" ? (
                        <p className="slot_after_p">
                          <span
                            onClick={() =>
                              openMapInNewTab(
                                dealerForTestRide?.latitude,
                                dealerForTestRide?.longitude
                              )
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <img
                               src={location_icons_icon}
                              alt="imhvgb"
                              style={{ cursor: "pointer" }}
                            />
                            Get direction
                          </span>
                        </p>
                      ) : (
                        ""
                      )}
                      <span className="show_mobile devider"></span>

                      {params.type == "Dealer" ? (
                        <p className="slot_after_p">
                          <span>
                            {" "}
                            <img  src={phone_icons_icon} alt="imhvgb" />
                          </span>
                          <a href={`tel:${dealerForTestRide?.phone_num}`}>
                            {dealerForTestRide?.phone_num}
                          </a>{" "}
                        </p>
                      ) : (
                        ""
                      )}

                      {width > 767 ? (
                        <p>
                          <span>Date:</span>
                          {localdata?.testdrive_date &&
                            moment(localdata?.testdrive_date).format(
                              "DD-MM-YYYY"
                            )}
                        </p>
                      ) : null}
                      {width > 767 ? (
                        <p>
                          <span>Time:</span>
                          {time ? time : localdata?.time_slot}
                        </p>
                      ) : null}
                      <div></div>
                    </div>
                  </div>
                </>
              )}

              <div className="test_ridestep">
                <ul>
                  <li>
                    <Link to="/booknow">Book OXO</Link>
                  </li>
                </ul>
              </div>
              {loading ? null : (
                <div className="test_ridestep">
                  <ul>
                    <li
                      onClick={() => {
                        opencance2(true);
                      }}
                    >
                      <Link to=" ">Modify</Link>
                    </li>
                  </ul>
                </div>
              )}

              <div className="next_btn">
                <button
                  onClick={() => navigate("/userDashboard")}
                  className="default_btn"
                >
                  View Activities
                </button>
              </div>
            </div>
          </div>

          <div className="requested_popup close_button  appointment_popup">
            <PureModal
              onClose={() => {
                opencance2(false);
                return true;
              }}
              isOpen={showcance2}
              closeButton={<div className="close_btn"></div>}
              closeButtonPosition="top"
              width="100%"
            >
              <div className="popup_cont test_reque">
                <div className="requested">
                  {params.type == "Dealer" ? (
                    <h3>
                      Appointment scheduled at the {localdata?.dealer_name}
                    </h3>
                  ) : (
                    <h3> Test ride requested at your doorstep</h3>
                  )}
                  <ul>
                    <li>
                      <span className="show_mobile">
                        <FontAwesomeIcon icon={faCheck} />
                      </span>
                      Make sure you keep your driving license handy before the
                      test ride starts{" "}
                    </li>
                    <li>
                      <span className="show_mobile">
                        <FontAwesomeIcon icon={faCheck} />
                      </span>
                      Wearing a Helmet is mandatory while taking the test ride
                    </li>
                    {params.type == "Dealer" ? (
                      ""
                    ) : (
                      <li>
                        <span className="show_mobile">
                          <FontAwesomeIcon icon={faCheck} />
                        </span>
                        Our team will reach out to you to confirm your slot
                        preference
                      </li>
                    )}
                  </ul>
                  {params.type == "Dealer" ? (
                    <p>
                      <span>Location:</span>
                      {localdata?.dealer_address}
                    </p>
                  ) : (
                    <p>
                      <span>Location:</span>
                      {previousRide?.dealer_address}
                    </p>
                  )}
                  {width < 767 ? (
                    <div className="date_tme_mobile">
                      <h3>Appointment slot</h3>
                      <p>
                        <span>
                          <FontAwesomeIcon icon={faCalendarDays} />
                        </span>
                        {localdata?.testdrive_date &&
                          moment(localdata?.testdrive_date).format(
                            "DD-MM-YYYY"
                          )}
                      </p>

                      <p>
                        <span>
                          <FontAwesomeIcon icon={faCalendarDays} />
                        </span>
                        {time ? time : localdata?.time_slot}
                      </p>
                    </div>
                  ) : null}

                  {params.type == "Dealer" &&
                  dealerForTestRide?.latitude !== "null" &&
                  dealerForTestRide?.longitude !== "null" ? (
                    <p className="slot_after_p">
                      <span
                        onClick={() =>
                          openMapInNewTab(
                            dealerForTestRide?.latitude,
                            dealerForTestRide?.longitude
                          )
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <img
                           src={location_icons_icon}
                          alt="imhvgb"
                          style={{ cursor: "pointer" }}
                        />
                        Get direction
                      </span>
                    </p>
                  ) : (
                    ""
                  )}
                  <span className="show_mobile devider"></span>

                  {params.type == "Dealer" ? (
                    <p className="slot_after_p">
                      <span>
                        {" "}
                        <img  src={phone_icons_icon} alt="imhvgb" />
                      </span>
                      {dealerForTestRide?.phone_num}
                    </p>
                  ) : (
                    ""
                  )}
                  {width > 767 ? (
                    <p>
                      <span>Date:</span>
                      {localdata?.testdrive_date &&
                        moment(localdata?.testdrive_date).format("DD-MM-YYYY")}
                    </p>
                  ) : null}
                  {width > 767 ? (
                    <p>
                      <span>Time:</span>
                      {time ? time : localdata?.time_slot}
                    </p>
                  ) : null}
                  <div className="test_ridestep">
                    <ul>
                      {moment(new Date()).format("YYYY-MM-DD") <
                      moment(new Date(localdata?.testdrive_date)).format(
                        "YYYY-MM-DD"
                      ) ? (
                        <button
                          style={{ width: "100%" }}
                          id="reschedule_test_drive"
                          onClick={() => Navigat()}
                        >
                          <li>
                            <Link to="">Reschedule Test Ride</Link>
                          </li>
                        </button>
                      ) : moment(new Date()).format("YYYY-MM-DD") <=
                          moment(new Date(getdate)).format("YYYY-MM-DD") &&
                        (moment(new Date()).format("HH:mm") <
                        localdata?.time_slot.startsWith("9AM")
                          ? "08:00"
                          : localdata?.time_slot.startsWith("12PM")
                          ? "11:00"
                          : "14:00") ? (
                        <button
                          style={{ width: "100%" }}
                          id="reschedule_test_drive"
                          onClick={() => Navigat()}
                        >
                          <li>
                            <Link to="">Reschedule Test Ride</Link>
                          </li>
                        </button>
                      ) : null}
                      <button
                        style={{ width: "100%" }}
                        id="book_another_test_drive"
                        onClick={() => opencance3(true)}
                      >
                        <li>
                          <Link
                            style={{ margin: 0, marginBottom: "10px" }}
                            to=" "
                          >
                            Book Another Test Ride
                          </Link>
                        </li>
                      </button>
                      <button
                        style={{ width: "100%" }}
                        id="cancel_booking"
                        onClick={() => opencancel(true)}
                      >
                        <li>
                          <Link
                            to=" "
                            style={{ margin: 0, marginBottom: "10px" }}
                          >
                            Cancel Booking{" "}
                          </Link>
                        </li>
                      </button>
                    </ul>
                  </div>
                  <div className="next_btn">
                    <button
                      onClick={() => navigate("/userDashboard")}
                      className="default_btn"
                    >
                      {" "}
                      View Activities{" "}
                    </button>
                  </div>
                </div>
              </div>
            </PureModal>
          </div>

          <div
            className="cancel_ride form-group modal_popup test_ride_popup form-group modal_popup  close_button "
            id="cancel_testride"
          >
            <PureModal
              onClose={() => {
                opencancel(false);
                return true;
              }}
              isOpen={showcancel}
              closeButton={
                <div
                  className="close_btn sure_cncel_popup"
                  onClick={() => opencancel(false)}
                ></div>
              }
              closeButtonPosition="top"
              width="100%"
            >
              <div className="popup_cont color_change reschedule_info requested ">
                <h3>Are you sure you want to cancel your test ride?</h3>
                <p>
                  you can also consider rescheduling <br />
                  the test ride to a diffrerent slot
                </p>
                {params.type == "Dealer" ? (
                  <p>
                    <span>Location:</span>
                    {localdata?.dealer_address}
                  </p>
                ) : (
                  <p>
                    <span>Location:</span>
                    {previousRide?.dealer_address}
                  </p>
                )}
                {width < 767 ? (
                  <div className="date_tme_mobile">
                    <h3>Appointment slot</h3>
                    <p>
                      <span>
                        <FontAwesomeIcon icon={faCalendarDays} />
                      </span>
                      {localdata?.testdrive_date &&
                        moment(localdata?.testdrive_date).format("DD-MM-YYYY")}
                    </p>

                    <p>
                      <span>
                        <FontAwesomeIcon icon={faCalendarDays} />
                      </span>
                      {time ? time : localdata?.time_slot}
                    </p>
                  </div>
                ) : null}
                <div className="reschedule_infobtn">
                  {moment(new Date()).format("YYYY-MM-DD") <
                  moment(new Date(localdata?.testdrive_date)).format(
                    "YYYY-MM-DD"
                  ) ? (
                    <button
                      className="user_btn border_btn"
                      onClick={() => Navigat()}
                    >
                      Reschedule Test Ride
                    </button>
                  ) : moment(new Date()).format("YYYY-MM-DD") <=
                      moment(new Date(localdata?.testdrive_date)).format(
                        "YYYY-MM-DD"
                      ) &&
                    (moment(new Date()).format("HH:mm") <
                    localdata?.time_slot.startsWith("9AM")
                      ? "08:00"
                      : localdata?.time_slot.startsWith("12PM")
                      ? "11:00"
                      : "14:00") ? (
                    <button
                      className="user_btn border_btn"
                      onClick={() => Navigat()}
                    >
                      Reschedule Test Ride
                    </button>
                  ) : null}
                  <button
                    className="user_btn border_btn"
                    onClick={() => {
                      return (
                        opencancel(false),
                        navigate("/CancelTestrides/" + params.ticket_id)
                      );
                    }}
                  >
                    Cancel Test Ride{" "}
                  </button>
                </div>
              </div>
            </PureModal>
          </div>

          <div
            className="form-group modal_popup test_ride_popup book_another close_button"
            id="cancel_testride"
          >
            <PureModal
              onClose={() => {
                opencance3(false);
                return true;
              }}
              isOpen={showcance3}
              closeButton={
                <div
                  className="close_btn"
                  onClick={() => opencance3(false)}
                ></div>
              }
              closeButtonPosition="top"
              width="100%"
            >
              <div className="popup_cont requested">
                <h3>Cancel previous Test Ride and continue?</h3>
                <p>
                  only 1 booking is allowed a time continuing with this booking
                  will cancel the previous one
                </p>
                <p>
                  <span>Location:</span>
                  {previousRide?.dealer_address}
                </p>
                <p>
                  <span>Date:</span>
                  {previousRide?.testdrive_date && previousRide?.testdrive_date}
                </p>
                <p>
                  <span>Time:</span> {previousRide?.time_slot}
                </p>
                <p>Do you want to cancel ?</p>
                <div className="book_anpo">
                  <button
                    onClick={() => {
                      return modal(), opencance2(false);
                    }}
                    className="user_btn border_btn"
                  >
                    No
                  </button>
                  <button
                    onClick={() => cancelbooktestride()}
                    className="user_btn"
                  >
                    Yes
                  </button>
                </div>
              </div>
            </PureModal>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};
export default HomeConfirmation;
