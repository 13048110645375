import React from "react";
import styles from "./HamburgerMenu.module.scss";

const Hamburger = ({ className, onClick }) => (
   <button className={`navbar_lines uk-flex uk-flex-column ${className}`}  onClick={onClick}>
        <span />
        <span />
        <span />
    </button>  
);
export default Hamburger;
