//PYTHON API BASE_URL
export const PYTHON_BASE_URL = process.env.REACT_APP_PYTHON_URL;
//Node API BASE URL
export const NODE_BASE_URL = process.env.REACT_APP_NODE_URL;

export const PYTHON_API_BASE_ENDPOINT = "/hop-api/";

export const COMPLETE_URL_STAGE = PYTHON_BASE_URL + PYTHON_API_BASE_ENDPOINT;

export const payment_url = "http://43.204.133.45:5001/booking/";
export const postalpincode_url = "https://api.postalpincode.in/pincode/";

export const Config = {
  state: `${COMPLETE_URL_STAGE}get_state_list/`,
  otp_varification: `${COMPLETE_URL_STAGE}otp_varification/`,
  city_state: `${COMPLETE_URL_STAGE}get_city_state/`,
  price: `${COMPLETE_URL_STAGE}get_price/`,
  pre_booking_ticket: `${COMPLETE_URL_STAGE}pre_booking_ticket/`,
  get_dealer: `${COMPLETE_URL_STAGE}get_dealer/`,
  test_drive: `${COMPLETE_URL_STAGE}test_drive/`,
  email_check: `${COMPLETE_URL_STAGE}email_check/`,
  check_home_delivery: `${COMPLETE_URL_STAGE}home_delivery_check/`,
  my_test_drives: `${COMPLETE_URL_STAGE}my-test-drives/`,
  leads: `${PYTHON_BASE_URL}/leads`,
  service_request: `${COMPLETE_URL_STAGE}service_ticket/`,
  test_drive_ticket: `${COMPLETE_URL_STAGE}test_drive_ticket/`,
  payment_ticket: `${COMPLETE_URL_STAGE}payment_ticket/`,
  service_ticket: `${COMPLETE_URL_STAGE}service_ticket/`,
  get_price_all: `${COMPLETE_URL_STAGE}get_price_all/`,
  mybookings: `${COMPLETE_URL_STAGE}my-bookings/`,
  pincode: `${COMPLETE_URL_STAGE}get_all_data/`,
  booktestride: `${COMPLETE_URL_STAGE}book_hometestride/`,
  booktestride_event: `${COMPLETE_URL_STAGE}book_event/`,
  cancel_testride: `${COMPLETE_URL_STAGE}cancel-test-drive/`,
  resechulde: `${COMPLETE_URL_STAGE}reschedule-testdrive/`,
  dashboardAPI: `${COMPLETE_URL_STAGE}latest_task/`,
  myServiceAPI: `${COMPLETE_URL_STAGE}my-service-tickets/`,
  newsletter_subscribeAPI: `${COMPLETE_URL_STAGE}newsletter_subscribe/`,
  cancelPreBookingAPI: `${COMPLETE_URL_STAGE}cancel-pre-booking/`,
  POST_ApplyAPI: `${COMPLETE_URL_STAGE}career_create/`,
  GET_getTicketDetailsAPI: `${COMPLETE_URL_STAGE}ticket/`,
  GET_preBookingTicketDetailsAPI: `${COMPLETE_URL_STAGE}pre_booking_ticket/`,
  POST_modifyBookingColorAPI: `${COMPLETE_URL_STAGE}edit_booking/`,
  POST_cancelServiceTicketAPI: `${COMPLETE_URL_STAGE}cancel_service_ticket/`,
  GET_UserDetailAPI: `${COMPLETE_URL_STAGE}user/`,
  GET_AllData: `${COMPLETE_URL_STAGE}get_all_data/`,

  payment: `${payment_url}`,
  postalpincode: `${postalpincode_url}`,

  insertdealer: `${NODE_BASE_URL}insertdealer`,
  fectch_dealer: `${NODE_BASE_URL}fetchDealer`,
  fetchAllDealers: `${NODE_BASE_URL}fetchAllDealer`,
  fetchSearchDealer: `${NODE_BASE_URL}fetchSearchDealer`,
  search_dealer: `${NODE_BASE_URL}searchDealer`,
  export_dealer: `${NODE_BASE_URL}exportDealer`,
  addEnquiry: `${NODE_BASE_URL}add-enquiry`,
  listEnquiries: `${NODE_BASE_URL}get-enquiries`,
  exportEnquiries: `${NODE_BASE_URL}exportEnquiries`,
  addContactForm: `${NODE_BASE_URL}formContact`,
  verifyCaptcha: `${NODE_BASE_URL}verify-captcha`,
  downloadDealerList: `${NODE_BASE_URL}uploads/dealers.xlsx`,
};
