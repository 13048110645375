import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import Footer from "../../components/common/layout/Footer";
import Header from "../../components/common/layout/Header";
import { Config } from "../../utils/apiUrl";
import { useScrollTo } from "../../hooks/useScrollTo";

const ContactUs = () => {
  useScrollTo(0, 0);
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [mobile_Number, setMobileNumber] = useState();
  const [state, setState] = useState();
  const [stateList, setStateList] = useState([]);
  const [message, setMessage] = useState();
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [firstNameErr, setFirstNameErr] = useState();
  const [lastNameErr, setLastNameErr] = useState();
  const [emailErr, setEmailErr] = useState();
  const [mobile_NumberErr, setMobileNumberErr] = useState();
  const [stateErr, setStateErr] = useState();
  const [messageErr, setMessageErr] = useState();

  useEffect(() => {
    document.getElementById("ContactUs").addEventListener("scroll", (e) => {
      let yAxisForHeader = document.querySelector("#UsingForHeader").scrollTop;
      var yAxisContactUs = document.getElementById("ContactUs").scrollTop;
      if (yAxisForHeader > 10 || yAxisContactUs > 10) {
        window.document.querySelector("header").style.display = "none";
      } else {
        window.document.querySelector("header").style.display = "block";
      }
    });
    return () =>
      document.getElementById("ContactUs")?.addEventListener("scroll");
  }, []);

  useEffect(() => {
    document
      .getElementById("UsingForHeader")
      .addEventListener("scroll", (e) => {
        let yAxisForHeader =
          document.querySelector("#UsingForHeader").scrollTop;
        let yAxisContactUs = document.getElementById("ContactUs").scrollTop;
        if (yAxisForHeader > 10 || yAxisContactUs > 10) {
          window.document.querySelector("header").style.display = "none";
        } else {
          window.document.querySelector("header").style.display = "block";
        }
      });
    return () =>
      document.getElementById("UsingForHeader")?.addEventListener("scroll");
  }, []);

  const openMapInNewTab = (parameter) => {
    let url = "";
    if (parameter == 0) {
      url = `https://www.google.com/maps/dir/${latitude},${longitude}/Hop+Launchpad,+Plot+no.+1,+Sector+11,+Jhulelal+Marg,+Shipra+Path,+Jhalana+Chhod,+Mansarovar,+Jaipur,+Rajasthan+302020/@26.882341,75.7570832,13z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x396db57d9e410713:0xd99d290d7ae15447!2m2!1d75.7764315!2d26.8447572`;
    } else if (parameter === 1) {
      url = `https://www.google.com/maps/dir/${latitude},${longitude}/Hop+Electric+Mobility+-+Electric+Scooter+Showroom+in+Jaipur/@26.8670378,75.7854156,17z/data=!3m1!4b1!4m6!3m5!1s0x396db5f3bfedd88b:0x52aeacd41c9b65d0!8m2!3d26.8670378!4d75.7854156!16s%2Fg%2F11mvvb_fxf`;
    }
    window.open(url, "_blank");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const NameRegex = /^[a-z ,.'-]+$/i;
    const EmailRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    let err = 0;

    if (!firstName) {
      setFirstNameErr("Please Enter First Name.");
      err++;
    } else if (!NameRegex.test(firstName)) {
      setFirstNameErr("Please Enter Valid First Name.");
      err++;
    } else {
      setFirstNameErr("");
    }

    if (!lastName) {
      setLastNameErr("Please Enter Last Name.");
      err++;
    } else if (!NameRegex.test(lastName)) {
      setLastNameErr("Please Enter Valid Last Name.");
      err++;
    } else {
      setLastNameErr("");
    }

    if (email == "") {
      setEmailErr("Please Enter Email.");
      err++;
    } else if (!EmailRegex.test(email)) {
      setEmailErr("Please Enter Valid Email.");
      err++;
    } else {
      setEmailErr("");
    }

    if (!mobile_Number) {
      setMobileNumberErr("Please enter mobile number.");
      err++;
    } else {
      setMobileNumberErr();
    }

    if (!state || state == "") {
      setStateErr("Please select the state.");
      err++;
    } else {
      setStateErr();
    }
    if (!message) {
      setMessageErr("Please enter your message.");
      err++;
    } else {
      setMessageErr();
    }

    if (err == 0) {
      setFirstNameErr();
      setLastNameErr();
      setEmailErr();
      setMobileNumberErr();
      setStateErr();
      setMessageErr();

      const data = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        phone: mobile_Number,
        state: state,
        message: message,
      };

      try {
        let response = await axios.post(Config.addContactForm, data, {
          headers: {
            "Content-type": "application/json",
          },
        });
        if (response == "success") {
          setFirstName("");
          setLastName("");
          setEmail("");
          setMobileNumber("");
          setState("");
          setMessage("");
          toast("Your message has been recorded", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 800,
          });
        }
      } catch (error) {
        console.log(error);
        if (error.response.data.status == 200) {
          setFirstName("");
          setLastName("");
          setEmail("");
          setMobileNumber("");
          setState("");
          setMessage("");
          toast("Your message has been recorded", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 800,
          });
        }
        console.log(error);
      }
    }
  };
  const LoadState = async () => {
    try {
      let stateData = await fetch(Config.state);
      let json = await stateData.json();

      if (json) {
        setStateList(json?.states);
      }
    } catch (r) {}
  };

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
      });
    } 
    LoadState();
  }, []);

  const handleInputFirstNameChange = (e) => {
    const { value } = e.target;
    const phoneNumberDigits = value.replace(/[^a-z]+/i, "");
    setFirstName(phoneNumberDigits);
  };
  const handleInputLastNameChange = (e) => {
    const { value } = e.target;
    const phoneNumberDigits = value.replace(/[^a-z]+/i, "");
    setLastName(phoneNumberDigits);
  };

  const handleInputMobileChange = (e) => {
    const { value } = e.target;
    const phoneNumberDigits = value.replace(/\D/g, "");
    setMobileNumber(phoneNumberDigits);
  };


  return (
    <>
      <Header />
      <div className="height_one_csrol dealer_parent" id="UsingForHeader">
        <div class="form_main_sec" id="contact_us_form_page">
          <div className="form_sec_new_inner">
            <div class="form_sec_new" id="ContactUs">
              <div class="form_head">
                <h2>Reach out to Us</h2>
                <p>
                  We are here to assist you, fill out the form below to reach
                  out to our team.
                </p>
              </div>

              <form>
                <div class="form-group">
                  <label>First name:</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="First name"
                    value={firstName}
                    onChange={handleInputFirstNameChange}
                  />

                  <small style={{ color: "red" }}>{firstNameErr}</small>
                </div>

                <div class="form-group">
                  <label>Last name:</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Last name"
                    value={lastName}
                    onChange={handleInputLastNameChange}
                  />
                  <small style={{ color: "red" }}>{lastNameErr}</small>
                </div>

                <div class="form-group">
                  <label>Email address:</label>
                  <input
                    type="email"
                    class="form-control"
                    placeholder="Email address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <small style={{ color: "red" }}>{emailErr}</small>
                </div>

                <div class="form-group">
                  <label>Mobile Number:</label>
                  <input
                    type="tel"
                    class="form-control"
                    placeholder="Mobile Number"
                    value={mobile_Number}
                    onChange={handleInputMobileChange}
                    maxLength={10}
                  />
                  <small style={{ color: "red" }}>{mobile_NumberErr}</small>
                </div>

                <div class="form-group">
                  <label>State:</label>
                  <select
                    value={state}
                    class="form-control"
                    onChange={(e) => setState(e.target.value)}
                  >
                    <option value="">Select State</option>
                    {stateList.map((item) => (
                      <option>{item}</option>
                    ))}

                  </select>
                  <small style={{ color: "red" }}>{stateErr}</small>
                </div>
                <div class="form-group">
                  <label>Message:</label>
                  <textarea
                    class="form-control"
                    placeholder="Message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  ></textarea>
                  <small style={{ color: "red" }}>{messageErr}</small>
                </div>

                <div class="send_btn forms_click_btn">
                  <button
                    className="default_btn"
                    onClick={handleSubmit}
                  >
                    Send Message
                  </button>
                  <p>
                    By filling this form, you agree that your contact
                    information will be processed as per the
                    <Link to="/TermsService"> Terms </Link>
                    of HOP Electric Mobility Pvt. Ltd.
                  </p>
                </div>
              </form>

              <div class="Locations">
                <div class="form_head">
                  <h2>Locations</h2>
                  <p>
                    HOP Electric Mobility Pvt Ltd is located at the Following
                    Locations:{" "}
                  </p>
                </div>

                <div class="box_sec">
                  <div class="box_cont">
                    <h3>Hop Launchpad</h3>
                    <p>
                      Plot no. 1, Sector 11, Jhulelal Marg, Shipra Path, Jhalana
                      Chhod, Mansarovar, Jaipur, Rajasthan 302020
                      <br />
                      Contact No- +91-80033-80063
                      <br />
                      Mail Id- hello@hopelectric.in
                    </p>
                    <div className="direction">
                      <button onClick={() => openMapInNewTab(0)}>
                        Get Direction
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bike_img_sec"></div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default ContactUs;
