import { Link } from "react-router-dom";
import logo_img from "../../../assets/images/HopLogo.svg";
import fb_img from "../../../assets/images/face.png";
import insta_img from "../../../assets/images/insta.png";
import youtube_1 from "../../../assets/images/youtube_1.png";
import in_img from "../../../assets/images/in.png";
import twitter_img from "../../../assets/images/twitter.png";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { getCookie } from "../../../utils";
import "./footer.css";
import { useEffect, useRef } from "react";

const DetailFooter = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const PriceRef = useRef();

  const redirectservicerequest = (expiredata) => {
    // navigate(`/service`);
    window.open(process.env.REACT_APP_WEBSITE_URL + "/service");
  };

  const redirect = (bookdata, e) => {
    const cookieData = getCookie(bookdata);
    if (!cookieData) {
      navigate(`/${"login"}?to=booktestride`);
    } else {
      navigate("/booktestride");
    }
  };

  const scrollbooknow = (e) => {
    const section = document.querySelector("#price_sec");
    if (location.pathname == "/" || location.pathname == "/booknow") {
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    } else {
      navigate("/booknow");
    }
  };

  const tempNavigate = (to) => {
    if (to == "/hop-leo" && location.pathname == "/hop-leo") {
      window.open(process.env.REACT_APP_WEBSITE_URL + "/enquiry", "_self");
    }
    if (to == "/hop-leo" && location.pathname !== "/hop-leo") {
      window.open(process.env.REACT_APP_WEBSITE_URL + "/hop-leo", "_self");
    }

    if (to == "/oxo" && location.pathname == "/") {
      window.open(process.env.REACT_APP_WEBSITE_URL + "/enquiry", "_self");
    }

    if (to == "/oxo" && location.pathname !== "/") {
      window.open(process.env.REACT_APP_WEBSITE_URL, "_self");
    }
  };

  return (
    <>
      <footer className="footer">
        <div className="uk-container">
          <div className="footer_row">
            <div className="footer_col">
              <div className="logo">
                <Link to="/home">
                  <img src={logo_img} />
                </Link>
                <p>HOP Electric Mobility</p>
              </div>
              <div className="follow">
                <h5>Follow us on</h5>
                <ul className="uk-flex uk-flex-center social_icon_sectio">
                  <li>
                    <Link
                      to="https://www.facebook.com/hopelectric"
                      target="_self"
                    >
                      <img src={fb_img} alt="image" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.instagram.com/electric.hop/"
                      target="_self"
                    >
                      <img src={insta_img} alt="image" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://in.linkedin.com/company/hopelectric"
                      target="_self"
                    >
                      <img src={in_img} alt="image" />
                    </Link>
                  </li>
                  <li>
                    <Link to="https://twitter.com/electrichop" target="_self">
                      <img src={twitter_img} alt="image" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://youtube.com/@HopElectricMobility"
                      target="_self"
                    >
                      <img src={youtube_1} alt="image" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="footer_col">
              <ul className="footer_link space_bot">
                <h5>About</h5>
                <li>
                  <a
                    href={process.env.REACT_APP_WEBSITE_URL + "/about-us"}
                    target="_self"
                  >
                    About Us
                  </a>
                </li>
                <li>
                  <Link to="/dealers" target="_self">
                    All Dealers
                  </Link>
                </li>
              </ul>
            </div>
            <div className="footer_col">
              <ul className="footer_link">
                <h5>Products</h5>
                <li>
                  <span
                    className="footer_link_a"
                    // href={process.env.REACT_APP_WEBSITE_URL + "/oxo"}
                    onClick={() => tempNavigate("/oxo")}
                  >
                    OXO
                  </span>
                </li>
                <li>
                  <span
                    className="footer_link_a"
                    // href={process.env.REACT_APP_WEBSITE_URL + "/hop-leo"}
                    onClick={() => tempNavigate("/hop-leo")}
                  >
                    LEO
                  </span>
                </li>
                <li>
                  <a
                    href={process.env.REACT_APP_WEBSITE_URL + "/enquiry"}
                    target="_self"
                  >
                    LYF
                  </a>
                </li>
              </ul>
            </div>

            <div className="footer_col">
              <ul className="footer_link space_bot">
                <h5>Interact</h5>
                <li>
                  <Link to="/contact-us" target="_self">
                    Get in Touch
                  </Link>
                </li>
                <li onClick={() => redirectservicerequest("userdata")}>
                  <a>Service Request</a>
                </li>
                <li>
                  <a
                    href={process.env.REACT_APP_WEBSITE_URL + "/career"}
                    target="_self"
                  >
                    Career
                  </a>
                </li>
                <li>
                  <a
                    href={process.env.REACT_APP_WEBSITE_URL + "/enquiry"}
                    target="_self"
                  >
                    Enquiry
                  </a>
                </li>
              </ul>
            </div>

            <div className="footer_col">
              <ul className="footer_link">
                <h5>Commercial</h5>
                <li>
                  <span
                    className="footer_link_a"
                    onClick={(e) => redirect("userdata", e)}
                  >
                    Book A Test Ride
                  </span>
                </li>
                <li>
                  <span className="footer_link_a" onClick={scrollbooknow}>
                    Book Now
                  </span>
                </li>
                <li>
                  <a href={process.env.REACT_APP_WEBSITE_URL + "/dealership"}>
                    Become A Dealer
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
export default DetailFooter;
