import { Link } from "react-router-dom";
import { useState } from "react";

import rigth_arrow from "../../../assets/images/arrow-right.png";
import Header from "./Header";
import { useScrollTo } from "../../../hooks/useScrollTo";

function MenuList() {
  useScrollTo(0, 0);
  const [open, setOpen] = useState(true);

  return (
    <>
      <Header />
      <div className="menu_list">
        <ul>
          <li>
            <Link to="/">User-Dashboard</Link>
          </li>
          <li
            className={open ? "arrow_show dropdown_menu" : "dropdown_menu"}
            onClick={() => (open ? setOpen(false) : setOpen(true))}
          >
            <Link to=" ">my test rides </Link>
            <img
              src={rigth_arrow}
              alt="imhvgb"
               
              className="arrowicon bottom_show"
            />
            <ul
              className="drop_menu"
              style={{ display: open ? "block" : "none" }}
            >
              <li>
                <Link to="/" className="active">
                  active rides
                </Link>
              </li>
              <li>
                <Link to="/">Past</Link>
              </li>
              <li>
                <Link to="/">Cancelled</Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="/">book another T-ride</Link>
          </li>
          <li>
            <Link to="/">My bookings</Link>
          </li>
          <li>
            <Link to="/">Order bike</Link>
          </li>
        </ul>
      </div>
    </>
  );
}
export default MenuList;
